import { Component, Input } from '@angular/core';

@Component({
    selector: 'image-points',
    templateUrl: 'image-points.component.html',
    styleUrls: ['./image-points.scss'],
})
export class ImagePoints  {
    constructor() {}

    @Input() data!: any[];
    @Input() image!: string;
    @Input() title!: string;
    @Input() subtitle!: string;
    @Input() leftContentXLCol = '7';
    @Input() colorStyle = '1';
    @Input() imagePosition = '1';
}
