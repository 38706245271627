import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteConstants } from '../../config/route.config';
import { PartnersComponent } from './partners/partners.component';
import { DebugRouteGuard } from '../../debug-route.guard';

const routes: Routes = [
  { path: '', redirectTo: RouteConstants.BECOME_PARTNER, pathMatch: 'full' },
  { path: RouteConstants.BECOME_PARTNER, component: PartnersComponent, canActivate: [DebugRouteGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class PartnersRoutingModule { }
