import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FetchMetaService } from '../../../../queries/Meta/fetchmeta.service';
import { Subject, Subscription } from 'rxjs';
import META_QUERY from '../../../../queries/Meta/meta.service';
import { Apollo } from 'apollo-angular';
import { takeUntil } from 'rxjs/operators';
import { CanonicalService } from '../../../../queries/canonical/canonical.service';

@Component({
    selector: 'app-faqs',
    templateUrl: './faqs.component.html',
    styleUrls: ['./faqs.component.scss'],
})
export class FaqsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    constructor(private canonicalService: CanonicalService, private titleService: Title, private metaService: Meta, private fetchMetaService: FetchMetaService, private apollo: Apollo) { }
    selected_item!: {
        category_name: string;
        title: string;
        faq: ({ question: string; ans: string[]; } | { question?: undefined; ans?: undefined; })[];
    }

    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        if (this.fetchMetaService.getData() == false) {
            let metatags = [];
            this.apollo
                .watchQuery({
                    query: META_QUERY,
                })
                .valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                    let meta = [];
                    meta = (result.data as any).metaTags.data.map((metaTag: any) => (
                        {
                            ...metaTag,
                        }
                    ));
                    for (let i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    // console.log("Meta Tags ", metaTagsData);
                    this.titleService.setTitle(metaTagsData['faqs'].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content:
                            metaTagsData['faqs'].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData['faqs'].attributes.keywords
                    });
                });
        }
        else {
            // console.log("Data Alreday Fetched");
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data['faqs'].attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content:
                    data['faqs'].attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data['faqs'].attributes.keywords
            });

        }

        // this.titleService.setTitle('FAQs | COSGrid Networks');
        // this.metaService.updateTag({ name: 'description', content: 'Explore FAQs about solutions. Learn about ZTNA, SD-WAN vs. MPLS, NDR, and more.' });
        // this.metaService.updateTag({ name: 'keywords', content: 'COSGrid, MicroZAccess, Reflex-WAN, NetShield, FAQ, frequently asked questions, Zero Trust Network Access, SD-WAN, MPLS, NDR, network security, network connectivity' });
        this.selected_item = this.products.filter(obj => obj.category_name === "Zero Trust Network Access")[0];
    }

    display = (category: any) => {
        let product_to_render = this.products.filter(obj => obj.category_name === category);
        this.selected_item = product_to_render[0];
    };

    products = [
        {
            category_name: 'Secure SD-WAN',
            title: 'Reflex-WAN FAQ',
            faq: [
                {
                    question: '1. Is SD-WAN better than MPLS?',
                    ans: [
                        'SD-WAN has high scalability while keeping low CapEx and Opex since it uses multiple network channels to enhance the bandwidth when needed. On the other hand, MPLS is a more secure option for branch connectivity, scalability is low and high CapEx and Opex.',
                        'So, if your organization is looking for expansion in the future and want to keep the CapEx low, It is better, and if you know your organization will stay the same and no new branches will be planned in future, then MPLS can be an option.',
                    ],
                },
                {
                    question: '2. Difference between WAN and SD-WAN?',
                    ans: [
                        'SD-WAN and WAN optimization are two similar technologies and we can see them as a successor and a legend in the same field. Both are capable of doing wonders alone, and even better when, together.',
                    ],
                },
                {
                    question: '3. What is SD-WAN used for?',
                    ans: [
                        'SD-WAN is used to connect multiple branch offices in large geography using multiple networking channels, like 4G LTE, Broadband, and MPLS.',
                    ],
                },
                {
                    question: '4. When to use SD-WAN?',
                    ans: [
                        'If your organization is planning to open multiple branches and your requirements are efficient, seamless, and highly scalable connectivity, then you should use sd-wan. Other perks of using it include low CapEx and OpEx.',
                    ],
                },
                {
                    question: '5. What is the difference between LAN and WAN?',
                    ans: [
                        'LAN helps to establish a connection between multiple computers and devices but mostly within the same branch, WAN helps to connect multiple branches together covering larger geography.',
                    ],
                },
            ],
        },
        {
            category_name: 'Zero Trust Network Access',
            title: 'COSGrid MicroZAccess FAQ',
            faq: [
                {
                    question: '1. What is Zero Trust?',
                    ans: [
                        'It is an intelligent and granular access control system that takes into consideration the security context such as User\'s or device\'s security posture, location and time etc. Having a Strong & Enhanced Identity for users, devices and Apps and using it to enforce the \'Principles of Least privileges\' when accessing resources such as Networks, Systems, Applications and Data.',
                    ],
                },
                {
                    question: '2. What are all the interesting Use Cases, we can implement Zero Trust Network Access?',
                    ans: [
                        '<ul style="margin-left:40px">',
                        '<li>Secure Remote Access to workloads</li>',
                        '<li>Alternative to VPN Access</li>',
                        '<li>Workloads microsegmentation</li>',
                        '<li>Application Dependency </li>',
                        '<li>Multi-cloud/ Enterprise / Cloud workloads protection</li>',
                        '</ul>'
                    ],
                },
                {
                    question: `3.How does ZTNA micro segmentation help to mitigate attacks?`,
                    ans: [
                        `Only the right person/device/apps with the right 'security confidence' score is given appropriate access. Thereby reducing the chances of data breaches. Note, the resource access allow/deny is dynamic and continuous. Also, through ZTNA micro segmentation, lateral movements of attacker / exploits are restricted. This reduces the overall attack surface and hence the security risk.`
                    ],
                },
                {
                    question: `4. Is VLAN/SDN and Micro Segmentation the same?`,
                    ans: [
                        `Yes, VLAN and SDN are enabling Microsegmentation`
                    ]
                },
                {
                    question: `5. Is physical firewall devices necessary for zero trust network Access?`,
                    ans: [
                        `Physical devices are not necessary. The firewall can be virtual and can run inside a host too.`
                    ]
                },
                {
                    question: `6. What are some of the key components of a Zero Trust architecture?`,
                    ans: [
                        '<p>The key components of a Zero Trust architecture include:</p>',
                        '<ul style="margin-left:40px">',
                        '<li>Identity and access management (IAM): IAM is used to verify the identity of users and devices before granting access to resources.</li>',
                        '<li>Microsegmentation: Microsegmentation is used to segment the network into smaller, more secure zones.</li>',
                        '<li>Risk-based access control: Risk-based access control is used to grant access to resources based on the risk associated with the user or device.</li>',
                        '<li>Continuous monitoring: Continuous monitoring is used to detect and respond to threats in real time.</li>',
                        '</ul>'
                    ]
                },
                {
                    question: `7. How does Zero Trust differ from traditional security models?`,
                    ans: [
                        'Traditional security models focus on protecting the perimeter of the network. However, Zero Trust takes a differe by assuming that no one is trusted, even if they are inside the network. This means that all users and devices a the same level of scrutiny, regardless of where they are located.'
                    ]
                },
                {
                    question: `8. What are some of the challenges of implementing Zero Trust?`,
                    ans: [
                        `Some of the challenges of implementing Zero Trust include:`,
                        '<ul style="margin-left:40px">',
                        '<li>Cost: Zero Trust can be expensive to implement, especially for large organizations.</li>',
                        '<li>Complexity: Zero Trust can be complex to implement and manage, especially for organizations with complex IT environments.</li>',
                        '<li>Culture: Zero Trust requires a change in security culture, as it requires organizations to move away from a perimeter-based security model.</li>',
                        '</ul>'
                    ]
                },
                {
                    question: `9. What is COSGrid MicroZAccess ZTNA?`,
                    ans: [
                        `COSGrid MicroZAccess ZTNA is a Zero Trust Network Access (ZTNA) solution that provides secure and controlled access to your network resources. It replaces traditional VPNs with a more secure and flexible approach.`
                    ]
                },
                {
                    question: `10. How does MicroZAccess differ from traditional VPNs?`,
                    ans: [
                        `MicroZAccess offers granular control over access, continuous authentication, and
micro-segmentation, providing a higher level of security compared to traditional VPNs.`
                    ]
                },
                {
                    question: `11. Is MicroZAccess suitable for small and medium-sized businesses (SMBs)?`,
                    ans: [
                        `Yes, MicroZAccess is designed to be scalable and flexible, making it suitable for businesses of all sizes.`
                    ]
                }, {
                    heading: `Deployment and Configuration`,
                },
                {
                    question: `1. How long does it take to deploy MicroZAccess?`,
                    ans: [
                        `Deployment time varies depending on the organization's size and complexity, but MicroZAccess is designed for rapid deployment.`,
                    ]
                },
                {
                    question: `2. Does MicroZAccess require any specific hardware or software?`,
                    ans: [
                        `The system requirements for MicroZAccess are minimal and can be found in the detailed documentation.`
                    ]
                },
                {
                    question: `3. Can I integrate MicroZAccess with my existing security infrastructure?`,
                    ans: [
                        `Yes, MicroZAccess can be integrated with various security systems to provide a comprehensive security solution.`
                    ]
                },
                {
                    heading: `Security and Compliance`
                },
                {
                    question: `1. How secure is MicroZAccess?`,
                    ans: [
                        `MicroZAccess employs advanced security measures, including continuous authentication, micro-segmentation, and encryption to protect your data.`
                    ]
                },
                {
                    question: `2. Does MicroZAccess comply with industry standards and regulations?`,
                    ans: [
                        `MicroZAccess is designed to meet industry standards and regulations. Please refer to the specific compliance information provided by COSGrid.`
                    ]
                },
                {
                    question: `4. How does MicroZAccess prevent unauthorized access?`,
                    ans: [
                        `MicroZAccess uses multi-factor authentication, role-based access control, and device posture assessment to prevent unauthorized access.`
                    ]
                },
                {
                    heading: `Performance and User Experience`
                },
                {
                    question: `1. Will MicroZAccess impact user performance?`,
                    ans: [
                        `MicroZAccess is optimized for performance and should not significantly impact user experience.`
                    ]
                },
                {
                    question: `2. Can users access applications from any device?`,
                    ans: [
                        `Yes, users can access applications from any device with an internet connection, as long as the device meets the required security standards.`
                    ]
                },
                {
                    question: `3. What happens if a user loses their device?`,
                    ans: [
                        `If a device is lost or stolen, the user's access can be revoked immediately to protect sensitive data.`
                    ]
                },
                {
                    question: `4. How can I get technical support for MicroZAccess?`,
                    ans: [
                        `COSGrid provides technical support for MicroZAccess. Please refer to the support documentation or contact COSGrid directly for assistance.`
                    ]
                }
            ]
        },
        {
            category_name: 'Network Detection Response',
            title: 'COSGrid NetShield FAQ',
            faq: [
                {
                    question: `1. What is  NDR (Network  detection Response)?`,
                    ans: [
                        `Network Detection and Response (NDR) is a cybersecurity solution that helps organizations detect and respond to threats on their network. NDR solutions use advanced analytics and machine learning techniques to monitor network traffic, identify anomalies and suspicious activities, and alert security teams to potential threats.`
                    ]
                },
                {
                    question: `2. Is NDR and XDR the same?`,
                    ans: [
                        `NDR (Network Detection and Response) and XDR (Extended Detection and Response) are both cybersecurity solutions that focus on detecting and responding to threats across an organization's network where XDR offers a more comprehensive approach that encompasses multiple sources of data and provides more advanced threat response capabilities. However, NDR remains an important tool for monitoring network traffic and detecting potential threats in real-time.`
                    ]
                },
                {
                    question: `3. What is the difference between NDR and IDS/IPS?`,
                    ans: [
                        `NDR solutions are more advanced than traditional IDS/IPS (Intrusion Detection System/Intrusion Prevention System) solutions. While IDS/IPS solutions focus on identifying known threats based on predefined rules, NDR solutions use advanced analytics and machine learning techniques to detect unknown threats and suspicious activities in real-time.`
                    ]
                },
                {
                    question: `4. How does NDR work?`,
                    ans: [
                        `NDR solutions work by analyzing network traffic, identifying anomalies, and alerting security teams to potential threats. They use advanced analytics and machine learning techniques to identify patterns and behaviors that are indicative of a security threat, and then trigger an alert when suspicious activity is detected.`
                    ]
                },
                {
                    question: `5. What are the benefits of NDR?`,
                    ans: [
                        `NDR solutions provide organizations with increased visibility into their network traffic, allowing them to identify potential threats that traditional security solutions may miss. They also enable organizations to respond more quickly and effectively to security incidents, reducing the time it takes to detect and remediate threats.`
                    ],
                }
            ]
        }
    ];

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
