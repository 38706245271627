<div class="h-100 p-5 gradient">
    <div class="w-70 mx-auto">
        <img
            class="img-fluid"
            src="https://vcdn.cosgrid.com/website/assets2/blog_images/1300w/COSgrid%20copy_2.webp"
            alt="Logo"
        />
    </div>
    <h6 style="color: white;" class="text-center mt-3">
        Copyright 2024 © COSGrid Systems Pvt. Ltd., All Rights Reserved
    </h6>
</div>
