<p>openings works!</p>
<div *ngIf="loading == true"
    style="display: flex; height: 100vh; width: 100vw; margin: 0 0; padding: 0 0; background-color: #eeeeee">
    <div style="margin: auto">
        <img style="display: block" class="logo-loading" src="https://vcdn.cosgrid.com/website/assets4/navbar/fLogo.svg" alt="Logo loading.."
            loading="lazy" />
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    >
</div>
<div *ngIf="loading == false" class="pt-3">
    <section class="px-md-5 my-5 px-2">
        <div class="d-flex justify-content-between align-items-center">
            <p class="fw-bold h4">Discover the perfect role that suits you best.</p>
            <div class="d-flex flex-column flex-md-row">
                <select (change)="dropdownCountrySelect($event)" class="fs-6 p-2">
                    <option [value]="openingCountry" *ngFor="let openingCountry of openingCountries">
                        {{ openingCountry }}
                    </option>
                </select>
                <select (change)="dropdownDepartmentsSelect($event)" class="ms-md-4 fs-6 p-2">
                    <option [value]="openingDepartment" *ngFor="let openingDepartment of openingDepartments">
                        {{ openingDepartment }}
                    </option>
                </select>
            </div>
        </div>
    </section>
    <section class="px-md-5 my-5 px-2">
        <div class="row d-flex justify-content-center">
            <div class="col-12 pt-4 px-4">
                <div class="row">
                    <div class="col-8 col-sm-5">
                        <p class="fw-bold fs-4"><i class="fa-regular fa-user"></i> Position</p>
                    </div>
                    <div class="col-4 col-sm-3 py-1">
                        <p class="fw-bold fs-4"><i class="fa-regular fa-building"></i> Department</p>
                    </div>
                    <div class="col-8 col-sm-3 py-1">
                        <p class="fw-bold fs-4"><i class="fa-regular fa-address-book"></i> Type</p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngFor="let item of current_active_positions">
            <div class="row d-flex justify-content-center" *ngFor="let i of item.attributes.open_positions.data">
                <div class="col-12 p-4 single-career-container my-2">
                    <div class="row">
                        <div class="col-8 col-sm-5 py-1">
                            <p class="fw-bold fs-6">{{ i.attributes.Title }}</p>
                        </div>
                        <div class="col-4 col-sm-3 py-1">
                            <p class="fs-6">{{ item.attributes.type }}</p>
                        </div>
                        <div class="col-8 col-sm-3 py-1">
                            <p class="fs-6">{{ i.attributes.Employment_type }}</p>
                        </div>
                        <div class="col-4 py-1 col-sm-1">
                            <button class="btn-primary btn fs-6"
                                [routerLink]="i.attributes.Open_position_url">
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>