import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SupportRoutingModule } from './support-routing.module';
import { SupportComponent } from './support/support.component';
import { ContactSupportComponent } from './support/contact-support/contact-support.component';
import { SubmitTicketComponent } from './support/submit-ticket/submit-ticket.component';
import { SupportTermsComponent } from './support/support-terms/support-terms.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';


@NgModule({
  declarations: [
    SupportComponent,
    ContactSupportComponent,
    SubmitTicketComponent,
    SupportTermsComponent
    
  ],
  imports: [
    CommonModule,
    SupportRoutingModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
  ]
})
export class SupportModule { }
