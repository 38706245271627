import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { MyEmitterService } from '../../../app-emitter.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { RouteConstants } from '../../../config/route.config';

@Component({
  selector: 'app-desktop-footer',
  templateUrl: './desktop-footer.component.html',
  styleUrl: './desktop-footer.component.scss'
})
export class DesktopFooterComponent {
  PRIVACY_POLICY = RouteConstants.PRIVACY_POLICY + '/.';
  Support = RouteConstants.Support + '/.';
  SITEMAP = RouteConstants.SITEMAP + '/.';

  ztnaItem = [
    {
      link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,

      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;COSGrid MicroZAccess</a>',
    },
  ];
  SASLinks = [
    {
      link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;SASE</a>',
    },
  ];
  SSELinks = [
    {
      link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.REFLEX_SDWAN}/.`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;ZTNA</a>',
    },
    {
      link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;SIG</a>',
      frag: 'sig',
    },
    {
      link: `/${RouteConstants.PRODUCTS}/vpn/${RouteConstants.SECUREACCESS}/.`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;VPN</a>',
    },
  ];
  SDLinks = [
    {
      link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp; ReFleX-WAN</a>',
    },
    {
      link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.FIVEG_SDWAN}/.`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp; ReFleX Z3 5G SD WAN</a>',
    },
  ];
  NDRLinks = [
    {
      link: `/${RouteConstants.PRODUCTS}/ndr/${RouteConstants.NETSHIELD}/.`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;NDR</a>',
    },
  ];
  resourceLinks = [
    {
      link: `${RouteConstants.RESOURCES}/case-studies/.`,
      name: 'Case studies & Reports',
    },
    {
      link: 'https://docs.cosgrid.com/',
      name: 'MicroZAccess Documentation',
    },
    {
      link: `/${RouteConstants.RESOURCES}/whitepaper-datasheets/.`,
      name: 'White papers',
    },
    {
      link: `${RouteConstants.FAQS}/.`,
      name: "FAQ'S",
    },
  ];

  productLinks = [
    {
      link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
      name: 'Security Service Edge - SSE',
    },
    {
      link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
      name: 'Secure SD-WAN',
    },
    {
      link: `${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`,
      name: 'Next Generation Firewall - NGFW'
    },
    {
      link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
      name: 'Secure Access service Edge - SASE',
    },
    {
      link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
      name: 'Advanced Threat protection',
    },
  ];
  componentsLinks = [
    {
      link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.C_EDGE}`,
      name: 'COSGrid RefleX-Edge',
    },
    {
      link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.GUIDER}`,
      name: 'COSGrid Guider',
    },
    {
      link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.CLOUD_NF_HUB}`,
      name: 'COSGrid CloudNF Hub',
    },
    {
      link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURITY_ANALYSER_RESPONDER}`,
      name: 'COSGrid SAR',
    },
    // {
    //     link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURECONNECT}/.`,
    //     name: 'COSGrid Secure Connect',
    // },
  ];
  DOCLinks = [
    {
      link: `https://docs.cosgrid.com/.`,
      name: 'MicroZAccess',
    },
  ];
  FAQLink = [
    {
      link: `${RouteConstants.RESOURCES}/${RouteConstants.FAQS}`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;FAQ</a>',
    },
  ];
  UseLink = [
    {
      link: `/${RouteConstants.USECASES}`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;ReFleX SD-WAN</a>',
    },
    {
      link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;MicroZAccess</a>',
      frag: 'usecases',
    },
  ];
  MediLink = [
    {
      link: `/${RouteConstants.EVENTS}/.`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;Event Photographs</a>',
    },
    {
      link: `/${RouteConstants.RESOURCES}/${RouteConstants.GALLERY}/.`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;Moments</a>',
    },
    {
      link: `/${RouteConstants.BLOGS}/.`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;Blogs</a>',
    },
  ];
  WhiteLink = [
    {
      link: `https://vcdn.cosgrid.com/website/strapi/Re_Fle_X_SD_WAN_Product_Brief_a5bd7e785f.pdf`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;ReFleX SD-WAN</a>',
    },
    {
      link: `https://ncms.cosgrid.com/uploads/Brochure_Net_Shield_V3_1cc106e96b.pdf`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;NetShield NDR</a>',
    },
    {
      link: `https://ncms.cosgrid.com/uploads/Micro_Z_Access_Product_Brief_44eb68d199.pdf`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;MicroZAccess</a>',
    },
    {
      link: `https://ncms.cosgrid.com/uploads/Brochure_COS_Grid_SASE_V1_f3bf7f2f47.pdf`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;COSGrid SASE</a>',
    },
  ];
  LibraryLinks = [
    {
      link: `${RouteConstants.RESOURCES}/${RouteConstants.SD_WAN}`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;SD-WAN Concepts</a>',
    },
  ];

  productsItem = [
    {
      link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.SECURESDWAN_REFLEX_HYBWAN}/.`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;RefleX Hyb-WAN</a>',
    },
    {
      link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.SECURESDWAN_REFLEX_INWAN}/.`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;RefleX In-WAN</a>',
    },
    {
      link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.FIVEG_SDWAN}/.`,
      name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;Z3 5G SD-WAN</a>',
    },
    {
      link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.SDINTERNETEDGE}/.`,
      name: 'SD-Internet Edge',
    },
    // {
    //     link: `/${RouteConstants.PRODUCTS}/VPN/${RouteConstants.SECUREACCESS}/.`,
    //     name: 'COSGrid RefleX RPN',
    // },
    {
      link: `/${RouteConstants.PRODUCTS}/ndr/${RouteConstants.NETSHIELD}/.`,
      name: 'COSGrid NetShield',
    },
  ];

  componentsItem = [
    {
      link: `/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.C_EDGE}/.`,
      name: 'COSGrid RefleX-Edge',
    },
    {
      link: `/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.GUIDER}/.`,
      name: 'COSGrid Guider',
    },
    {
      link: `/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.CLOUD_NF_HUB}/.`,
      name: 'COSGrid CloudNF Hub',
    },
    {
      link: `/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURECONNECT}/.`,
      name: 'COSGrid Secure Connect',
    },
  ];

  industriesItem = [
    {
      link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.FINANCIAL_SERVICES}/.`,
      name: 'Financial',
    },
    {
      link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.HEALTHCARE}/.`,
      name: 'Healthcare',
    },
    {
      link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.MANUFACTURING}/.`,
      name: 'Manufacturing',
    },
    {
      link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.RESTAURANTS_HOSPITALITY}/.`,
      name: 'Restaurants / Hospitality',
    },
    {
      link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.RETAIL}/.`,
      name: 'Retail',
    },
  ];

  // partnersItem: IRouteLinkItem[] = [
  //     {
  //         link: `${RouteConstants.PARTNERS}#partnersProgram`,
  //         name: "Partner's Portal",
  //     },
  //     {
  //         link: `${RouteConstants.PARTNERS}#becomePartner`,
  //         name: 'Become a partner',
  //     },
  // ];

  companyItem = [
    {
      link: `${RouteConstants.COMPANY}/${RouteConstants.ABOUTUS}`,
      name: 'About us',
    },
    {
      link: `${RouteConstants.RESOURCES}/${RouteConstants.PRESS_RELEASE}`,
      name: 'Press Release',
    },
    {
      link: `${RouteConstants.COMPANY}/${RouteConstants.AWARDS}`,
      name: 'Awards',
    },
    {
      link: `${RouteConstants.COMPANY}/${RouteConstants.EVENTS}`,
      name: 'Events',
    },

    {
      link: `${RouteConstants.COMPANY}/${RouteConstants.CAREERS}`,
      name: 'Careers',
    },
  ];

  industriesLinks = [
    {
      link: `${RouteConstants.INDUSTRIES}/${RouteConstants.FINANCIAL_SERVICES}/.`,
      name: 'Financial Services',
    },
    {
      link: `${RouteConstants.INDUSTRIES}/${RouteConstants.HEALTHCARE}/.`,
      name: 'Healthcare',
    },
    {
      link: `${RouteConstants.INDUSTRIES}/${RouteConstants.MANUFACTURING}/.`,
      name: 'Manufacturing',
    },
    {
      link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RESTAURANTS_HOSPITALITY}/.`,
      name: 'Restaurants',
    },
    {
      link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RETAIL}/.`,
      name: 'Retail',
    },
  ];

  socialMediaItems: any = [
    {
      image: 'linkedin',
      link: 'https://www.linkedin.com/company/cosgrid-networks/',
    },
    {
      image: 'twitter',
      link: 'https://twitter.com/cosgridnetworks',
    },
    {
      image: 'youtube',
      link: 'https://www.youtube.com/channel/UC4INTYcS9l_HbzPbwW1-5GA',
    },
    {
      image: 'whatsapp',
      link: 'https://wa.me/918610144212',
    },
    {
      image: 'instagram',

      link: 'https://www.instagram.com/cosgridnetworks',
    },
    {
      image: 'facebook',
      link: 'https://www.facebook.com/people/COSGrid-Networks/100078776480639/',
    },
  ];

  locationItem = [
    {
      link: 'https://goo.gl/maps/LojnqM6DLsWG91XLA',
      name: `Velachery, Chennai - 600042,<br />TamilNadu, India`,
    },
    {
      link: 'https://www.google.com/maps/place/2055+Limestone+Rd+Suite+200+C,+Wilmington,+DE+19808,+USA/@39.723327,-75.657039,15z/data=!4m6!3m5!1s0x89c701c892855555:0x20a2b8882c1e4d73!8m2!3d39.7233268!4d-75.6570387!16s%2Fg%2F11sq__9mxr?hl=en&entry=ttu',
      name: `Delaware, New Castle,<br />US, 19808`,
    },
  ];
  registeredOffice = [
    {
      link: 'https://www.google.com/maps/place/COSGrid+Systems+Private+Limited/@12.9827359,80.2294445,17z/data=!3m1!4b1!4m6!3m5!1s0x3a525d8233638529:0xe2e92d3f778d8f48!8m2!3d12.9827359!4d80.2294445!16s%2Fg%2F11f03t4p3s',
      name: `
      <u>Registered Office: </u><br />
      No. 24-1, Shanti Street,<br /> Dr. Seethapathy Nagar,<br /> Velachery,<br />
      Chennai - 600042<br /> Tamil Nadu, India.
    `,
    },
  ];

  USlocation = [
    {
      link: 'https://goo.gl/maps/smvzhuDWZF7QrjjCA',
      name: `
      <u>Registered Office: </u><br />
      COSGrid Networks Inc,<br />2055 Limestone Rd STE 200-C,<br />Wilmington, DE, New Castle,<br /> US, 19808
    `,
    },
  ];

  partnersItem = [
    {
      name: 'Partners',
      link: `${RouteConstants.PARTNERS}/${RouteConstants.BECOME_PARTNER}`,
    },
    {
      name: 'Privacy Policy',
      link: RouteConstants.PRIVACY_POLICY,
    },
    {
      name: 'Terms Of Service',
      link: RouteConstants.TERMS_OF_USE,
    },
    {
      name: 'Support',
      link: RouteConstants.Support,
    },
    {
      name: 'Site Map',
      link: RouteConstants.SITEMAP,
    },
  ];

  contactsItem = [
    // {
    //     link: 'tel:+919022764534',
    //     name: '+91 90227 64534',
    // },
    // {
    //     link: 'tel:+918610144212',
    //     name: '+91 86101 44212',
    // },
    // {
    //     link: `${RouteConstants.CAREERS}/.`,
    //     name: 'Careers',
    // },
    // {
    //     link: 'tel:+919884764534',
    //     name: '+91 9884764534',
    // },
    {
      link: 'mailto:info@cosgrid.com?Subject=Hello%20again"',
      name: 'info@cosgrid.com',
    },
    {
      link: 'mailto:careers@cosgrid.com?Subject=Hello%20again"',
      name: 'careers@cosgrid.com',
    },
    {
      link: 'mailto:sales@cosgrid.com?Subject=Hello%20again"',
      name: 'sales@cosgrid.com',
    },
    {
      link: 'tel:+918610144212',
      name: '+91 86101 44212',
    },
  ];

  // socialMediaItem: IRouteLinkItem[] = [
  //     {
  //         link: 'https://www.linkedin.com/company/cosgrid-networks/',
  //         name: `<i class="fab fa-linkedin-in"></i>`,
  //     },
  //     {
  //         link: 'https://twitter.com/CosgridNetworks',
  //         name: `<i class="fab fa-twitter"></i>`,
  //     },
  //     {
  //         link: 'https://medium.com/cosgrid-networks',
  //         name: `<i class="fab fa-medium-m"></i>`,
  //     },
  //     {
  //         link: 'https://www.facebook.com/COSGrid-Networks-106028358695422/',
  //         name: `<i class="fab fa-facebook"></i>`,
  //     },
  //     {
  //         link: 'https://instagram.com/cosgridnetworks?utm_medium=copy_link',
  //         name: `<i class="fab fa-instagram"></i>`,
  //     },
  //     {
  //         link: 'https://www.youtube.com/@cosgridnetworks2141',
  //         name: `<i class="fab fa-youtube"></i>`,
  //     },
  //     {
  //         link: 'https://www.reddit.com/user/COSGrid_Networks/',
  //         name: `<i class="fab fa-reddit"></i>`,
  //     },
  // ];

  constructor(
    private emitter: MyEmitterService,
    private router: Router,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) protected platformId: any,
  ) { }

  ngOnInit(): void {
    this.emitter.getproductEvent().subscribe((data) => {
      //console.log(data);
      window.scroll(0, 0);
    });
  }

  routeToPage(item: any) {
    //console.log(item);
    // this.router.navigateByUrl(item.link);
    this.emitter.emitproductEvent(item);
  }

  routeToComponent(link: any) {
    // this.router.navigateByUrl(link);
    this.emitter.emitcomponentEvent(link);
  }
  industriesClick(link: any) {
    if (link.includes('https://')) {
      window.open(link, '_blank');
    } else {
      this.emitter.emitIndustriesEvent(link);
      // console.log(link);
    }
  }
  componentClick(link: any) {
    this.emitter.emitcomponentEvent(link);
  }

  openLink(link: any) {
    window.open(link);
  }
}
