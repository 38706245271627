<div class="sidenav h-100 w-20 position-fixed z-1 align-items-center top-0 start-0 overflow-y-auto d-none d-md-block">
    <div style="height: 101px"></div>
    <div class="list-group text-strong">
        <a fragment="{{ section1.id }}" routerLink="/resources/sd-wan"
            class="list-group-item fs-6 list-group-item-action" aria-current="true">{{ section1.title }}</a><a
            href="/resources/sd-wan#{{ section2.id }}" class="list-group-item fs-6 list-group-item-action"
            aria-current="true">{{ section2.title }}</a><a href="/resources/sd-wan#{{ section3.id }}"
            class="list-group-item fs-6 list-group-item-action" aria-current="true">{{ section3.title }}</a><a
            href="/resources/sd-wan#{{ section4.id }}" class="list-group-item fs-6 list-group-item-action"
            aria-current="true">{{ section4.title }}</a><a href="/resources/sd-wan#{{ section5.id }}"
            class="list-group-item fs-6 list-group-item-action" aria-current="true">{{ section5.title }}</a>
        <a *ngFor="let section of section6to7" href="/resources/sd-wan#{{ section.id }}"
            class="list-group-item fs-6 list-group-item-action" aria-current="true">
            {{ section.title }}
        </a>
        <a href="/resources/sd-wan#{{ section_8.id }}" class="list-group-item fs-6 list-group-item-action"
            aria-current="true">{{ section_8.title }}</a>
        <a href="/resources/sd-wan#{{ section_9.id }}" class="list-group-item fs-6 list-group-item-action"
            aria-current="true">{{ section_9.title }}</a>
        <a href="/resources/sd-wan#{{ section_10.id }}" class="list-group-item fs-6 list-group-item-action"
            aria-current="true">{{ section_10.title }}</a>
        <a href="/resources/sd-wan#{{ section_11.id }}" class="list-group-item fs-6 list-group-item-action"
            aria-current="true">{{ section_11.title }}</a>
        <a href="/resources/sd-wan#{{ section_12.id }}" class="list-group-item fs-6 list-group-item-action"
            aria-current="true">{{ section_12.title }}</a>
        <a href="/resources/sd-wan#{{ section_13.id }}" class="list-group-item fs-6 list-group-item-action"
            aria-current="true">{{ section_13.title }}</a>
        <a href="/resources/sd-wan#{{ section_14.id }}" class="list-group-item fs-6 list-group-item-action"
            aria-current="true">{{ section_14.title }}</a>
        <a href="/resources/sd-wan#{{ section_15.id }}" class="list-group-item fs-6 list-group-item-action"
            aria-current="true">{{ section_15.title }}</a>
        <a href="/resources/sd-wan#{{ section_17.id }}" class="list-group-item fs-6 list-group-item-action"
            aria-current="true">{{ section_17.title }}</a>
        <a href="/resources/sd-wan#{{ section_18.id }}" class="list-group-item fs-6 list-group-item-action"
            aria-current="true">{{ section_18.title }}</a>
        <a href="/resources/sd-wan#{{ section_19.id }}" class="list-group-item fs-6 list-group-item-action"
            aria-current="true">{{ section_19.title }}</a>
    </div>
</div>

<div class="main py-1" style="margin-top: 100px;">
    <h1 class="fw-bold clr-blue-dark h2 text-center" id="{{ section1.id }}">SD-WAN Overview</h1>
    <div class="row ml-1 mr-1">
        <section>
            <div class="xl pt-2">
                <div class="text-normal">
                    <p class="fw-bold text-center text-md-left clr-blue-dark my-3 h5">
                        {{ section1.title }}
                    </p>
                    <div class="row">
                        <div class="col-lg">
                            <ul *ngFor="let item of section1.text" class="list-unstyled lh-lg">
                                <li class="fs-6" [innerHTML]="item.desc"></li>
                                <li class="pl-4">
                                    <ul class="mt-3 text-left">
                                        <li *ngFor="let point of item.points" class="fs-6" [innerHTML]="point">
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-4">
                            <img [src]="section1.imgURL" [alt]="section1.title" class="img-fluid mx-auto d-block"
                                loading="lazy" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div style="background-color: #f8f9fa !important">
        <div id="{{ section5.id }}"></div>
        <div id="{{ section3.id }}"></div>
        <div id="{{ section4.id }}"></div>

        <div class="row ml-1 mr-1" id="{{ section2.id }}">
            <mat-tab-group>
                <!-- section2 -->
                <mat-tab label="SD-WAN CONTEXT">
                    <section>
                        <div class="xl pt-2" style="width: 98%;">
                            <div class="text-normal">
                                <p class=" text-md-left clr-blue-dark mb-3 fw-bold h5">
                                    {{ section2.title }}
                                </p>
                                <div class="row">
                                    <div class="col-lg">
                                        <ul *ngFor="let item of section2.text" class="list-unstyled lh-lg">
                                            <li class="fs-6" [innerHTML]="item.desc"></li>
                                            <li class="pl-4">
                                                <ul class="mt-3 text-left">
                                                    <li *ngFor="let point of item.points" class="fs-6"
                                                        [innerHTML]="point"></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </mat-tab>
                <!-- section3 -->
                <mat-tab label="SD-WAN PROBLEMS ?">
                    <section id="{{ section3.id }}">
                        <div class="xl pt-2">
                            <div class="text-normal">
                                <p class="fw-bold  text-md-left clr-blue-dark mb-3 h5">
                                    {{ section3.title }}
                                </p>
                                <ul class="fs-6" [innerHTML]="section3.desc"></ul>
                            </div>
                            <div class="d-flex justify-content-evenly align-items-center flex-wrap">
                                <div *ngFor="let item of section3.problems"
                                    class="card1 d-flex my-1 justify-content-center p-3 mb-2 align-items-center flex-column">
                                    <div class="d-flex align-items-center justify-content-center p-5 h-30">
                                        <img [src]="item.imgURL" alt="image" class="img-fluid w-50" loading="lazy" />
                                    </div>
                                    <div class="cardTitle d-flex align-items-center justify-content-center pt-4">
                                        <p class="fw-bold h6">{{ item.title }}</p>
                                    </div>
                                    <div class="cardText d-flex justify-content-center align-items-center pt-4">
                                        <p class="fs-6">{{ item.text }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </mat-tab>
                <mat-tab label="BENEFITS OF SD-WAN">
                    <!-- section 4 -->
                    <section id="{{ section4.id }}">
                        <div class="xl ml-1 mr-1 pt-2">
                            <div class="text-normal">
                                <p class="  text-md-left clr-blue-dark mb-3 fw-bold h5">
                                    {{ section4.title }}
                                </p>
                            </div>
                            <div class="d-flex justify-content-evenly align-items-center flex-wrap">
                                <div *ngFor="let item of section4.card"
                                    class="card2 d-flex justify-content-center align-items-center flex-column my-2 p-2">
                                    <div
                                        class="imageContainer2 h-30 d-flex justify-content-center align-items-center mt-4 p-5">
                                        <img [src]="item.imgURL" alt="image" class="img-fluid w-50" loading="lazy" />
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center h-90 w-100 p-3">
                                        <p class="fs-6">{{ item.points }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </mat-tab>
                <mat-tab label="WHY SD-WAN ?">
                    <section [attr.id]="section5.id">
                        <div class="x1 pt-2">
                            <div class="text-normal">
                                <p class="fw-bold mt-2  text-md-left clr-blue-dark h5">
                                    {{ section5.title }}
                                </p>
                                <ul *ngFor="let item of section5.text" class="list-unstyled lh-lg">
                                    <li class="fs-6" [innerHTML]="item.desc"></li>
                                    <li class="pl-4">
                                        <ul class="mt-3 text-left">
                                            <li *ngFor="let point of item.points" class="fs-6" [innerHTML]="point"></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

    <div class="row ml-1 mr-1">
        <section *ngFor="let section of section6to7" [id]="section.id">
            <div class="x1">
                <div class="pt-2">
                    <p class="fw-bold mt-2  text-md-left clr-blue-dark h5">
                        {{ section.title }}
                    </p>
                    <ul *ngFor="let item of section.text" class="list-unstyled lh-lg">
                        <li class="fs-6" [innerHTML]="item.desc"></li>
                        <li class="pl-4">
                            <ul class="mt-3 text-left">
                                <li *ngFor="let point of item.points" class="fs-6" [innerHTML]="point"></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <!-- Section - 8 -->
        <section id="{{ section_8.id }}">
            <div class="xl pt-2 mr-1">
                <div class="text-normal">
                    <p class="fw-bold  text-md-left clr-blue-dark mt-2 h5">
                        {{ section_8.title }}
                    </p>
                    <p class="fs-6">{{ section_8.text }}</p>
                    <img [src]="section_8.imgURL" [alt]="section_8.title" class="img-fluid w-60 mx-auto d-block"
                        loading="lazy" />
                    <ul class="list-unstyled lh-lg mt-2">
                        <li class="fs-6" [innerHTML]="section_8.part_one.start"></li>
                        <li class="pl-4">
                            <ul class="text-left">
                                <li *ngFor="let item1 of section_8.part_one.points" class="fs-6" [innerHTML]="item1">
                                </li>
                            </ul>
                        </li>
                        <li class="fs-6 mt-2" [innerHTML]="section_8.part_one.end"></li>
                    </ul>
                    <ul *ngFor="let item2 of section_8.part_two" class="list-unstyled lh-lg mt-2">
                        <li class="fs-6">
                            <u class="fs-6 text-bold">{{ item2.heading }}</u>
                        </li>
                        <li class="pl-4">
                            <ul class="text-left">
                                <li *ngFor="let points of item2.points" class="fs-6" [innerHTML]="points"></li>
                            </ul>
                        </li>
                    </ul>

                    <ul class="list-unstyled lh-lg mt-2">
                        <li *ngFor="let item3 of section_8.part_three" class="fs-6" [innerHTML]="item3"></li>
                    </ul>
                </div>
            </div>
        </section>

        <!-- Section - 9 -->
        <section id="{{ section_9.id }}">
            <div class="xl ml-1 pt-2">
                <div class="text-normal">
                    <p class="fw-bold  text-md-left clr-blue-dark mb-3 h5">
                        {{ section_9.title }}
                    </p>
                    <p class="fs-6">{{ section_9.start }}</p>
                    <div class="row">
                        <div class="col-lg-7">
                            <p *ngFor="let item of section_9.blocks" class="fs-6 mt-1" [innerHTML]="item"></p>
                        </div>
                        <div class="col-lg-4">
                            <img [src]="section_9.imgURL" [alt]="section_8.title" class="img-fluid mx-auto d-block"
                                loading="lazy" />
                        </div>
                    </div>

                    <p class="fs-6 mt-2">{{ section_9.end }}</p>
                </div>
            </div>
        </section>

        <!-- Section - 10 -->
        <section id="{{ section_10.id }}">
            <div class="xl ml-1 pt-2">
                <div class="text-normal">
                    <p class="fw-bold  text-md-left clr-blue-dark mb-3 h5">
                        {{ section_10.title }}
                    </p>
                    <div class="row">
                        <div class="col-lg-6">
                            <ul *ngFor="let item1 of section_10.blocks" class="list-unstyled lh-lg">
                                <li class="fs-6">{{ item1.text }}</li>

                                <li class="pl-4">
                                    <ul class="text-left">
                                        <li *ngFor="let points of item1.points" class="fs-6" [innerHTML]="points"></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>

                        <div class="col-lg-5">
                            <img [src]="section_10.imgURL" [alt]="section_10.title" class="img-fluid mx-auto d-block"
                                loading="lazy" />
                        </div>
                    </div>

                    <p *ngFor="let item2 of section_10.points" class="fs-6" [innerHTML]="item2"></p>
                </div>
            </div>
        </section>
    </div>
    <div style="background-color: #f8f9fa !important">
        <div class="row ml-1 mt-2 mb-2 mr-1">
            <div id="{{ section_11.id }}"></div>
            <div id="{{ section_12.id }}"></div>
            <div id="{{ section_13.id }}"></div>
            <div id="{{ section_14.id }}"></div>
            <div id="{{ section_15.id }}"></div>

            <mat-tab-group>
                <mat-tab label="SD-WAN vs SDN">
                    <!-- Section - 11 -->
                    <section id="{{ section_11.id }}">
                        <div class="xl pt-2" style="background-color: #f8f9fa">
                            <div class="">
                                <p class="fw-bold  text-md-left clr-blue-dark mb-3 h5">
                                    {{ section_11.title }}
                                </p>
                                <img [src]="section_11.imgURL" [alt]="section_11.title"
                                    class="img-fluid w-60 mx-auto d-block mt-5" loading="lazy" />
                                <table class="table table-responsive table-hover table-bordered mt-2">
                                    <thead>
                                        <tr class="text-center fs-4">
                                            <th scope="col">{{ section_11.t_header.attribute }}</th>
                                            <th scope="col">{{ section_11.t_header.sdn }}</th>
                                            <th scope="col">{{ section_11.t_header.sd_wan }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let row of section_11.t_body">
                                            <td scope="col">
                                                <p class="clr-blue-dark text-bold fs-6">{{ row.attribute }}</p>
                                            </td>
                                            <td scope="col">
                                                <p *ngFor="let col of row.sdn" [innerHTML]="col" class="fs-6"></p>
                                            </td>
                                            <td scope="col">
                                                <p *ngFor="let col of row.sd_wan" [innerHTML]="col" class="fs-6"></p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </section>
                </mat-tab>
                <mat-tab label="SD-WAN vs MPLS">
                    <!-- Section - 12 -->
                    <section id="{{ section_12.id }}">
                        <div class="xl pt-2">
                            <div class="text-normal">
                                <p class="fw-bold  text-md-left clr-blue-dark mb-3 h5">
                                    {{ section_12.title }}
                                </p>
                                <img [src]="section_12.imgURL" [alt]="section_12.title"
                                    class="img-fluid mx-auto w-60 d-block mt-2" loading="lazy" />
                                <p *ngFor="let item of section_12.blocks" class="fs-6 pt-2" [innerHTML]="item">
                                </p>
                            </div>
                        </div>
                    </section>
                </mat-tab>
                <!-- section13 -->
                <mat-tab label="SD-WAN vs VPN">
                    <section id="{{ section_13.id }}">
                        <div class="xl pt-2">
                            <div class="pt-2 text-normal">
                                <p class="fw-bold  text-md-left clr-blue-dark mb-3 h5">
                                    {{ section_13.title }}
                                </p>
                                <!--  -->
                                <img [src]="section_13.imgURL" [alt]="section_13.title"
                                    class="img-fluid mx-auto d-block mt-2 w-50" loading="lazy" />
                                <p *ngFor="let item1 of section_13.texts" class="fs-6 mt-4" [innerHTML]="item1">
                                </p>
                                <ul *ngFor="let item2 of section_13.blocks" class="list-unstyled lh-lg">
                                    <li class="clr-blue-dark mt-3 fs-5 fw-bold">{{ item2.heading }}</li>
                                    <li class="pl-4">
                                        <ul class="text-left">
                                            <li *ngFor="let points of item2.points" class="fs-6" [innerHTML]="points">
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </mat-tab>
                <mat-tab label="SD-WAN vs DMVPN">
                    <!-- Section - 14 -->
                    <section id="{{ section_14.id }}">
                        <div class="xl pt-2">
                            <div class="text-normal">
                                <p class="fw-bold  text-md-left clr-blue-dark mb-3 h5">
                                    {{ section_14.title }}
                                </p>
                                <p *ngFor="let item of section_14.blocks" class="fs-6 mt-2" [innerHTML]="item">
                                </p>
                            </div>
                        </div>
                    </section>
                </mat-tab>
                <mat-tab label="SD-WAN vs WAN OPTIMIZATION">
                    <!-- Section - 15 -->
                    <section id="{{ section_15.id }}">
                        <div class="xl pt-2">
                            <div class="text-normal">
                                <p class="fw-bold  text-md-left clr-blue-dark mb-3 h5">
                                    {{ section_15.title }}
                                </p>
                                <p *ngFor="let item of section_15.blocks" class="fs-6 mt-2" [innerHTML]="item">
                                </p>
                            </div>
                        </div>
                    </section>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
    <div class="row ml-1 mr-1">
        <!-- Section - 17 -->
        <section id="{{ section_17.id }}">
            <div class="xl ml-1 pt-2">
                <div class="">
                    <p class="fw-bold  text-md-left clr-blue-dark mb-3 h5">
                        {{ section_17.title }}
                    </p>
                    <p class="fs-6 mt-4" [innerHTML]="section_17.start"></p>
                    <ul class="list-unstyled lh-lg">
                        <li class="fs-6 mt-3">{{ section_17.blocks.text }}</li>
                        <li class="pl-4">
                            <ul class="text-left mt-3">
                                <li *ngFor="let item of section_17.blocks.points" class="fs-6" [innerHTML]="item"></li>
                            </ul>
                        </li>
                    </ul>

                    <p class="fs-6 mt-4" [innerHTML]="section_17.end"></p>
                </div>
            </div>
        </section>

        <!-- Section - 18 -->
        <section id="{{ section_18.id }}">
            <div class="xl ml-1 pt-2">
                <div class="">
                    <p class="fw-bold  text-md-left clr-blue-dark mb-3 h5">
                        {{ section_18.title }}
                    </p>
                    <div class="col-lg-12 text-left">
                        <p class="fs-6 mt-4" [innerHTML]="section_18.start"></p>
                    </div>
                    <div class="row">
                        <div class="col-lg-5 text-left">
                            <ul class="text-lg-right mt-3">
                                <img [src]="section_18.imgURL" [alt]="section_18.title"
                                    class="img-fluid mx-auto d-block mt-2 w-60" loading="lazy" />
                            </ul>
                        </div>
                        <div class="col-lg-5 text-left" style="margin-left: 33px">
                            <ul class="list-unstyled lh-lg-left">
                                <li class="fs-6 mt-3">{{ section_18.blocks.text }}</li>
                            </ul>

                            <li *ngFor="let item of section_18.blocks.points" class="fs-6"
                                style="text-indent: -20px; padding-top: 10px" [innerHTML]="item"></li>
                        </div>
                    </div>
                    <p class="text-bolder text-center mt-4 mb-3 fs-4" [innerHTML]="section_18.quotes"></p>
                </div>
            </div>
        </section>

        <!-- Section - 19 -->
        <section id="{{ section_19.id }}">
            <div class="xl ml-1 pt-2">
                <div class=" text-md-left">
                    <p class="fw-bold clr-blue-dark mb-3 h5">{{ section_19.title }}</p>
                    <ul class="list-unstyled lh-lg">
                        <li class="pl-4">
                            <ul class="text-left">
                                <li *ngFor="let i of section_19.points" class="fs-6" [innerHTML]="i"></li>
                            </ul>
                        </li>
                    </ul>

                    <div class="row pb-3 position-relative">
                        <img src="https://vcdn.cosgrid.com/website/assets/images/news/talktous.png" alt="talktous"
                            class="img-fluid w-60 mx-auto d-block" style="width: 100%" loading="lazy" />

                        <p id="talktous" class="text-bolder fs-5" [innerHTML]="section_19.quotes">
                        <p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>