import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Subject, Subscription } from 'rxjs';

import { MatPaginator } from '@angular/material/paginator';

import { merge, of } from 'rxjs';
import { startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import META_QUERY from '../../../queries/Meta/meta.service';
import EVENTS_QUERY from '../../../queries/events/events.service';
import { CanonicalService } from '../../../queries/canonical/canonical.service';


@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss'],
})

export class EventsComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>()
    events: any[] = [];
    loading: boolean = true;
    errors: any;
    constructor(private apollo: Apollo, private router: Router, private titleService: Title, private metaService: Meta, private fetchMetaService: FetchMetaService, private canonicalService: CanonicalService) { }
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator | undefined;
    showContent: boolean = false
    event: any
    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        try {
            if (this.fetchMetaService.getData() == false) {
                let metatags = [];
                this.apollo
                    .watchQuery({
                        query: META_QUERY,
                    })
                    .valueChanges
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((result) => {
                        let meta = [];
                        meta = (result.data as any).metaTags.data.map((metaTag: any) => (
                            {
                                ...metaTag,
                            }
                        ));
                        for (var i = 0; i < meta.length; i++) {
                            metatags[meta[i].attributes.URL_ID] = meta[i];
                        }
                        // console.log("Meta Tags : ", this.metatags);
                        this.fetchMetaService.storeData(metatags);
                        let metaTagsData = this.fetchMetaService.getData();
                        // console.log("Meta Tags ", metaTagsData);
                        this.titleService.setTitle(metaTagsData['events'].attributes.title);
                        this.metaService.updateTag({
                            name: 'description',
                            content:
                                metaTagsData['events'].attributes.description,
                        });
                        this.metaService.updateTag({
                            name: 'keywords',
                            content: metaTagsData['events'].attributes.keywords
                        });
                    });
            }
            else {
                // console.log("Data Alreday Fetched");
                const data = this.fetchMetaService.getData();
                this.titleService.setTitle(data['events'].attributes.title);
                this.metaService.updateTag({
                    name: 'description',
                    content:
                        data['events'].attributes.description,
                });
                this.metaService.updateTag({
                    name: 'keywords',
                    content: data['events'].attributes.keywords
                });

            }
        }
        catch {
            //     this.titleService.setTitle('Events | COSGrid Networks');
            // this.metaService.updateTag({ name: 'description', content: 'Stay updated with the latest events and conferences where COSGrid Networks has participated.' });
            // this.metaService.updateTag({ name: 'keywords', content: 'events, conferences, networking conferences, technology summits, industry events, COSGrid Networks, participation, collaboration, innovative solutions' });
        }

        this.loading = true;
        this.apollo
            .watchQuery({
                query: EVENTS_QUERY,
            })
            .valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                this.events = (result.data as any).newsInfos.data.map((event: any) => ({
                    ...event,
                    image: event.attributes?.Title_image?.data[0].attributes?.url,
                }));
                // console.log(this.events);
                this.errors = result.errors;
                this.loading = false;
            });
    }
    // ngAfterViewInit() {
    //     this.paginator = this.paginator;
    //     }
    linkListToPaginator() {
        merge(this.paginator?.page).pipe(
            startWith({}),
            switchMap(() => {
                return of(this.events);
            }))
            .subscribe(res => {
                const from = this.paginator!.pageIndex * 4;
                const to = from + 4;
                this.events = res.slice(from, to);
            });
    }

    toogleContent(event: any) {
        this.event = event;
        this.showContent = !this.showContent;
    }
    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
