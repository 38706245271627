<div
    *ngIf="loading == true"
    style="display: flex; height: 100vh; width: 100vw; margin: 0 0; padding: 0 0; background-color: #eeeeee;"
>
    <div style="margin: auto;">
        <img
            style="display: block;"
            class="logo-loading"
            src="https://vcdn.cosgrid.com/website/assets4/navbar/fLogo.svg"
            alt="Logo loading.."
            loading="lazy"
        />
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    >
</div>

<section id="awards" style="padding-top: 80px;">
    <div class="section-title">
        <h1 class="mb-5 mt-4 fw-bold h2 clr-blue-dark">Awards</h1>
    </div>
    <div class="cardContainer">
        <div *ngFor="let award of awards" class="cardAwards">
            <div class="imageContainer">
                <img class="imageawards" [src]="award.image" alt="awards" loading="lazy" />
            </div>
            <div class="cardtitle">
                <p class="fw-bold clr-blue-dark h5">{{ award.attributes.Title }}</p>
            </div>
            <div class="cardcontents">
                <p class=" mt-4 mb-4 fw-normal text-dark fs-6">{{ award.attributes.Contents }}</p>
            </div>
            <div class="carddescription">
                <small class=" text-muted fs-6">{{ award.attributes.Description }}</small>
            </div>
        </div>
    </div>
</section>
