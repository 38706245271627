import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { CommonModule, isPlatformBrowser, NgFor, NgIf } from '@angular/common';
import { SharedModule } from './shared/shared.module';

// import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './pages/home/home.component';
import { SitemapComponent } from './pages/others/sitemap/sitemap.component';
import { TermsOfUseComponent } from './pages/others/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './pages/others/privacy-policy/privacy-policy.component';
import { IndustriesComponent } from './pages/industries/industries.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PagesModule } from './pages/pages.module';
import { GraphQLModule } from './graphql.module';
import { HttpHandler } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from "ngx-toastr"


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: []
})
export class AppComponent {

  constructor(@Inject(PLATFORM_ID) protected platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth;
    }
  }

  

  screenWidthDetermined: boolean = false;
  screenWidth: number = 0;
  fullyLoaded: boolean = false;

  router: string = '';
  title: string = 'COSGrid Networks';

  ngOnInit() {
    this.fullyLoaded = false
    this.updateScreenWidth();
  }

  @HostListener('window:load', ['$event'])
  onLoad(event: Event) {
      if (isPlatformBrowser(this.platformId))
          this.fullyLoaded = true;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
      if (isPlatformBrowser(this.platformId))
          this.updateScreenWidth();
  }

  private updateScreenWidth() {
    if (isPlatformBrowser(this.platformId)) {
      this.screenWidth = window.innerWidth;
      this.screenWidthDetermined = true;
    }
  }

  onActivate(event:Event) {
    if (isPlatformBrowser(this.platformId)) {
        window.scroll(0, 0);
        console.log(window.location.pathname);
        this.router = window.location.pathname;
    }
}

}
