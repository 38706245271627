import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-features-list',
  templateUrl: './features-list.component.html',
  styleUrls: ['./features-list.component.scss']
})
export class FeaturesListComponent {
  @Input() title!: string;
  @Input() subtitle!: string
  @Input() data!:any

  constructor() { }


}
