<div class="container-fluid mt-4 feature-box">
    <div class="row">
        <div class="col text-md-left text-center p-3 pb-4 clr-white" style="background-color: #006bc2;">
            <h1 class="fw-bold h2">{{ title }}</h1>
            <p class="fw-bold fs-4">{{subtitle}}</p>
        </div>
    </div>
    <div class="px-md-3" style="margin-top: -2rem;">
        <image-list
            class="d-flex flex-column justify-content-center align-items-center"
            [data]="data"
            [titleCenter]="true"
        ></image-list>
    </div>
</div>