import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subject, Subscription } from 'rxjs';
import AWARDS_QUERY from '../../../queries/awards/awards.service';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import META_QUERY from '../../../queries/Meta/meta.service';
import { takeUntil } from 'rxjs/operators';
import { CanonicalService } from '../../../queries/canonical/canonical.service';

@Component({
    selector: 'app-awards',
    templateUrl: './awards.component.html',
    styleUrls: ['./awards.component.scss'],
})
export class AwardsComponent implements OnInit {
    private destroy$ = new Subject<void>()
    awards: any[] = [];
    loading: boolean = true;
    errors: any;
    constructor(private apollo: Apollo, private router: Router, private titleService: Title, private metaService: Meta, private fetchMetaService: FetchMetaService, private canonicalService: CanonicalService) { }

    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        try {
            if (this.fetchMetaService.getData() == false) {
                let metatags = [];
                this.apollo
                    .watchQuery({
                        query: META_QUERY,
                    })
                    .valueChanges
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((result) => {
                        let meta = [];
                        meta = (result.data as any).metaTags.data.map((metaTag: any) => (
                            {
                                ...metaTag,
                            }
                        ));
                        for (let i = 0; i < meta.length; i++) {
                            metatags[meta[i].attributes.URL_ID] = meta[i];
                        }
                        // console.log("Meta Tags : ", this.metatags);
                        this.fetchMetaService.storeData(metatags);
                        let metaTagsData = this.fetchMetaService.getData();
                        // console.log("Meta Tags ", metaTagsData);
                        this.titleService.setTitle(metaTagsData['awards'].attributes.title);
                        this.metaService.updateTag({
                            name: 'description',
                            content:
                                metaTagsData['awards'].attributes.description,
                        });
                        this.metaService.updateTag({
                            name: 'keywords',
                            content: metaTagsData['awards'].attributes.keywords
                        });
                    });
            }
            else {
                // console.log("Data Alreday Fetched");
                const data = this.fetchMetaService.getData();
                this.titleService.setTitle(data['awards'].attributes.title);
                this.metaService.updateTag({
                    name: 'description',
                    content:
                        data['awards'].attributes.description,
                });
                this.metaService.updateTag({
                    name: 'keywords',
                    content: data['awards'].attributes.keywords
                });

            }

        }
        catch {
            //    this.titleService.setTitle('Awards and Recognition | COSGrid Networks');
            //    this.metaService.updateTag({ name: 'description', content: "Discover COSGrid's achievements, awards and patents. " });
            //    this.metaService.updateTag({ name: 'keywords', content: 'COSGrid, patent, Adaptive multi-path traffic, SD-WAN, 5G Security, CyberSecurity Grand Challenge, TANSEED 2nd Edition' });
        }

        this.loading = true;
        this.apollo
            .watchQuery({
                query: AWARDS_QUERY,
            })
            .valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                this.awards = (result.data as any).awards.data.map((award: any) => ({
                    ...award,
                    image: award?.attributes?.Title_image?.data[0].attributes?.url,
                }));
                console.log(this.awards);
                this.loading = false;
                this.errors = result.errors;
            });
    }

    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
