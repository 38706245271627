<app-carousel></app-carousel>

<!-- about -->



<section class="px-md-5 my-5">
    <div class="container-fluid shadow d-flex flex-column align-items-center p-3">
        <p class="fs-6">Welcome to <span class="clr-blue-dark">Z</span>3 SASE</p>
        <h3 class="h3 fw-bold text-center">Embrace the power of <span class="clr-blue-dark">Z</span>ero Trust, <span
                class="clr-blue-dark">Z</span>ero Glitch, <span class="clr-blue-dark">Z</span>ero Touch</h3>
        <p class="fs-6">COSGrid Networks empowers your business with seamless security, industry-leading protection and
            superior connected experience with <span class="clr-blue-dark">Smart SASE NetSecOps</span></p>
        <div class="d-flex justify-content-md-between justify-content-center flex-column flex-md-row h-100 w-100">
            <div *ngFor="let item of about"
                class="d-flex flex-column align-items-center justify-content-center h-100 w-100">
                <img [src]="item.imgUrl" [alt]="item.alt" [class]="item.class" loading="lazy">
                <p [innerHTML]="item.desc" class="text-center mt-4"></p>
            </div>
        </div>
    </div>
</section>

<!-- global image -->
<section class="px-md-5 my-5 px-2 d-flex flex-column justify-content-center align-items-center">
    <h1 class="text-center h3 fw-bold mb-5"><span class="clr-blue-dark fw-bold">Secure access</span> and <span
            class="clr-blue-dark fw-bold">protection</span> to users, data, applications, and workloads <br />
        across <span class="clr-blue-dark fw-bold">on-prem, clouds, remote sites</span>, and <span
            class="clr-blue-dark fw-bold">WFH users anywhere</span>.</h1>
    <img src="https://vcdn.cosgrid.com/website/assets4/home/homepage-content.webp" alt="about" class="w-50"
        loading="lazy">
</section>

<!-- Products comprehensive description -->
<section class="px-md-5 py-3 my-5 px-2 row gap-2" style="background: #d9e4f1;">
    <div class="col-md-3 d-flex flex-column align-items-center justify-content-center gap-2">
        <img src="assets/cosgrid shield.png" alt="cosgrid shield" width="150" height="200">
        <p class="h4 fw-bold text-center">Comprehensive
            <span class="clr-blue-dark fw-bold">
                Security and Networking Solutions
            </span>
            to Safeguard your Business
        </p>
    </div>
    <div class="col-md-9 row">
        <div class="col-12 col-md-6 py-2">
            <h3 class="h4 fw-bold clr-blue-dark">SD-WAN</h3>
            <p>Optimize performance and securely connect multiple branch
                offices or remote users. COSGrid's SD-WAN solutions
                provide reliable, low-latency connections while reducing
                networking costs & complexity
                </p>
        </div>
        <div class="col-12 col-md-6 py-2">
            <h3 class="h4 fw-bold clr-blue-dark">Next Generation Firewalls (NGFW)</h3>
            <p>COSGrid's Next Gen firewalls, which combine traditional
                firewall capabilities with deep packet inspection, applicationlayer filtering, and real-time threat intelligence to block
                malicious traffic and safeguard your enterprise.
                </p>
        </div>
        <div class="col-12 col-md-6 py-2">
            <h3 class="h4 fw-bold clr-blue-dark">SSE - Security Service Edge</h3>
            <p>Ensure secure access to cloud applications with COSGrid's SSE
                solutions. Our platform offers comprehensive protection for
                users, devices, and apps, utilizing a zero-trust architecture, secure
                internet browsing, and cloud workload security to safeguard your
                business from threats</p>
        </div>
        <div class="col-12 col-md-6 py-2">
            <h3 class="h4 fw-bold clr-blue-dark">SASE - Secure Access Service Edge</h3>
            <p>COSGrid's SASE framework combines SD-WAN, NDR and
                SSE into a single, integrated security and networking solution.
                With SASE, businesses can ensure seamless, secure access for
                users, no matter where they are, while maintaining network
                performance and visibility</p>
        </div>
        <div class="col-12 col-md-6 py-2">
            <h3 class="h4 fw-bold clr-blue-dark">Network Access Control (NAC)</h3>
            <p>COSGrid's NAC solution provides visibility and control over
                which devices can access your network. By enforcing policies
                based on user roles, device health, and security posture, we
                ensure that only authorized, secure devices are allowed access,
                preventing rogue devices from entering your network.
                </p>
        </div>
        <div class="col-12 col-md-6 py-2">
            <h3 class="h4 fw-bold clr-blue-dark">Network Detection & Response (NDR)</h3>
            <p>COSGrid's NDR solutions provide advanced threat detection
                and response capabilities, identifying suspicious network
                activity in real-time and allowing your security team to
                respond swiftly to minimize potential damage.
                </p>
        </div>
    </div>

</section>

<!-- products -->
<section class="px-md-5 my-5 px-2">
    <div class="row justify-content-center">
        <h1 class="clr-blue-dark fw-bold text-center mb-5 h2">Our Products</h1>
        <div *ngFor="let item of ourProducts"
            class="col-12 col-md-4 d-flex justify-content-center align-items-center p-2 p-lg-3 my-3 ">
            <div class="hover-popup shadow rounded w-100 h-100">
                <a class="d-flex align-items-center justify-content-center flex-column w-100 h-100 text-decoration-none clr-black-dark"
                    [routerLink]="item.link" [fragment]="item?.fragment"><img [src]="item.imgUrl" [alt]="item.name"
                        [class]="item.width" loading="lazy" />
                    <p class="mt-2 clr-black">{{item.name}}</p>
                </a>
            </div>


        </div>
    </div>
</section>

<!-- awards -->
<section class="px-md-5 my-5">
    <app-association [data]="awards" title="Awards and Recognition" headDirection="left"></app-association>
</section>



<!-- our customers -->
<section class="px-md-5 my-5">
    <div class="row justify-content-center">
        <h1 class="clr-blue-dark fw-bold text-center h2 mb-5">Our Customers</h1>
        <ngu-carousel [inputs]="carouselTile" [dataSource]="customerSliderList">
            <div class="item d-flex justify-content-center align-items-center" *nguCarouselDef="let item; let i = index"
                style="border: none; box-shadow: none">
                <img [src]="item.imgURL" [alt]="item?.alt" [class]="item?.class" loading="lazy" />
            </div>
        </ngu-carousel>
    </div>
</section>

<!-- testimonials -->
<section class="px-md-5 my-5">
    <app-testimonials> </app-testimonials>
</section>


<!-- overview carousels -->
<section class="px-md-5 my-5 px-2 bg-clr-blue-dark">
    <div class="row p-4">
        <div class="px-lg-5 col-12 col-lg-6 d-flex flex-column justify-content-center">
            <h1 class="mb-3 fw-bold clr-white h2">Why COSGrid for Your
                Networking and Security Needs?
                </h1>
            <!-- <p class="text-justify clr-white fs-6">{{ firewallIntro }}</p> -->
        </div>
        <div class="col-lg-6 ">
            <carousel-list [data]="OverviewHighlights"
                baseUrl="assets/" [descOnly]="true"></carousel-list>
        </div>
    </div>
</section>

<!-- Integration support -->
<section class="px-md-5 my-5">
    <div class="p-3"
        style="background: linear-gradient(90deg, #6801AF 0%, #00B3F0 100%); border-top-right-radius: 20px">
        <h1 class="fw-bold clr-white h2">Integration Support</h1>
        <h5 class="clr-white fs-5">Cloud Integration Support & SSO for the following platforms</h5>
    </div>
    <div class="row d-flex justify-content-center align-items-center h-100 py-lg-5 py-1 integration-row">
        <div class="col-md-3 col-12 image-box d-flex justify-content-center align-items-center hover-popup"
            *ngFor="let item of Integration">
            <a [href]="item.link" target="_blank"
                class="d-flex justify-content-center w-90 h-90 p-3 image-anchor bg-clr-white"><img [src]="item?.imgURL"
                    [alt]="item?.name" loading="lazy" class="img-fluid5" style="cursor: pointer"
                    style="max-height: 80px; object-fit: contain" [attr.width]="item.width" />
            </a>
            <!-- </div> -->
        </div>
    </div>
</section>

<!-- association and industry partner -->
<section class="px-md-5 my-5">
    <div class="row justify-content-center">
        <h1 class="clr-blue-dark fw-bold text-center h2 mb-5">Associations and Industry partners</h1>
        <ngu-carousel [inputs]="carouselTile" [dataSource]="associateWith">
            <div class="item d-flex justify-content-center align-items-center" *nguCarouselDef="let item; let i = index"
                style="border: none; box-shadow: none">
                <img [src]="item.imgURL" [alt]="item?.alt" [class]="item.class" loading="lazy" />
            </div>
        </ngu-carousel>
    </div>
</section>

<!-- video tutorials -->
<section class="px-md-5 my-5">
    <div class="row justify-content-center position-relative">
        <h1 class="clr-blue-dark fw-bold text-center h2 mb-5">Video Tutorials</h1>
        <ngu-carousel #videoCarousel [inputs]="carouselVideoTile" [dataSource]="videos">
            <div class="item" *nguCarouselDef="let video; let i = index" style="border: none; box-shadow: none">
                <div class="m-2 d-flex flex-column align-items-center">
                    <iframe width="300" height="195" [srcdoc]="video.srcdoc" title="YouTube video player"
                        frameborder="0" loading="lazy"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                    <p class=" mt-5 mx-5">{{ video.title }}</p>
                </div>
            </div>
            <button NguCarouselPrev class="nav-btn left-nav" [style.opacity]="videoCarousel.isFirst() ? 0.2:0.7"><i
                    class="fas fa-angle-left"></i></button>
            <button NguCarouselNext class="nav-btn right-nav" [style.opacity]="videoCarousel.isLast() ? 0.2:0.7"><i
                    class="fas fa-angle-right" style="user-select: auto;"></i></button>
        </ngu-carousel>
    </div>
</section>

<!-- why cosgrid -->
<section class="px-md-5 my-5 px-2">
    <app-features-list title="COSGrid Advantage" subtitle="" [data]="whyEnterprisesChooseCosgrid"></app-features-list>
</section>
<!-- <section class="px-md-5 my-5">
    <div class="container-fluid">
        <p class="text-center fw-bold clr-blue-dark fw-bold h2">Why COSGrid ?</p>
        <div class="pt-4">
            <table-list [data]=""
                baseUrl="https://vcdn.cosgrid.com/website/assets/home/why-choose-cosgrid/"></table-list>
        </div>
    </div>
</section> -->

<!-- blogs -->
<section class="my-5 px-md-5">
    <div class="container-fluid">
        <h1 class="fw-bold text-center clr-blue-dark h2">Blogs</h1>
        <div class="d-flex justify-content-around align-items-center flex-wrap">
            <div *ngFor="let i of numbers" class="card mt-4" style="width: 20rem; padding: 10px; border-radius: 20px"
                [ngStyle]="{ background: blogdata[i].background, color: blogdata[i].color }">
                <div class="date-container text-center">
                    <span class="font-bold">{{ blogdata[i].date.split('-')[0] }}</span><br />
                    <span>{{ blogdata[i].date.split('-')[1] }}&nbsp;{{ blogdata[i].date.split('-')[2] }}</span>
                </div>
                <img class="card-img-top" [src]="blogs?.[i]?.image" alt="Card image cap" loading="lazy" />
                <div class="card-body">
                    <p class="card-title text-white fs-6">{{ blogs?.[i]?.attributes.Title }}</p>

                    <p class="ml-auto text-white" (click)="routeToblog(blogs?.[i])" style="cursor: pointer">
                        Read more<i class="fa fa-angle-right fa-fw fa-sm"></i>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>