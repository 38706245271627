import { trigger, state, style, transition, animate } from '@angular/animations';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CanonicalService } from '../../../queries/canonical/canonical.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
        height: '0px',
        overflow: 'hidden',
        opacity: 0
      })),
      state('expanded', style({
        height: '*',
        opacity: 1
      })),
      transition('collapsed <=> expanded', [
        animate('300ms ease-in-out')
      ])
    ])
  ]
})
export class SupportComponent {

  openedBox: string = 'support';
  openedContent: string = 'support-term';

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private canonicalService: CanonicalService
  ) { }

  openBox(name: string): void {
    if (name === 'documentation' && isPlatformBrowser(this.platformId)) {
      window.open('https://docs.cosgrid.com/', '_blank');
      return;
    }
    this.openedBox = this.openedBox === name ? '' : name;
  }

  openContent(name: string): void {
    this.openedContent = name;
  }

  ngOnInit(): void {
    this.canonicalService.setCanonicalTag();
  }

}
