import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import { Subject, Subscription } from 'rxjs';
import META_QUERY from '../../../queries/Meta/meta.service';
import { Apollo } from 'apollo-angular';
import { takeUntil } from 'rxjs/operators';
import { CanonicalService } from './../../../queries/canonical/canonical.service';

export interface TermModel {
    title: string,
    text: string
}

@Component({
    selector: 'app-terms-of-use',
    templateUrl: './terms-of-use.component.html',
    styleUrls: ['./terms-of-use.component.scss'],
})


export class TermsOfUseComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    termsArray1to3: TermModel[] = [
        {
            title: 'A. Definitions',
            text: `In this Agreement, the terms "You" and "Your" refer to the individual or entity that has placed an order for products from COSGrid Networks or its authorized distributor or partner, as evidenced by the execution of the accompanying ordering document. The term "Products" collectively refers to all COSGrid Networks' SaaS, software, hardware, support programs (including upgrades), and any other services provided by COSGrid Networks as specified in the ordering document. The term "Program Documentation" refers to the user manual and any other materials provided by COSGrid Networks as part of the product stack. The term "Users" refers to individuals authorized by You or on Your behalf to use the services as defined in the ordering document. The term "Your Data" refers to the data provided by You that resides in Your services environment. The term "Ordering Document" refers to the document signed by the parties that accompanies and incorporates this Agreement, including the services policies and any other referenced or incorporated documents`
        },
        {
            title: 'B. Applicability of Agreement',
            text: `This Agreement, titled "COSGrid Networks Terms and Conditions," is applicable to the ordering document with which it is associated.`
        },
        {
            title: 'C. Rights Granted',
            text: `Upon COSGrid Networks' acceptance of Your order and for the duration of the services term specified in the ordering document, You are granted a non-exclusive, non-assignable, royalty-free, worldwide, limited right to use the services solely for Your internal business operations and subject to the terms of this Agreement. You may permit Your Users to use the services for this purpose, and You are responsible for ensuring their compliance with this Agreement. The services will be provided as described in the services policies referenced in the ordering document. You acknowledge that COSGrid Networks has no obligation to deliver physical copies of the products and that they will not be shipped to You as part of the services. You agree that Your rights to use the products specified in the ordering document and the services shall terminate upon the expiration or termination of this Agreement or the services provided hereunder.`
        },
    ];
    termsArray4: any[] = [
        {
            title: 'D. Ownership and Restrictions',
            text: `You retain full ownership and intellectual property rights to Your data. COSGrid Networks and its licensors maintain all ownership and intellectual property rights to the services and COSGrid Networks' programs. Any developments or deliverables created under this Agreement will be owned by COSGrid Networks. Third-party technology that may be necessary for the use of certain COSGrid Networks programs will be specified in the program documentation or ordering document, and its use will be governed by the respective third-party technology license agreement provided by COSGrid Networks, not under this Agreement. You are prohibited from the following:`,
            points: [`Removing or altering any program markings or notices of COSGrid Networks' or its licensors' proprietary rights.`, `Making the programs or materials resulting from the services available to any third party for use in their business operations, unless expressly permitted for the specific program or materials acquired from the services.`, `Modifying, creating derivative works of, disassembling, reverse compiling, or reverse engineering any part of the services, including reviewing data structures or similar materials produced by programs. You may not access or use the services to build or support, or assist a third party in building or supporting, products or services that compete with COSGrid Networks.`, `Disclosing the results of any services or program benchmark tests without prior written consent from COSGrid Networks.`, `Licensing, selling, renting, leasing, transferring, assigning, distributing, displaying, hosting, outsourcing, disclosing, permitting timesharing or service bureau use, or otherwise commercially exploiting or making the services, COSGrid Networks programs, or materials available to any third party, except as expressly permitted under the terms of this Agreement.`]
            , points_text: `The rights granted to You under this Agreement are also subject to the following conditions:`,
            points2: ['The rights of any user to use the services (e.g., on a "named user" basis) cannot be shared or used by more than one individual unless the user is entirely reassigned to another authorized user, in which case the prior authorized user will no longer have the right to use the product.', 'Except as expressly provided herein, no part of the services may be copied, reproduced, distributed, republished, downloaded, displayed, posted, or transmitted in any form or by any means, including electronic, mechanical, photocopying, recording, or other means.', 'You agree to take reasonable measures to prevent unauthorized third parties from accessing the services.'],
            endText: `COSGrid Networks utilizes third-party software in the development and operation of its products. The use of this software is subject to the respective license agreements provided by the software vendors. In cases where the software is embedded within COSGrid Networks' products and is distributed under open source licenses that require the availability of source code, COSGrid Networks will fulfill its obligation by making the relevant source code portions (including any modifications made by COSGrid Networks) available upon request`
        }
    ];
    termsArray5: any = {
        title: 'E.Warranty: ',
        text: `COSGrid Networks warrants that the services/products will be free from material defects in workmanship and materials for a period of 365 days from the date of original purchase. If any defect is found during the warranty period, COSGrid Networks will repair or replace the defective hardware or software at no charge to the customer.
        COSGrid Networks' obligation under this warranty is limited to repair or replacement of defective hardware or software. COSGrid Networks is not liable for any incidental or consequential damages, including but not limited to lost profits, data loss, or damage to other equipment.
        This warranty does not cover damage caused by accident, misuse, or abuse. COSGrid Networks also disclaims any warranty for products that have been modified or repaired by unauthorized personnel.
        <br>`,

    };
    constructor(private canonicalService: CanonicalService, private titleService: Title, private metaService: Meta, private fetchMetaService: FetchMetaService, private apollo: Apollo) { }

    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        if (this.fetchMetaService.getData() == false) {
            let metatags = [];
            this.apollo
                .watchQuery({
                    query: META_QUERY,
                })
                .valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                    let meta = [];
                    meta = (result.data as any).metaTags.data.map((metaTag: any) => (
                        {
                            ...metaTag,
                        }
                    ));
                    for (let i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    // console.log("Meta Tags ", metaTagsData);
                    this.titleService.setTitle(metaTagsData['terms-of-use'].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content:
                            metaTagsData['terms-of-use'].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData['terms-of-use'].attributes.keywords
                    });
                });
        }
        else {
            // console.log("Data Alreday Fetched");
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data['terms-of-use'].attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content:
                    data['terms-of-use'].attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data['terms-of-use'].attributes.keywords
            });

        }
    }

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
