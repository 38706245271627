<section class="px-md-5 my-5 px-2 top pt-3">
    <div class="container-home text-white">
        <div class="row text-align-center lg-col-reverse">
            <div class="col-12 col-lg-5 text-center text-lg-left pt-5 pl-lg-5 md-col-reverse">
                <h1 class="fw-bold clr-white h2">Contact Us</h1>

                <p class=" mt-4 fs-6">For more call, mail or visit us. Our team is here to help.</p>

                <!-- Start Building -->
            </div>
            <div class="col-lg mt-3 text-center text-lg-right p-4">
                <div class="top-bar-height"></div>
            </div>
        </div>
    </div>
</section>


<!-- about and form -->
 <section class="px-md-5 my-5 px-2">
    <div class="mtext">
        <p class="fw-bold pr-md-5 h4 mb-2 text-center text-md-left">
            COSGrid Networks secure software-defined networking platform (SD-WAN, SASE and NDR) will enable:
        </p>
        <div *ngFor="let item of description" class="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" width="18px" class="mt-1"
                    height="18px"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                    <path fill="#004d65"
                        d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                </svg>
            <p class="ms-2 fs-6">{{item}}</p>
        </div>
        <a routerLink="/product" class="btn btn-dark hover-popup">View More</a>
    </div>
    <div class="form-box">
        <form [formGroup]="contactForm" class="p-lg-5 p-2 bg-clr-white shadow rounded" (ngSubmit)="onSubmit()">
            <p class="fw-bold h4">Get in Touch</p>
            <div class="row mt-3">
                <div class="col-6">
                    <div class="w-100">
                        <input placeholder="First name *" type="text" class="form-control rouded-0" id="form-name"
                            formControlName="name" [ngClass]="{
                                'is-invalid':
                                    contactForm.get('name')?.errors &&
                                    (contactForm.get('name')?.touched || onSubmitted)
                            }" required />
                        <div *ngIf="
                                contactForm.get('name')?.errors && (contactForm.get('name')?.touched || onSubmitted)
                            " id="form-name-invalid" class="invalid-feedback">
                            <div *ngIf="contactForm.get('name')?.errors?.['required']">
                                Please provide your <strong>First name</strong>
                            </div>
                            <div *ngIf="contactForm.get('name')?.errors?.['minlength']">
                                Minimum required number is 2.
                            </div>
                            <div *ngIf="contactForm.get('name')?.errors?.['maxlength']">
                                Minimum required number is 15.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="w-100 ">
                        <input placeholder="Last name *" type="text" class="form-control rouded-0" id="form-Lastname"
                            formControlName="Lastname" [ngClass]="{
                                'is-invalid':
                                    contactForm.get('Lastname')?.errors &&
                                    (contactForm.get('Lastname')?.touched || onSubmitted)
                            }" required />
                        <div *ngIf="
                                contactForm.get('Lastname')?.errors &&
                                (contactForm.get('Lastname')?.touched || onSubmitted)
                            " id="form-Lastname-invalid" class="invalid-feedback">
                            <div *ngIf="contactForm.get('Lastname')?.errors?.['required']">
                                Please provide your <strong>Last name</strong>
                            </div>
                            <div *ngIf="contactForm.get('Lastname')?.errors?.['minlength']">
                                Minimum required number is 2.
                            </div>
                            <div *ngIf="contactForm.get('Lastname')?.errors?.['maxlength']">
                                Minimum required number is 15.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-100 mt-3">
                <input placeholder="Email *" type="text" class="form-control rounded-0" id="form-email"
                    formControlName="email" [ngClass]="{
                        'is-invalid':
                            contactForm.get('email')?.errors && (contactForm.get('email')?.touched || onSubmitted)
                    }" required />
                <div *ngIf="contactForm.get('email')?.errors && (contactForm.get('email')?.touched || onSubmitted)"
                    class="invalid-feedback" id="form-email-error" class="invalid-feedback">
                    <div *ngIf="contactForm.get('email')?.errors?.['required']">
                        Please provide your <strong>Email ID</strong>
                    </div>
                    <div *ngIf="contactForm.get('email')?.errors?.['email']">Please provide a Valid Email ID</div>
                </div>
            </div>

            <div class="w-100 mt-3">
                <input placeholder="Phone *" type="tel" class="form-control rounded-0" id="form-phone"
                    formControlName="phone" maxlength="10" [ngClass]="{
                        'is-invalid':
                            contactForm.get('phone')?.errors && (contactForm.get('phone')?.touched || onSubmitted)
                    }" required />
                <div id="form-phone-error"
                    *ngIf="contactForm.get('phone')?.errors && (contactForm.get('phone')?.touched || onSubmitted)"
                    class="invalid-feedback" class="invalid-feedback">
                    <div *ngIf="contactForm.get('phone')?.errors?.['required']">
                        Please provide your <strong>Phone Number</strong>
                    </div>
                    <div *ngIf="contactForm.get('phone')?.errors?.['pattern']">Please provide a Valid Phone Number</div>
                </div>
            </div>

            <div class="w-100 mt-3">
                <input placeholder="Company name *" type="text" class="form-control rounded-0" id="form-company_name"
                    formControlName="company_name" [ngClass]="{
                        'is-invalid':
                            contactForm.get('company_name')?.errors &&
                            (contactForm.get('company_name')?.touched || onSubmitted)
                    }" required />
                <div *ngIf="
                        contactForm.get('company_name')?.errors &&
                        (contactForm.get('company_name')?.touched || onSubmitted)
                    " id="form-name-invalid" class="invalid-feedback">
                    <div *ngIf="contactForm.get('company_name')?.errors?.['required']">
                        Please provide your <strong>Company Name</strong>
                    </div>
                    <div *ngIf="contactForm.get('company_name')?.errors?.['minlength']">
                        Minimum required number of characters is 2.
                    </div>
                    <div *ngIf="contactForm.get('company_name')?.errors?.['maxlength']">
                        Minimum required number of characters is 15.
                    </div>
                </div>
            </div>
            <div class="w-100 mt-3">
                <input placeholder="Job Function *" type="text" class="form-control rounded-0" id="form-job_function"
                    formControlName="job_function" [ngClass]="{
                        'is-invalid':
                            contactForm.get('job_function')?.errors &&
                            (contactForm.get('job_function')?.touched || onSubmitted)
                    }" required />

                <div *ngIf="
                        contactForm.get('job_function')?.errors &&
                        (contactForm.get('job_function')?.touched || onSubmitted)
                    " id="form-name-invalid" class="invalid-feedback">
                    <div *ngIf="contactForm.get('job_function')?.errors?.['required']">
                        Please fill the <strong>Job function</strong>
                    </div>
                    <div *ngIf="contactForm.get('job_function')?.errors?.['minlength']">
                        Minimum required number of characters is 2.
                    </div>
                    <div *ngIf="contactForm.get('job_function')?.errors?.['maxlength']">
                        Minimum required number of characters is 15.
                    </div>
                </div>
            </div>
            <div class="w-100 mt-3">
                <select class="form-control" formControlName="country" id="country">
                    <option value="" disabled selected style="color: grey;">Select Country</option>
                    <option *ngFor="let countryx of country" [value]="countryx.name">{{ countryx.name }}</option>
                </select>
                <div *ngIf="contactForm.get('country')?.errors && (contactForm.get('country')?.touched || onSubmitted)"
                    id="form-country-invalid" class="invalid-feedback">
                    <div *ngIf="contactForm.get('country')?.errors?.['required']">
                        Please fill the <strong>Country</strong>
                    </div>
                    <div *ngIf="contactForm.get('country')?.errors?.['minlength']">
                        Minimum required number of characters is 2.
                    </div>
                    <div *ngIf="contactForm.get('country')?.errors?.['maxlength']">
                        Minimum required number of characters is 15.
                    </div>
                </div>
            </div>
            <div class="w-100 mt-3">
                <textarea placeholder="Message" class="form-control rounded-0" id="form3-msg" formControlName="msg"
                    rows="4" style="resize: none"></textarea>
            </div>
            <div class="d-flex justify-content-center mt-3">
                <ngx-recaptcha2 #captchaElem siteKey="6LflT3EgAAAAANg6Mw-W9MzCfJtjt3nZ7IAK1WbU"
                    (success)="handleSuccess($event)" [size]="size" [hl]="lang" [theme]="theme" [type]="type"
                    formControlName="recaptcha">
                </ngx-recaptcha2>
            </div>
            <div class="d-flex justify-content-center mt-5">
                <button class="btn btn-primary hover-popup w-70" type="submit">
                    Submit
                </button>
            </div>
        </form>
    </div>
 </section>

 <!-- our locations -->
<section class="px-md-5 my-5 px-2 pt-md-5">
        <h1 class="fw-bold text-center mb-5 mt-5 h2">Our Locations:</h1>
        <div class="d-flex align-items-center flex-wrap justify-content-evenly">
            <div class="cardlocation" *ngFor="let item of ourLocations">
                <div class="l">
                    <iframe
                        [src]="item.src"
                        frameborder="0" style="border: 0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                </div>
                <div class="pcontain">
                    <p class="mt-4 fs-6">
                        <i class="text-blue mr-1 fas fa-map-marked-alt"></i>
                        <span class="font-bold">{{item.locType}}</span>
                        <br />
                        <span class="fs-6" [innerHTML]="item.address"></span>
                    </p>
                </div>
            </div>
        </div>
</section>

<!-- contacts -->
 <section class="px-md-5 mb-5 px-2">
    <div class="row px-md-5">
        <div class="col-md">
            <div class="m-2 mb-5 mb-md-5 text-center text-md-left">
                <p class="clr-blue-dark fw-bold h3">Call us</p>
                <hr class="clr-blue-dark"/>
                <p class="fs-6">
                    Sales:
                    <a href="tel:+919022764534"><i class="text-blue mr-4 fas fa-phone-alt"
                            ></i>+91 9022764534</a>
                </p>

                <p class="fs-6">
                    Info:
                    <a href="tel:+918610144212"><i class="text-blue mr-3 fas fa-phone-alt"
                            ></i>+91 8610144212</a>
                </p>

                <p class="fs-6">
                    Careers:
                    <a href="tel:+919884764534"><i class="text-blue mr-3 fa fa-lg fa-whatsapp"></i>+91
                        9600019407</a>
                </p>
            </div>
        </div>
        <div class="col-md">
            <div class="m-2 mb-5 mb-md-5 text-center text-md-left">
                <p class="clr-blue-dark fw-bold h3">Mail us</p>
                <hr />
                <p class="fs-6">
                    <a href="mailto:info@cosgrid.com?Subject=Hello"><i
                            class="text-blue mr-3 fas fa-envelope"></i>info&#64;cosgrid.com</a>
                </p>
                <p class="fs-6">
                    <a href="mailto:sales@cosgrid.com.com?Subject=Hello"><i
                            class="text-blue mr-3 fas fa-envelope"></i>sales&#64;cosgrid.com</a>
                </p>
                <p class="fs-6">
                    <a href="mailto:contact@cosgrid.com?Subject=Hello"><i
                            class="text-blue mr-3 fas fa-envelope"></i>contact&#64;cosgrid.com</a>
                </p>
            </div>
        </div>
    </div>
 </section>