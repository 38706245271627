import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { MyEmitterService } from '../../../app-emitter.service';
import { products, solutions, industries, partners, resources, company } from '../navbar.utils';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrl: './mobile-navbar.component.scss'
})
export class MobileNavbarComponent {

  constructor(
    private router: Router,
    private emitter: MyEmitterService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: object
  ) {

  }
  homeNavs = [
    {
      link: '',
      name: '',
    },
  ];
  image = 'https://vcdn.cosgrid.com/website/assets/images/navbar/fLogo.svg';
  menutoggle: any;

  
  products = products
  solutions = solutions
  industries = industries
  partners = partners
  resources = resources
  company = company
  isScrolled = false;

  @HostListener('window:scroll')
  onWindowScroll() {
      if (isPlatformBrowser(this.platformId)) {
          this.image = 'https://vcdn.cosgrid.com/website/assets/images/navbar/fLogo.svg';
          window.pageYOffset >= 80 ? (this.isScrolled = true) : (this.isScrolled = false);
      }
  }

  productClick(item: any) {
    this.togglefn(item.link);
    //console.log(item);
    this.emitter.emitproductEvent(item);
  }

  componentClick(link: any) {
    this.togglefn(link);
    this.emitter.emitcomponentEvent(link);
  }

  industriesClick(link: any) {
    //console.log(link);
    this.togglefn(link);
    this.emitter.emitIndustriesEvent(link);
    // console.log(link);
  }

  togglefn(link: string) {
    if (link.includes('https://')) {
      window.open(link, '_blank');
    } else {
      if (this.menutoggle == 'menu') { this.openedTab = ''; this.menutoggle = 'close'; }
    }
  }

  openedTab = ''
  openLinks(tab: string) {
    this.openedTab == tab ? this.openedTab = '' : this.openedTab = tab;
  }
}
