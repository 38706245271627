import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { TicketService } from '../ticket.service';
import { Subject } from 'rxjs';
import { CanonicalService } from './../../../../queries/canonical/canonical.service';

@Component({
  selector: 'app-submit-ticket',
  templateUrl: './submit-ticket.component.html',
  styleUrls: ['./submit-ticket.component.scss']
})
export class SubmitTicketComponent implements  OnDestroy {
  private destroy$ = new Subject<void>()
  ticketForm: FormGroup;
  caseTypeList = [
    {
      name: 'Problem',
      value: 'problem'
    },
    {
      name: 'Question',
      value: 'question'
    },
    {
      name: 'Categorization',
      value: 'categorization'
    },
    {
      name: 'Provisioning',
      value: 'provisioning'
    }
  ]

  priorityList = [

    {
      name: 'Urgent',
      value: 'urgent'
    },
    {
      name: 'High',
      value: 'high'
    },
    {
      name: 'Medium',
      value: 'medium'
    },
    {
      name: 'Low',
      value: 'low'
    }
  ]

  workingHoursList = [

    {
      name: 'India Standard Time (Asia / Kolkata)',
      value: 'india-standard-time(Asia / Kolkata)'
    },

  ]
  captchaSuccess !: boolean;
  recaptchaKey: string = '6LflT3EgAAAAANg6Mw-W9MzCfJtjt3nZ7IAK1WbU'
  @ViewChild('captchaElem') captchaElem !: ReCaptcha2Component;
  constructor(
    private fb: FormBuilder,
    private service: TicketService,
    private canonicalService: CanonicalService
  ) {
    this.ticketForm = this.fb.group({
      caseType: ['', Validators.required],
      subject: [''],
      priority: ['', Validators.required],
      companyName: ['', Validators.required],
      description: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')]],
      phoneNumber: ['', [Validators.required, Validators.pattern('^\\d{10}$')]],
      countryCode: ['', [Validators.required]],
      collaborator: [''],
      preferredWorkingHours: ['', Validators.required],
      modeOfCommunication: ['', Validators.required],
      recaptcha: new FormControl('', [Validators.required])
    });
  }
  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type !: 'image' | 'audio';

  ngOnInit() {
    this.canonicalService.setCanonicalTag()
  }


  handleSuccess(event: any) {
    //console.log(event);
    this.captchaSuccess = true;
  }

  validateForm() {
    if (!this.ticketForm.valid) {
      for (let i in this.ticketForm.controls) {
        this.ticketForm.controls[i].markAsTouched();
      }
      return false;
    } else {
      return true;
    }
  }

  onSubmit(): void {
    if (this.validateForm()) {
      // Process the form data here
      console.log('Form Submitted', this.ticketForm.value);
      this.service.sendTicket({data: this.ticketForm.value}).subscribe(
        (res) => {
          console.log('Ticket submitted successfully');
          this.captchaElem.reloadCaptcha()
          this.ticketForm.reset();
        },
        (err) => {
          console.log('Ticket submitted error: ' + err);
          
        }
      )
      // this.captchaElem.reloadCaptcha()
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
}
}

