import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import * as ztna from '../../../../core/models/ztna.model';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import META_QUERY from '../../../../queries/Meta/meta.service';
import { FetchMetaService } from '../../../../queries/Meta/fetchmeta.service';
import { CanonicalService } from '../../../../queries/canonical/canonical.service';

@Component({
    selector: 'app-ztna',
    templateUrl: './ztna.component.html',
    styleUrls: ['./ztna.component.scss'],
})
export class ZtnaComponent implements OnInit {
    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event) {
        if (isPlatformBrowser(this.platformId)) {
            const footerHeight = document.querySelector('.footer')?.clientHeight || 0 + 115;
            const currScroll = window.pageYOffset + 303;
            const maxScroll = document.body.scrollHeight;
        }

        // console.log('max offset ' + limit);
        // if (maxScroll - currScroll <= footerHeight) {
        //     (document.querySelector('.sidenav') as HTMLElement).style.position = 'absolute';
        // } else {
        //     (document.querySelector('.sidenav') as HTMLElement).style.position = 'fixed';
        // }
    }

    section1to2: ztna.ISection[] = [{
        id: 'what-is-ztna',
        title: 'What is Zero Trust Network Access (ZTNA) ?',
        nav: 'What is Zero Trust Network Access (ZTNA) ?',
        text: [
            {
                desc: "Zero Trust Network Access (ZTNA) is an IT security solution that provides secure least privileged access to an organization's data, resources/applications and services based on the defined access control policies of Who ,What, When, Why and How with visibility. ZTNA is all about Micro Segmented remote access which narrowing down the attack surface and thus improves the organizations cybersecurity posture. In simple terms, ZTNA is a dynamic security with no trust issues that makes hackers go for uncertainty.",
                points: [],
            },
        ],
    },
    {
        id: 'features-of-ztna',
        title: 'Core features of Zero Trust Network Access (ZTNA) ?',
        nav: 'Core features of Zero Trust Network Access (ZTNA) ?',
        text: [
            {
                desc: 'The Core Features of ZTNA include:',
                points: [
                    'Extended Identity for Endpoints',
                    'Role based Access Control',
                    'Device Trust',
                    'Universal Protocol support',
                    'Resources invisible on Internet',
                    'Protect both User and App traffic (North-South) as well App to App traffic (East-West)',
                    'Defense in depth through multi-layered verification'
                ]
            }
        ]
    }];

    section3to6: ztna.ISection[] = [
        {
            id: 'zero-Trust-Frameworks',
            title: 'Key Zero Trust Frameworks & Product Architectures',
            nav: 'Key Zero Trust Frameworks & Product Architectures',
            text: [
                {
                    desc: 'Zero Trust Frameworks are strategic guidelines that outline the principles and practices for implementing Zero Trust security. They provide a high-level roadmap without being specific to any particular technology or product. Notable examples of Zero Trust Frameworks include the NIST SP 800-207 document and the CISA Zero Trust Maturity Model.',
                    points: []
                },
                {
                    desc: '<strong>NIST SP 800-207: Zero Trust Architecture</strong> - This influential document, created by the National Institute of Standards and Technology, outlines the core principles of Zero Trust and provides a comprehensive reference model.',
                    points: []
                },
                {
                    desc: '<strong>CISA Zero Trust Maturity Model</strong> - Developed by the Cybersecurity & Infrastructure Security Agency, this model defines five pillars for implementing Zero Trust and offers a staged approach for organizations to assess their progress.',
                    points: []
                },
                {
                    desc: 'On the other hand, Zero Trust Product Architectures are technical blueprints that detail how specific products incorporate Zero Trust principles. These architectures explain how different technologies and components work together to achieve the desired level of Zero Trust security. Typically, individual security vendors create these architectures for their ZTNA (Zero Trust Network Access) or other Zero Trust-related products',
                    points: []
                },
                {
                    desc: '<strong>1.⁠ ⁠Zero Trust Network Access (ZTNA) architectures</strong> - Various security vendors offer ZTNA products, each with its own unique technical architecture. These architectures typically involve components such as user authentication gateways, policy engines, and secure tunnels for application access. For detailed information on specific ZTNA vendor architectures, refer to their documentation.',
                    points: [
                        'Software Defined Perimeter',
                        'Zero-Trust Overlay Network',
                        'Identity Aware Proxy',
                        'Privileged Access Management',
                        'Host-based Firewall Control',
                        'Identity Defined Network'
                    ]
                },
                {
                    desc: '<strong>2.⁠ ⁠Cloud Access Security Broker (CASB) architectures</strong> - CASBs can be utilized to enforce Zero Trust principles for cloud applications. CASB architectures encompass components for policy enforcement, data security, and API access control. To understand the product architectures of specific CASB vendors, consult their documentation.',
                    points: []
                },
                {
                    desc: 'Remember, frameworks and product architectures play distinct roles in implementing Zero Trust security. Frameworks provide strategic guidance, while product architectures offer technical blueprints for specific products.',
                    points: []
                }
            ]
        },
        {
            id: 'foundation-of-zero-trust-or-ztna-pillars',
            title: 'Foundation of Zero Trust or ZTNA Pillars',
            nav: 'Foundation of Zero Trust or ZTNA Pillars',
            text: [
                {
                    desc: '',
                    points: [
                        '<strong>Identity</strong>: This pillar emphasizes the importance of robust identity management through the implementation of Multi-Factor Authentication (MFA) and precise access controls based on user identity.',
                        '<strong>Devices</strong>: Ensuring the security and well-being of devices that connect to the network is of utmost importance. This entails implementing measures for endpoint security and conducting compliance checks',
                        '<strong>Network, Data, and Applications (Workloads)</strong>: This pillar encompasses various aspects such as network segmentation, data protection through encryption and classification, and implementing access controls for applications.',
                        '<strong>Visibility and Analytics</strong>: Gaining a comprehensive understanding of user activity, network traffic, and potential threats is crucial for effective security measures.',
                        '<strong>Automation and Orchestration</strong>: Streamlining operations and reducing human error can be achieved through the automation of security tasks and workflows.'
                    ]
                },
                {
                    desc: 'Seven Broader Pillars:<br>In addition to the five pillars mentioned by CISA, some resources define a broader view of Zero Trust Architecture (ZTA) with two additional pillars:',
                    points: [
                        '<strong>Securing the Workforce</strong>: Educating and training employees on best practices in cybersecurity plays a vital role in establishing a strong security posture.',
                        '<strong>Safeguarding Workload Integrity</strong>: Protecting applications and workloads from vulnerabilities and attacks is a significant aspect of Zero Trust.'
                    ]
                },
            ]
        },
        {
            id: 'zero-trust-design-principle',
            title: 'Zero Trust Design Principle',
            nav: 'Zero Trust Design Principles',
            imgUrl: '',
            text: [
                {
                    desc: 'The fundamental concept of Zero Trust security centers around the principle of "never trust, always verify." This cautious approach gives rise to several crucial design principles:',
                    points: [
                        '<strong>Continuous Verification</strong>: No entity, whether it is a user, device, application, or service, is inherently trusted. Each attempt to access a resource necessitates rigorous authentication and authorization. This verification process occurs consistently, not just during the initial login.',
                        '<strong>Least Privilege Access</strong>: Users and devices are only granted the minimum level of access required to carry out their tasks. This principle reduces the potential damage in the event of a breach, as a compromised account would have limited access to steal or manipulate data.',
                        '<strong>Assume Breach</strong>: Security is designed with the assumption that a breach has already taken place or is inevitable. This mindset prioritizes limiting the impact of an attack and preventing lateral movement within the network.',
                        '⁠<strong>Context-Aware Access</strong>: Access decisions are based on various contextual factors beyond just identity. These factors may include location, device health, time of day, and the specific application being accessed.',
                        '<strong>Microsegmentation</strong>: The network is divided into smaller segments with restricted access between them. This makes it more challenging for attackers to move laterally within the network, even if they manage to gain access to one segment.'
                    ]
                },
                {
                    desc: 'By adhering to these design principles, Zero Trust aims to establish a more secure and adaptable security posture for modern IT environments.',
                    points: []
                }
            ]
        },
        {
            id: 'key-components-of-a-zero-trust-architecture',
            title: 'Key components of a Zero Trust architecture?',
            nav: 'Key components of a Zero Trust architecture?',
            imgUrl: '',
            text: [
                {
                    desc: 'The key components of a Zero Trust architecture include:',
                    points: [
                        '<strong>Identity and access management (IAM)</strong>: IAM is used to verify the identity of users and devices before granting access to resources.',
                        '<strong>Microsegmentation</strong>: Microsegmentation is used to segment the network into smaller, more secure zones.',
                        '<strong>Risk-based access control</strong>: Risk-based access control is used to grant access to resources based on the risk associated with the user or device.',
                        '<strong>Continuous monitoring</strong>: Continuous monitoring is used to detect and respond to threats in real time.'
                    ]
                },

            ]
        }]

    section7to8: ztna.ISection[] = [{
        id: 'ztna-works',
        title: 'How does ZTNA work ?',
        nav: 'How does ZTNA work ?',
        text: [
            {
                desc: 'Traditional methods of remote access, such as VPNs, can pose security risks. However, ZTNA (Zero Trust Network Access) addresses this issue by providing secure access to specific applications and resources rather than granting access to the entire network. Here is a breakdown of how ZTNA works:',
                points: []
            },
            {
                desc: 'Key principles:',
                points: [
                    '<strong>Never trust, always verify</strong>: Unlike VPNs, ZTNA does not automatically grant access based on network connectivity. Instead, every user and device undergo continuous authentication.',
                    '<strong>Least privilege access</strong>: Users are only given access to the applications or resources necessary for their job, reducing the potential impact of a security breach.'
                ]
            },
            {
                desc: 'The ZTNA workflow:',
                points: [
                    '<strong>User initiates access</strong>: A remote user attempts to access an internal application.',
                    '<strong>Authentication</strong>: The ZTNA service verifies the user\'s credentials and device. Additional factors like location may also be taken into account.',
                    '<strong>Policy check</strong>: The ZTNA service examines pre-defined access control policies to determine if the user is authorized for the requested application.',
                    '<strong>Secure tunnel creation</strong>: If authorized, a secure encrypted tunnel is established between the user\'s device and the specific application.',
                    '<strong>Access granted</strong>: The user can now access the application without being directly connected to the organization\'s network.'
                ]
            },
            {
                desc: 'By implementing ZTNA, organizations can enhance their remote access security and mitigate potential vulnerabilities.',
                points: []
            }
        ]
    },
    {
        id: 'interesting-usecase-of-ztna',
        title: 'What are all the interesting Use Cases, we can implement Zero Trust Network Access?',
        nav: 'What are all the interesting Use Cases for Zero Trust Network Access?',
        imgUrl: '',
        text: [
            {
                desc: '',
                points: [
                    'Secure Remote Access to workloads',
                    'Alternative to VPN Access',
                    'Workloads micro-segmentation',
                    'Application Dependency',
                    'Multi-cloud/ Enterprise / Cloud workloads protection'
                ]
            }
        ]
    }]

    section9to11: ztna.ISection[] = [{
        id: 'problem-facing-in-ztna',
        title: 'What are all the problems facing in VPN / Traditional Security Access ?',
        nav: 'What are all the problems facing in VPN / Traditional Security Access ?',
        text: [
            {
                desc: 'VPNs have historically served a valuable purpose, but they come with drawbacks that are more pronounced in today\'s remote work environment. Here are the challenges faced by traditional security access methods like VPNs:',
                points: []
            },
            {
                desc: 'Security Weaknesses:',
                points: [
                    '<strong>All or Nothing Access</strong>: VPNs provide access to the entire internal network once connected, creating a large attack surface if credentials are compromised.',
                    '<strong>Limited Granular Control</strong>: VPNs cannot restrict access to specific applications or resources, posing a risk for users needing access to sensitive data.',
                    '<strong>Vulnerable to Compromised Credentials</strong>: Stolen VPN credentials can grant attackers access to the entire network.',
                    '⁠<strong>Increased Risk of Lateral Movement</strong>: Attackers can easily move laterally within the network once inside via VPN.'
                ]
            },
            {
                desc: 'Scalability and Performance Issues:',
                points: [
                    '<strong>Limited Capacity</strong>: Traditional VPN appliances may struggle to support a large number of remote users due to limited capacity.',
                    '<strong>Performance Bottleneck</strong>: Routing all traffic through a central VPN server can lead to performance issues, especially for bandwidth-intensive applications.'
                ]
            },
            {
                desc: 'User Experience Challenges:',
                points: [
                    '<strong>Complexity</strong>: Setting up and using VPNs can be complex, leading to potential security risks if users resort to workarounds.',
                    '<strong>Connectivity Issues</strong>: VPN connections can be unreliable, impacting user productivity',
                    '<strong>Limited Device Compatibility</strong>: Not all devices are compatible with VPNs, which can be problematic for a mobile workforce using personal devices.',
                ]
            },
            {
                desc: 'While VPNs may be useful, they are not fully equipped to secure today\'s dynamic and cloud-based IT environments. Zero Trust Network Access (ZTNA) provides a more secure and adaptable approach for granting remote access.',
                points: []
            }
        ]
    }, {
        id: 'ztna-vs-vpn',
        title: 'Is ZTNA and VPN the same ?',
        nav: 'What is the difference between Zero Trust Network Access (ZTNA) and VPN ?',
        imgUrl: '',
        text: [
            {
                desc: '<strong>VPN</strong> : Virtual Protection Network, now becoming a Vital Portal of Node to hackers . VPN uses a point-to-point(P2P) connection or encrypted "tunnel" to protect an internal endpoint\'s IP address from being exposedpublicly while still allowing a direct connection.VPNs were primarily used to grant complete access to a LAN, offering a private, encrypted tunnel for remote employees to connect to the corporate network. But, it lacks theflexibility and coherency to control and monitor exactly what users can do and which apps they can access. Once a user is granted access, they can access anything on the network, leading to security gaps and policyenforcement issues.',
                points: []
            },
            {
                desc: '<strong>ZTNA</strong> - The zero trust Network security works in direct contrast to the VPN model. Instead of establishing asmall boundary within the network, zero trust protects the entire network\'s security and, more specifically, theinformation assets within it by individually verifying each user and device before granting access to a given application. In zero trust, authorization and authentication happen continuously throughout the network, ratherthan just once at the boundary. This model restricts unnecessary lateral movement between apps, servicesand systems, accounting for both insider threats and the possibility that an attacker might compromise alegitimate account. Limiting which parties have privileged access to sensitive data greatly reducesopportunities for hackers to steal it.',
                points: []
            }
        ]
    },
    {
        id: 'how-does-zero-trust-differ-from-traditional-security-models',
        title: 'How does Zero Trust differ from traditional security models?',
        nav: 'How does Zero Trust differ from traditional security models?',
        imgUrl: '',
        text: [
            {
                desc: 'Traditional security models focus on protecting the perimeter of the network. However, Zero Trust takes adifferent approach by assuming that no one is trusted, even if they are inside the network. This means that allusers and devices are subject to the same level of scrutiny, regardless of where they are located.',
                points: []
            }
        ]
    }]

    section12: ztna.ISection = {
        id: 'benefits-of-zero-trust',
        title: 'Benefits of Zero Trust',
        nav: 'What is the Benfits in implementing ZTNA ?',
        imgUrl: '',
        text: [
            {
                desc: 'Zero Trust has many benefits, including:',
                points: [
                    '<strong>Increased security</strong>: Zero Trust helps to protect organizations from a wide range of threats, including malware,ransomware, and data breaches.',
                    '<strong>Reduced complexity</strong>: Zero Trust simplifies security by eliminating the need for complex perimeter security measures.',
                    '<strong>Improved agility</strong>: Zero Trust makes it easier for organizations to adapt to change, such as the increasing use of cloud computing and remote work.',
                    '<strong>Reduced costs</strong>: Zero Trust can help organizations reduce their security costs by eliminating the need for expensive perimeter security measures.'
                ]
            }
        ]
    }

    section13to14: ztna.ISection[] = [{
        id: 'challenges-in-ztna-deployments',
        title: 'Challenges in ZTNA deployments?',
        nav: 'What are the challenges in implementing / deploying ZTNA ?',

        imgUrl: '',
        text: [
            {
                desc: 'ZTNA is easy to deploy considering it has a completely automated workflow and doesn’t depend on hardware appliances like VPN gateways. The challenges come in when the enterprises encounter various product architectures from different vendors. The means, there is some confusion in what are baseline requirements and what are augmented product features.',
                points: [
                    '<strong>Cost</strong>: Zero Trust can be expensive to implement, especially for large organizations.',
                    '<strong>Complexity</strong>: Zero Trust can be complex to implement and manage, especially for organizations with complex IT environments.',
                    '<strong>Culture</strong>: Zero Trust requires a change in security culture, as it requires organizations to move away from a perimeter-based security model.',
                ]
            }
        ]
    },
    {
        id: 'ztna-product-considerations',
        title: 'Key ZTNA Product Considerations Parameters',
        nav: 'Key ZTNA Product Considerations Parameters',
        imgUrl: '',
        table: [
            {
                col1: 'AGENT TYPE',
                col2: 'Agent based  Or Agent -Less'
            },
            {
                col1: 'DEPLOYMENT MODEL',
                col2: 'Gateway mode or Enclave Model'
            },
            {
                col1: 'DATA TRAFFIC PATTERN',
                colList: ['Proxy Centric - Proxy can be dedicated or shared like SSE Pop', 'Relay dependent or independent', 'Direct - Peer to Peer']
            },
            {
                col1: 'PROTOCOL SUPPORT',
                colList: ['Universal Protocol', 'Limited to HTTP(S), RDP, SSH etc']
            },
            {
                col1: 'ZTNA PROTECTION SCOPE',
                colList: ['North-South only  (User to App)', 'Includes East-West (User to App &  App to App)']
            },
            {
                col1: 'PROTOCOL TECHNOLOGY',
                colList: ['TLS based', 'UDP/TCP tunnels with pinholing']
            }
        ],
        text: []
    }]


    private destroy$ = new Subject<void>()
    constructor(
        private activatedRoute: ActivatedRoute,
        private fetchMetaService: FetchMetaService,
        private titleService: Title,
        private apollo: Apollo,
        private metaService: Meta,
        private canonicalService: CanonicalService,
        @Inject(PLATFORM_ID) private platformId: object
    ) { }
    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        if (this.fetchMetaService.getData() == false) {
            let metatags = [];
            this.apollo
                .watchQuery({
                    query: META_QUERY,
                })
                .valueChanges.pipe(takeUntil(this.destroy$)).subscribe((result) => {
                    let meta = [];
                    meta = (result.data as any).metaTags.data.map((metaTag: any) => ({
                        ...metaTag,
                    }));
                    for (var i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    // console.log("Meta Tags ", metaTagsData);
                    this.titleService.setTitle(metaTagsData['microzaccess'].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content: metaTagsData['microzaccess'].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData['microzaccess'].attributes.keywords,
                    });
                });
        } else {
            // console.log("Data Alreday Fetched");
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data['microzaccess'].attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content: data['microzaccess'].attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data['microzaccess'].attributes.keywords,
            });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
