import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IContactsForm } from '../../../core/models/contacts.model';

@Injectable({
    providedIn: 'root',
})
export class ContactsService {
    constructor(private http: HttpClient) {}
    postContactForm(contactForm: IContactsForm) {
        // const url = `${environment.apiUrl}/contacts/?token=${environment.strapiApiKey}`;
        const url = `${environment.apiUrl}/api/contacts`;
        return this.http.post(url, contactForm);
    }
}
