
import { RouteConstants } from './../../config/route.config';

export const products = {
    products: [
        {
            title: 'Networking',
            links: [
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'Secure Software Defined WAN - (SD-WAN)'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`,
                    name: 'NFRXG Firewall- COSGrid Next Generation Firewall'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                    name: 'IoT Gateway - Secure IoT Connectivity with 5G'
                }
            ]
    },
        {
            title: 'Threat Protection',
            links: [
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                    name:  'Zero Trust Network Access (ZTNA) and Web Filtering'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Cloud Firewall - Firewall As a Service (FWaas)',
                    fragment: 'FWaas'
                },
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'SIG - Secure Web Gateway (SWG)',
                    fragment: 'sig'
                }
            ]
        },
        {
            title: 'SASE - Secure Access Service Edge',
            links: [
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    name: 'Z3 SASE - Secure, Fast Internet & Saas Access'
                }
            ]
        },
        {
            title: 'Application & Data Protection',
            links: [
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                    name: 'Zero Trust Network Access (ZTNA 2.0)'
                }
            ]
        },
        {
            title: 'Detection & Response',
            links: [
                {
                    link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                    name: 'NetShield NDR - AI/ML Big data NDR'
                }
            ]
        }
    ],
    components: [
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.C_EDGE}`,
            name: 'ReFlex Edge - Gateway hardware Device '
        },
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.GUIDER}`,
            name: 'COSGrid Guider - Management, Monitoring and orchestration '
        },
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.CLOUD_NF_HUB}`,
            name: 'Cloud NF Hub - Optimized Access to cloud & Saas Apps '
        },
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURITY_ANALYSER_RESPONDER}`,
            name: 'SAR- SDS - Security Analyzer and Responder- AI/ML Big data '
        },
        {
            link: `${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.MZA_APPCLIENT}`,
            name: 'MZA/SASE Client - Desktop App Client extends to endpoint client devices '
        },
        // {
        //     link: ``,
        //     name: 'MZA Mediator - Optimized Access to cloud & Saas Apps '
        // }
    ],
    // platform: [
    //     {
    //         link: ``,
    //         name: 'Architecture '
    //     },
    //     {
    //         link: ``,
    //         name: 'Deployment '
    //     }
    // ],
}


export const solutions = [
    {
        title: 'SASE',
        links: [
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                name: 'Secure Digital Transformation'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                name: 'Workforce Transformation'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                name: 'Improved Security Posture'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                name: 'User Experience Enhancement'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                name: 'Multi-vendor Data integration'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                name: 'Comprehensive Visibility & control'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                name: 'Zero Trust SASE'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                name: 'Attack Surface Reduction'
            },
        ]
    },
    {
        title: 'SD-WAN & Firewall',
        links: [
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                name: 'Centralized Management'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                name: 'Mpls Migration To SD-Wan'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                name: 'Secure Branch Internet Access'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`,
                name: 'Intrusion Detection & Prevention'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                name: 'Saas Acceleration'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                name: 'Improved Bandwidth Utilization'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                name: 'Digital Experience'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`,
                name: 'On Prem & Hybrid Security'
            },
        ]
    },
    {
        title: 'Zero Trust Access',
        links: [
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                name: 'VPN Alternative /Cloud VPN'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                name: 'Secure Users & Data'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                name: 'Secure Remote Access'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                name: 'Cloud Workload Protection'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                name: 'Third Party Access Control'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                name: 'Compliance Management'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                name: 'Zero Trust Access- IoT/OT'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                name: 'Micro Segmentation'
            },
        ]
    },
    {
        title: 'Advanced Threat Protection',
        links: [
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                name: 'Threat Detection and Mitigation'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                name: 'Network Traffic Analysis'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                name: 'Endpoint Threat Analytics'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                name: 'Insider Threat Detection'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                name: 'Data Exfiltration Prevention'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                name: 'Network Visibility & Threat Hunting'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                name: 'Forensics Investigation'
            },
            {
                link: `${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
                name: 'Ransomware Protection'
            },
        ]
    },
]

export const industries = [
    {
        links: [
            {
                link: `${RouteConstants.INDUSTRIES}/${RouteConstants.FINANCIAL_SERVICES}`,
                name: 'Banking and Financial Services '
            },
            {
                link: `${RouteConstants.INDUSTRIES}/${RouteConstants.HEALTHCARE}`,
                name: 'Healthcare'
            },
            {
                link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RETAIL}`,
                name: 'Retail'
            },
            // {
            //     link: `${RouteConstants.INDUSTRIES}/${RouteConstants.RESTAURANTS_HOSPITALITY}/.`,
            //     name: 'Hospitality'
            // },
        ]
    },
    {
        links: [
            {
                link: `${RouteConstants.INDUSTRIES}/${RouteConstants.ITITES}`,
                name: 'IT/ ITES '
            },
            {
                link: `${RouteConstants.INDUSTRIES}/${RouteConstants.MANUFACTURING}`,
                name: 'Manufacturing'
            },
            // {
            //     link: ``,
            //     name: 'Education'
            // },
            {
                link: `${RouteConstants.INDUSTRIES}/${RouteConstants.LOGISTICS}`,
                name: 'Logistics'
            },
        ]
    }
]

export const partners = [
    {
        links: [
            {
                link: `${RouteConstants.PARTNERS}`,
                name: 'Partner Program'
            },
            {
                link: `${RouteConstants.PARTNERS}/${RouteConstants.BECOME_PARTNER}`,
                name: 'Become a Partner',
                fragment: 'becomePartner'
            },

        ],
    },
    {
        links: [
            // {
            //     link: ``,
            //     name: 'Partner Portal'
            // },
            {
                link: `${RouteConstants.PARTNERS}/${RouteConstants.BECOME_PARTNER}`,
                name: 'Industry Partners',
                fragment: 'industry_partners'
            },
            {
                link: `${RouteConstants.PARTNERS}/${RouteConstants.BECOME_PARTNER}`,
                name: 'Find a Partner',
                fragment: 'becomePartner'
            },
        ]
    }
]

export const resources = [
    {
        title: 'Resources',
        class: 'col-5',
        links: [
            {
                link: `https://docs.cosgrid.com/home`,
                name: 'MicroZAccess ZTNA Documentation',
                external: true
            },
            {
                link: `${RouteConstants.RESOURCES}/${RouteConstants.SD_WAN}`,
                name: 'SD-WAN Concepts',
                external: false
            },
            {
                link: `${RouteConstants.RESOURCES}/${RouteConstants.USECASE}`,
                name: 'SD-WAN Usecase',
                external: false
            },
            {
                link: `${RouteConstants.RESOURCES}/${RouteConstants.ZTNA}`,
                name: 'Zero Trust Network Access Concepts'
            },
            // {
            //     link: ``,
            //     name: 'What is SASE ?'
            // },
            {
                link: `${RouteConstants.RESOURCES}/${RouteConstants.BLOGS}`,
                name: 'Blogs',
                external: false
            },
            {
                link: `${RouteConstants.RESOURCES}/case-studies`,
                name: 'Case Studies & Reports',
                external: false
            },
            {
                link: `${RouteConstants.RESOURCES}/${RouteConstants.WHITE_PAPERS}`,
                name: 'White Paper & Datasheets',
                external: false
            },
            // {
            //     link: ``,
            //     name: 'ROI Calculator'
            // },
            {
                link: `${RouteConstants.RESOURCES}/${RouteConstants.FAQS}`,
                name: 'Frequently Asked Questions',
                external: false
            },
        ],
    },
    {
        title: 'Integrations',
        class: 'col-3',
        links: [
            {
                link: `https://docs.cosgrid.com/microzaccess/authentication-iam/azure-ad-integration`,
                name: 'Azure AD',
                external: true
            },
            {
                link: `https://docs.cosgrid.com/microzaccess/authentication-iam/okta-integration`,
                name: 'Okta',
                external: true
            },
            {
                link: `https://docs.cosgrid.com/microzaccess/authentication-iam/google-suite-integration`,
                name: 'Google Suite',
                external: true
            },
            {
                link: `https://docs.cosgrid.com/microzaccess/authentication-iam/one-login-integration`,
                name: 'One Login',
                external: true
            }
        ],
    },
    {
        title: 'Media',
        class: 'col-4',
        links: [
            {
                link: `${RouteConstants.RESOURCES}/${RouteConstants.MOMENTS}`,
                name: 'Gallery'
            },
            {
                link: `${RouteConstants.RESOURCES}/news-${RouteConstants.PRESS_RELEASE}`,
                name: 'News & Press Release'
            },
        ]
    }
]

export const company = [
    {
        links: [
            {
                link: `${RouteConstants.COMPANY}/${RouteConstants.ABOUTUS}`,
                name: 'About us'
            },
            {
                link: `${RouteConstants.COMPANY}/${RouteConstants.AWARDS}`,
                name: 'Awards'
            },
            {
                link: `${RouteConstants.COMPANY}/${RouteConstants.EVENTS}`,
                name: 'Events'
            },
            // {
            //     link: ``,
            //     name: 'Our Customers'
            // },
        ],
    },
    {
        links: [
            {
                link: `${RouteConstants.COMPANY}/${RouteConstants.CONTACTS}`,
                name: 'Contact us'
            },
            {
                link: `${RouteConstants.COMPANY}/${RouteConstants.CAREERS}`,
                name: 'Careers'
            },
            // {
            //     link: ``,
            //     name: 'Current Openings'
            // },
        ]
    }
]


