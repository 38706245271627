<div
    *ngIf="loading == true"
    style="display: flex; height: 100vh; width: 100vw; margin: 0 0; padding: 0 0; background-color: #eeeeee"
>
    <div style="margin: auto">
        <img
            style="display: block"
            class="logo-loading"
            src="https://vcdn.cosgrid.com/website/assets4/navbar/fLogo.svg"
            alt="Logo loading.."
            loading="lazy"
        />
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
<section class="px-md-5 px-2 pt-3">
        <div class="row">
            <div class="col-md-7 text-center text-md-left mb-3 pb-5">
                <h1 class="fw-bold h2">
                    <span class="clr-blue-dark fw-bold">{{ opening_positions_details?.Title }}</span> |
                    {{ opening_positions_details?.career?.data.attributes.type }}
                </h1>
                <p class="fs-6">
                    {{ opening_positions_details?.Location }}
                    {{ opening_positions_details?.Employment_type }}
                </p>
                <div class="mt-5">
                    <p class="clr-blue-dark fw-bold h4 text-left">About the company</p>
                    <p class="text-left fs-6">
                        COSGrid Networks is a leading networking and cybersecurity products company that delivers secure
                        access and protection to users, data, applications, and workloads across offices, clouds, remote
                        sites, and WFH users anywhere. COSGrid is one of the few unified SASE companies globally that
                        enables ‘Cyber-resilience Simplified’ for organizations with advanced threat detection, zero
                        trust architecture capabilities and patented adaptive SD-WAN-based traffic steering to deliver
                        seamless connectivity across WAN links, including 4G/5G
                    </p>
                </div>
                <div class="mt-5" *ngIf="opening_positions_details?.Details?.responsibilities">
                    <p class="clr-blue-dark fw-bold h4 text-left">Responsibilities</p>
                    <div *ngFor="let i of opening_positions_details.Details.responsibilities" class="d-flex ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" class="mt-1"
                        viewBox="0 0 256 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                        <path fill="#006d8f"
                            d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                    </svg>
                        <p class="ms-2 fs-6 text-left">{{i}}</p>
                    </div>
                </div>

                <div class="mt-5" *ngIf="opening_positions_details?.Details?.requiredSkills">
                    <p class="clr-blue-dark fw-bold h4 text-left">Required Skills</p>
                    <div *ngFor="let i of opening_positions_details?.Details?.requiredSkills" class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" class="mt-1"
                        viewBox="0 0 256 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                        <path fill="#006d8f"
                            d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                    </svg>
                        <p class="ms-2 fs-6 text-left">{{i}}</p>
                    </div>
                </div>
                <div class="mt-5" *ngIf="opening_positions_details?.Details?.requirements">
                    <p class="clr-blue-dark fw-bold h4 text-left">Requirements</p>
                    <div *ngFor="let i of opening_positions_details?.Details?.requirements" class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" class="mt-1"
                        viewBox="0 0 256 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                        <path fill="#006d8f"
                            d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                    </svg>
                        <p class="ms-2 fs-6 text-left">{{i}}</p>
                    </div>
                </div>

                <div class="mt-5" *ngIf="opening_positions_details?.Details?.whatDoYouGet">
                    <p class="clr-blue-dark fw-bold h4 text-left">What Do You Get?</p>
                    <div *ngFor="let i of opening_positions_details?.Details?.whatDoYouGet" class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" class="mt-1"
                        viewBox="0 0 256 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                        <path fill="#006d8f"
                            d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                    </svg>
                        <p class="ms-2 fs-6 text-left">{{i}}</p>
                    </div>
                </div>

                <div class="mt-5" *ngIf="opening_positions_details?.Details?.note">
                    <p class="clr-blue-dark fw-bold h4 text-left">Note</p>
                    <div *ngFor="let i of opening_positions_details?.Details?.note" class="d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" class="mt-1"
                        viewBox="0 0 256 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                        <path fill="#006d8f"
                            d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" />
                    </svg>
                        <p class="ms-2 text-left">{{i}}</p>
                    </div>
                </div>
                <div class="mt-5 mb-5" *ngIf="opening_positions_details?.Details?.technologies">
                    <p class="clr-blue-dark fw-bold h4 text-left">Technologies</p>
                    <div class="text-break mt-4 d-flex flex-wrap">
                        <p
                            *ngFor="let i of opening_positions_details?.Details?.technologies"
                            class="px-3 fs-6"
                        >
                            {{ i }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md ml-0 ml-md-5">
                <div class="mt-0">
                    <p class="clr-blue-dark fw-bold h4 ">Submit your Application</p>
                    <form
                        [formGroup]="applicationForm"
                        class="mb-md-5 mt-5"
                        (ngSubmit)="onSubmit()"
                        enctype="multipart/form-data"
                    >
                        <!-- FullName -->
                        <div class="form-group mt-4 mt-md-2">
                            <label for="form-fullname">Full Name<sup class="text-danger">*</sup></label>
                            <input
                                type="text"
                                class="form-control rounded-0"
                                id="form-fullname"
                                formControlName="fullname"
                                [ngClass]="{
                                    'is-invalid':
                                        applicationForm.get('fullname')?.errors &&
                                        (applicationForm.get('fullname')?.touched || onSubmitted)
                                }"
                                required
                            />
                            <div
                                *ngIf="
                                    applicationForm.get('fullname')?.errors &&
                                    (applicationForm.get('fullname')?.touched || onSubmitted)
                                "
                                id="form-name-invalid"
                                class="invalid-feedback"
                            >
                                <div *ngIf="applicationForm.get('fullname')?.errors?.['required']">
                                    Please provide your <strong>Name</strong>
                                </div>
                                <div *ngIf="applicationForm.get('fullname')?.errors?.['minlength']">
                                    Minimum required number of characters is 2.
                                </div>
                                <div *ngIf="applicationForm.get('fullname')?.errors?.['maxlength']">
                                    Minimum required number of characters is 15.
                                </div>
                            </div>
                        </div>

                        <!-- Email -->
                        <div class="form-group mt-4 mt-md-2">
                            <label for="form-email">Email<sup class="text-danger">*</sup></label>
                            <input
                                type="text"
                                class="form-control rounded-0"
                                id="form-email"
                                formControlName="email"
                                [ngClass]="{
                                    'is-invalid':
                                        applicationForm.get('email')?.errors &&
                                        (applicationForm.get('email')?.touched || onSubmitted)
                                }"
                                required
                            />
                            <div
                                *ngIf="
                                    applicationForm.get('email')?.errors &&
                                    (applicationForm.get('email')?.touched || onSubmitted)
                                "
                                class="invalid-feedback"
                                id="form-email-error"
                                class="invalid-feedback"
                            >
                                <div *ngIf="applicationForm.get('email')?.errors?.['required']">
                                    Please provide your <strong>Email ID</strong>
                                </div>
                                <div *ngIf="applicationForm.get('email')?.errors?.['email']">
                                    Please provide a Valid Email ID
                                </div>
                            </div>
                        </div>

                        <!-- Phone -->
                        <div class="form-group mt-4 mt-md-2">
                            <label for="form-phone">Phone<sup class="text-danger">*</sup></label>
                            <input
                                type="tel"
                                class="form-control rounded-0"
                                id="form-phone"
                                formControlName="phone"
                                [ngClass]="{
                                    'is-invalid':
                                        applicationForm.get('phone')?.errors &&
                                        (applicationForm.get('phone')?.touched || onSubmitted)
                                }"
                                required
                            />
                            <div
                                id="form-phone-error"
                                *ngIf="
                                    applicationForm.get('phone')?.errors &&
                                    (applicationForm.get('phone')?.touched || onSubmitted)
                                "
                                class="invalid-feedback"
                                class="invalid-feedback"
                            >
                                <div *ngIf="applicationForm.get('phone')?.errors?.['required']">
                                    Please provide your <strong>Phone Number</strong>
                                </div>
                                <div *ngIf="applicationForm.get('phone')?.errors?.['pattern']">
                                    Please provide a Valid Phone Number
                                </div>
                            </div>
                        </div>

                        <div class="form-group mt-4 mt-md-2">
                            <label for="resume">Resume<sup class="text-danger">*</sup></label>
                            <!-- <label for="resume" class="form-control btn btn-blue btn-blog btn-outlined rounded-0">Upload</label> -->
                            <input
                                type="file"
                                accept="application/pdf"
                                class="form-control btn btn-light rounded-0"
                                id="resume"
                                size="1"
                                (change)="onFileSelector($event)"
                                [ngClass]="{
                                    'is-invalid': this.file == null && onSubmitted
                                }"
                            />
                            <div
                                id="form-resume-invalid"
                                class="invalid-feedback"
                                *ngIf="this.file == null && onSubmitted"
                            >
                                Please upload your <strong>Resume</strong>
                            </div>
                        </div>

                        <!-- Submit -->
                        <div class="form-group mt-4 mt-md-2">
                            <label for="resume">&nbsp;</label>
                            <button
                                class="form-control btn btn-primary-blue rounded-0"
                                [disabled]="!can_be_uploaded"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
</section>
