<footer class="footer mt-5">
    <div class="container-fluid">
        <div class="row justify-content-around">
            <div class="col-sm-6 col-md-5">
                <div class="w-60 mx-auto h-100 d-flex flex-column justify-content-between" id="footer-logo-container">
                    <div class="d-flex flex-row flex-wrap justify-content-between">
                        <div *ngFor="let icon of socialMediaItems">
                            <img
                                (click)="openLink(icon.link)"
                                role="button"
                                [src]="
                                    'https://vcdn.cosgrid.com/website/assets/social media icons/' + icon.image + '.png'
                                "
                                height="36px"
                                loading="lazy"
                                [alt]="icon.image"
                            />
                        </div>
                    </div>
                    <div class="w-100 text-center">
                        <img
                            src="https://vcdn.cosgrid.com/website/assets/logo.png"
                            class="img-fluid w-100"
                            loading="lazy"
                            alt="COSGrid Networks"
                        />
                        <div style="height: 10px"></div>
                        <h6 class="fs-6 fw-bold text-center tracking-wide">
                            Cyber resiliency simplified with<br />Smart SASE NetSecOps
                        </h6>
                    </div>
                    <h3 class="fs-6 w-100 text-center">Copyright ©2024 - All Rights Reserved</h3>
                </div>
            </div>
            <div class="col-sm-6 col-md-7 gap-3">
                <div class="row">
                   
                    <div class="col-5">
                        <ul class="list-group">
                            <h6 class="m-0 fw-bold">Products</h6>
                            <li *ngFor="let item of productLinks" class="m-0">
                                <a class="footer-link m-0" [routerLink]="item.link">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-4">
                        <ul class="list-group">
                            <h6 class="m-0 fw-bold">Components</h6>
                            <li *ngFor="let item of componentsLinks" class="m-0">
                                <a class="footer-link m-0" [routerLink]="item.link" (click)="componentClick(item.link)">{{
                                    item.name
                                }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-3">
                        <ul class="list-group">
                            <h6 class="m-0 fw-bold">Company</h6>
                            <li *ngFor="let item of companyItem" class="m-0">
                                <a class="footer-link m-0" [routerLink]="item.link">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    
                    <div class="col-5">
                        <ul class="list-group mt-5">
                            <h6 class="m-0 fw-bold">Contact Us</h6>
                            <li *ngFor="let item of contactsItem" class="m-0">
                                <a class="footer-link m-0" [href]="item.link">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-4">
                        <ul class="list-group mt-5">
                            <h6 class="m-0 fw-bold">Locations</h6>
                            <li *ngFor="let item of locationItem" class="m-0">
                                <a class="footer-link m-0" [href]="item.link" target="_blank" [innerHTML]="item.name"></a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-3">

                        <ul class="list-group" style="margin-top: 64px !important;">
                            <li *ngFor="let item of partnersItem" class="m-0">
                                 <a class="footer-link m-0" [routerLink]="item.link">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

