import gql from 'graphql-tag';

const EVENTS_QUERY = gql`
    # STRAPI V3 - QUERY
    # query {
    #     newsInfos(sort: "eventDate:DESC") {
    #         title
    #         misc
    #         description
    #         title_image {
    #             url
    #         }
    #     }
    # }

    # V4 QUERY 
    query {
        newsInfos(sort: "Event_date:DESC", pagination: { limit: 100 }) {
            data {
            attributes {
                Title
                misc
                Description
                Title_image {
                data {
                    attributes {
                    url
                    }
                }
                }
            }
            }
    }
}
    
`;

export default EVENTS_QUERY;
