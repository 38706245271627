import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { CarouselListComponent } from './carousel-list/carousel-list';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { FeaturesListComponent } from './features-list/features-list.component';
import { ImageList } from './image-list/image-list';
import { ImagePoints } from './image-points/image-points.component';
import { ProblemListComponent } from './problem-list/problem-list.component';
import { RouterModule } from '@angular/router';
import { ResourceListComponent } from './resource-list/resource-list.component';
import { SliderListComponent } from './slider-list/slider-list';
import { TableList } from './table-list/table-list';
import { FormsModule } from '@angular/forms';
import { AssociationComponent } from './association/association.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { CarouselComponent } from './carousel/carousel.component';
import { DesktopFooterComponent } from './footer/desktop-footer/desktop-footer.component';
import { DesktopNavbarComponent } from './navbar/desktop-navbar/desktop-navbar.component';
import { MobileFooterComponent } from './footer/mobile-footer/mobile-footer.component';
import { MobileNavbarComponent } from './navbar/mobile-navbar/mobile-navbar.component';
import {
  NguCarousel,
  NguCarouselDefDirective,
  NguCarouselNextDirective,
  NguCarouselPrevDirective,
  NguItemComponent,
  NguTileComponent
} from '@ngu/carousel';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [

    DesktopFooterComponent,
    DesktopNavbarComponent,
    MobileFooterComponent,
    MobileNavbarComponent,
    CarouselListComponent,
    CookieConsentComponent,
    FeaturesListComponent,
    ImageList,
    ImagePoints,
    ProblemListComponent,
    ResourceListComponent,
    SliderListComponent,
    TableList,
    AssociationComponent,
    TestimonialsComponent,
    CarouselComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NguCarousel,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPrevDirective,
    NguItemComponent,
    NguTileComponent,
    MatTabsModule, 
    // BrowserAnimationsModule
  ],
  exports: [
    DesktopFooterComponent,
    DesktopNavbarComponent,
    MobileFooterComponent,
    MobileNavbarComponent,
    CarouselListComponent,
    CookieConsentComponent,
    FeaturesListComponent,
    ImageList,
    ImagePoints,
    ProblemListComponent,
    ResourceListComponent,
    SliderListComponent,
    TableList,
    AssociationComponent,
    CommonModule,
    RouterModule,
    FormsModule,
    TestimonialsComponent,
    CarouselComponent
  ]
})
export class SharedModule { }
