import { Component, Input } from '@angular/core';

@Component({
    selector: 'slider-list',
    templateUrl: 'slider-list.html',
    styleUrls: ['./slider-list.scss'],
})
export class SliderListComponent  {

    constructor() {}
    @Input() title!: string;
    @Input() data: any;
}
