import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  private url: string = 'https://ncms.cosgrid.com/api/support-tickets'
  constructor(
    private http: HttpClient
  ) { }

  sendTicket(data: any):Observable<any> {
    return this.http.post(this.url, data)
  }
}
