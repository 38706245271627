<div style="padding-top: 8vh;"></div>

<!-- <section class="bg-dark text-white w-100">
  <div class="container pt-5 pb-5 text-center">
    <h3>A partner Ecosystem that provides innovative solutions for leading companies across the globe</h3>
  </div> 
</section> -->

<!-- APPROACH -->
<!-- <section id="approach">
    <div class="container pt-5">
        <div class="section-title text-center mb-5">
            <h3 class="text-stronger text-bold">Our Approach</h3>
        </div>
        <div class="row display-flex pb-5">
            <div *ngFor="let item of approach" class="col-md-6 mb-5">
                <div class="card mb-3">
                    <div class="row g-0">
                        <div class="col-lg-4 text-center">
                            <img class="img-fluid mx-auto d-block" [src]="item.imgURL" [alt]="item.title" />
                        </div>
                        <div class="col-lg-8 text-center text-md-left">
                            <div class="card-body">
                                <h5 class="card-title fs-5 lh-sm text-bolder">
                                    {{ item.title }}
                                </h5>
                                <p class="card-text  text-thin">{{ item.text }}</p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- <section id="approach">
    <div class="container pt-3">
        <div class="section-title text-center mb-5">
            <h3 class="text-stronger text-bold" style="color: #003F88;">Our Approach</h3>
        </div>
        <div class="cardContainer">
        
            <div *ngFor="let item of approach" class="col-3">
                <div class="card-a" style="border-right: 2px solid #003F88 ;" >
                    <div class="imageContainer text-center">
                    <img class="img-fluid w-50 d-block" [src]="item.imgURL" [alt]="item.title" />
                </div> 
                     <div class="cardText">
                    <h5 class="card-title fs-5 lh-sm text-bolder">
                        {{ item.title }}
                    </h5>
                    <p class="card-text  text-thin">{{ item.text }}</p>
                </div>
            
            </div>
        </div>
    </div>
    </div>
</section> -->
<section id="approach" [ngStyle]="{'margin-top' : marginTop }">
    <div class="container">
        <div class="section-title text-center mb-5">
            <h1 class="fw-bold clr-blue-dark h2">Our Approach</h1>
        </div>
        <div class="cardContainer">
            <div *ngFor="let item of approach" class="card-a" [style.border-right]="item.border">
                <div class="imageContainer">
                    <img class="img-fluid w-70 d-block" [src]="item.imgURL" [alt]="item?.title" loading="lazy" />
                </div>
                <div class="cardTitle mt-2">
                    <p class="card-title h6 lh-sm fw-bold">
                        {{ item.title }}
                    </p>
                </div>
                <div class="cardText">
                    <p class="fs-6">{{ item.text }}</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="bg-light b-4">
    <div class="container-fluid pt-5 pb-5 mtext-center">
        <div class="row departments d-flex justify-content-center">
            <div *ngFor="let item of usecasesList" class="col-lg-3 col-md-4 col-sm-6 text-center">
                <a [href]="item.link"><img [src]="item.imgURL" alt="" class="img img-fluid" style="width: 80px" /></a>
                <h6 class="text-bold text-blue text-uppercase">{{ item.title }}</h6>
            </div>
        </div>
    </div>
</section> -->
<br />
<br />

<!-- challenge -->
<!-- <section [id]="item.id"  *ngFor="let item of usecasesList" [id]="item.id" id="platforms" class="bg-white">
    <div class="container">
        <div class="row mt-4 text-align-center">
            <div class="col-md-3">
                <img [src]="item.imgURL" alt="" srcset="" class="w-50 img-fluid mx-auto d-block" />
            </div>
            <div class="col-md">
                <h4 class="text-color text-blue text-bold text-uppercase txt">{{ item.title }}</h4>
                <div class="txt">
                    <p *ngFor="let i of item.desc" class="text lh-lg  mb-3" style="font-weight: 400;">
                        {{ i }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<div class="section-title text-center mb-3">
    <h3 class="fw-bold h2 clr-blue-dark">Usecases</h3>
</div>
<div class="container">
    <div class="cardContaineru">
        <section *ngFor="let item of usecasesList" [id]="item.id" class="row">
            <div class="col-sm-3">
                <!-- <div class="imageContaineru"> -->
                <img [src]="item.imgURL" [alt]="item?.title" srcset="" class="w-40 img-fluid d-block mx-auto" loading="lazy" />
                <!-- </div> -->
            </div>
            <div class="col-sm-9">
                <!-- <div class="cardTitleu"> -->
                <p class="card-title h4 fw-bold lh-sm text-bolder">
                    {{ item.title }}
                </p>
                <!-- </div> -->
                <!-- <div class="cardTextu"> -->
                <p *ngFor="let i of item.desc" class=" mb-3 fs-6 text-justify">
                    {{ i }}
                </p>
                <!-- </div> -->
            </div>
        </section>
    </div>
</div>
<br /><br />
