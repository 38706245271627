import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IEmailDownloadsForm } from '../models/emaildownload';

@Injectable({
    providedIn: 'root',
})
export class EmailserviceService {
    constructor(private http: HttpClient) {}

    postContactForm(contactForm: IEmailDownloadsForm) {
       // console.log(contactForm);
        // const url = `${environment.apiUrl}/email-downloads/?token=${environment.strapiApiKey}`;
        const url = `${environment.apiUrl}/api/email-downloads`;
        //console.log(url);
        //console.log(this.http.post(url, contactForm));

        return this.http.post(url, contactForm);
    }
}
