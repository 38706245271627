<!-- <div style="height: 13vh"></div> -->

<section id="case-studies" *ngIf="!loading">
    <h1 class="fw-bold clr-blue-dark text-center h2 pt-3">Case Studies & Reports</h1>
    <app-resource-list [data]="reports" title="case-studies"></app-resource-list>
</section>
<div
    *ngIf="loading == true"
    style="display: flex; height: 100vh; width: 100vw; margin: 0 0; padding: 0 0; background-color: #eeeeee"
>
    <div style="margin: auto">
        <img
            style="display: block"
            class="logo-loading"
            src="https://vcdn.cosgrid.com/website/assets4/navbar/fLogo.svg"
            alt="Logo loading.."
            loading="lazy"
        />
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
    >
</div>
