import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';


@Component({
    selector: 'app-testimonials',
    templateUrl: './testimonials.component.html',
    styleUrls: ['./testimonials.component.scss'],
    animations: [
        trigger('translateTab', [
          state('start', style({
            transform: 'translateX(0)'
          })),
          state('end', style({
            transform: 'translateX(100%)'
          })),
          transition('start <=> end', animate('300ms ease-in-out'))
        ])
      ]
})
export class TestimonialsComponent implements OnInit {
    showNavigationArrows = false;
    showNavigationIndicators = false;

    testimonials = [
        {
            id: 1,
            name: 'Orange Retail Finance Pvt. Ltd.',
            personImgURL: 'https://vcdn.cosgrid.com/website/assets/images/testimonials/company-2.png',
            companyImg: 'https://vcdn.cosgrid.com/website/assets/images/testimonials/company-2.png',
            text: "With COSGrid as our SD-WAN partner, we've been able to roll out new branches <strong> 80% faster, get 3x more bandwidth, and 50% lower Opex </strong> compared to MPLS WAN.  A complete end-to-end solution from them with a solid 4G support, Network security, and simplified management",

            companyname: '',
            // 'CIO/VP, <br><span class="fw-bolder">Orange Retail Finance Pvt. Ltd.</span>',
        },
        {
            id: 2,
            name: 'Sankara Nethralaya',
            personImgURL: 'https://vcdn.cosgrid.com/website/assets/images/testimonials/companyLogo-1.jpeg',
            companyImg: 'https://vcdn.cosgrid.com/website/assets/images/testimonials/companyLogo-1.jpeg',
            text: 'We were heavily reliant on MPLS links to run our wide area network for servicing patients in India. COSGrid solutions on SD-WAN Solutions were both transformative and cost-effective.',
            companyname: '',
            // 'Chief Information & Technology Officer,<br><span class="fw-bolder">Sankara Nethralaya</span>',
        },
        {
            id: 3,
            name: 'Zimson Times Pvt. Ltd.',
            personImgURL: 'https://vcdn.cosgrid.com/website/assets/images1/zimson.png',
            companyImg: 'https://vcdn.cosgrid.com/website/assets/images1/zimson.png',
            text: "In the retail world, securing our data and systems is paramount, especially as cyber threats continue to evolve. Like many store owners, we've dabbled with VPNs to secure our connections. Enter MicroZAccess, a new Smart Zero Trust Network Access (ZTNA) client for desktops. Modern Security for Modern Retail: The fact that it’s <strong>designed for things like SD-WAN and SASE</strong> gives us hope that it's future-proof, at least for the foreseeable future. For retail store owners looking to step up their cyber security game, <strong>MicroZAccess is worth checking out</strong>. While it might have a bit of a learning curve for some, the peace of mind it offers is priceless. Would recommend!",
            companyname: '',
            // 'Chief Information & Technology Officer,<br><span class="fw-bolder">Sankara Nethralaya</span>',
        },
    ];
    carouselSlide: any;
    carouselImages: any;
    seconds!: number;

    duration = 6;

    current_id!: number;
    size: any;

    nextSlide() {
        this.current_id = this.current_id != this.testimonials.length ? this.current_id + 1 : 1;
    }

    prevSlide() {
        this.current_id = this.current_id != 1 ? this.current_id - 1 : this.testimonials.length;
    }

    carouselChange(e: number) {
        this.current_id = e;
        this.seconds = this.duration;
    }

    carouselCheckActive(a: number) {
        return a == this.current_id;
    }

    constructor(@Inject(PLATFORM_ID) protected platformId: Object) {}

    ngOnInit(): void {
        this.current_id = 1;
        this.seconds = this.duration;
        if (isPlatformBrowser(this.platformId)) {
            setInterval(() => {
                this.seconds = this.seconds > 0 ? this.seconds - 1 : this.duration;
                if (this.seconds == this.duration) {
                    this.nextSlide();
                }
            }, 1000);
        }
    }
}
