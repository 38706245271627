import gql from 'graphql-tag';

const POSITION_IDS_QUERY = gql`
  
        # STRAPI V3 - QUERY
        # query {
        # openPositions {
        #     id
        #     open_position_url
        # }
        
        query {
            openPositions {
                data {
                    id
                    attributes {
                        Open_position_url
                    }
                }
           }
        }
`;

export default POSITION_IDS_QUERY;
