<div class="contact-support rounded">
    <div class="title my-4">
        <p class="h3 fw-bold">Contact Support</p>
    </div>
    <div class="row">
        <div class="phone-support col">
            <div class="d-flex">
                <img src="https://vcdn.cosgrid.com/website/assets4/support/contact-support.png" alt="contact support" loading="lazy" width="25rem" height="25rem">
                <p class="h4 mb-2 ms-1 clr-blue-dark fw-bold">Phone Support</p>
            </div>
            
            <p class="clr-black fs-6">+91 90227 64534</p>
        </div>
        <div class="email-support col">
            <div class="d-flex">
                <img src="https://vcdn.cosgrid.com/website/assets4/support/Mail-support.png" alt="mail support" loading="lazy" width="25rem" height="25rem">
                <p class="h4 mb-2 ms-1 clr-blue-dark fw-bold">Email Support</p>
            </div>
            
            <p class="fs-6">
                <a href="mailto:support@cosgrid.com" >support&#64;cosgrid.com</a>
            </p>
            <p class="fs-6">
                <a href="mailto:Contact@cosgrid.com">Contact&#64;cosgrid.com</a>
            </p>
            <p class="fs-6">
                <a href="mailto:Info@cosgrid.com">Info&#64;cosgrid.com</a>
            </p>
        </div>
        <div class="whatsapp-support col">
            <div class="d-flex"><img src="https://vcdn.cosgrid.com/website/assets4/support/whatsapp-support.png" alt="whatsapp-support" width="25rem" height="25rem" loading="lazy">
                <p class="h4 mb-2 ms-1 clr-blue-dark fw-bold">Whatsapp Support</p></div>
            
            <p class="clr-black fs-6">+91 90227 64534</p>
            <p class="clr-black fs-6">+91 86101 44212</p>
        </div>
    </div>

</div>