import gql from 'graphql-tag';

//query in strapi by ID

// const POSITION_DETAILS_QUERY = gql`
//     query openPositions($id: ID!) {
//         openPosition(id: $id) {
//             career {
//                 type
//             }
//             title
//             location
//             employment_type
//             details
//         }
//     }
// `;

//query in strapi v3 by custom filter - String
//in v4 we can use filters query instead of where 

const POSITION_DETAILS_QUERY = gql `
# STRAPI V3 - QUERY
# query openPositions($open_position_url: String!) {
#     openPositions(where: { open_position_url: $open_position_url }) {
#             career {
#                 type
#               }
#             title
#             location
#             employment_type
#             details
#     }
#   }

# STRAPI V4 - QUERY 
query openPositions($open_position_url: String!) {
    openPositions(filters: { Open_position_url: {eq:$open_position_url}}) {
    data {
      id
      attributes {
        career {
          data {
            attributes {
              type
            }
          }
        }
        Title
        Location
        Employment_type
        Details
      }
    }
  }
}
     
`

export default POSITION_DETAILS_QUERY;
