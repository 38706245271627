import gql from 'graphql-tag';

const OPEN_POSITIONS_QUERY = gql`
    # STRAPI V3 - QUERY 
    # query get_opening_positions {
    #     careers {
    #         type
    #         open_positions {
    #             id
    #             title
    #             location
    #             employment_type
    #             open_position_url
    #         }
    #     }
    # }

    # STRAPI V4 - QUERY
        query get_opening_positions {
        careers {
            data {
            id
            attributes {
                type
                open_positions {
                data {
                    id
                    attributes {
                    Title
                    Location
                    Employment_type
                    Open_position_url
                    }
                }
                }
            }
            }
        }
        }
`;

export default OPEN_POSITIONS_QUERY;
