<section class="px-md-5 px-2 my-5 pt-3" [ngStyle]="{'background': 'url(' + backgroundBanner + ') no-repeat center 20% /cover'}" style=" height: 65vh; padding-left: 5%;">
    <div class="row h-100">
        <div class="h-100 col-12 d-flex justify-content-center align-items-center h-100 bg-transparent">
          <div class="custom-width text-center p-1 p-md-5 rounded-lg shadow-lg glass-morphism">
            <p class="fw-bold h1 text-uppercase">Join our team</p>
            <p class="h5 m-3">Discover what's next in your Career ! <br /> Take a look at our latest job opportunities.</p>
            <button class="my-2 bg-clr-blue-dark bg-gradient px-4 py-2 border-0 text-white h5 rounded" [routerLink]="OPENINGS_LINK">View Openings</button>
          </div>
        </div>
    </div>
</section>

<!-- overview -->
<section class="px-10 my-5">
    <p class="fs-6 w-100 text-center px-md-5">
        Working at COSGrid Networks goes beyond being just a job; it presents a chance to discover resolutions for
        complex challenges. It involves pioneering the future of cybersecurity, shaping the next generation of
        innovative solutions.
    </p>
    <p class="fs-5 w-100 text-center px-md-5 my-4 fw-bold clr-blue-dark">
        “ We are looking for not just an employee, we are looking for a Passionate and interested Associate"
    </p>
    <p class="fs-6 w-100 text-center px-md-5">
        We COSGrid Networks , an Enterprise Network securitty startup transforming the way Networks are Built,
        Secured, Operated and Managed. We work on the bleeding edge technologies that solves critical customer
        problems in the cloud and IoT centric world. This is being led by comprehensive research and intellectual
        property development.
    </p>
    <p class="fs-6 w-100 text-center px-md-5">
        We are looking for creative and results-oriented developers, marketing and sales professionals who take
        ownership, drive and scale the business to next level. If this is the next challenge you seek, get in touch
        with us.
    </p>
</section>

<!-- motive -->
<section class=" px-10  my-5">
    <div class="row d-flex justify-content-center align-items-center" *ngFor="let careerPoints of carrerCollection.imgCollection; index as i">
        <div class="col-12 col-md-12 d-flex flex-column flex-md-row" [ngClass]="{
            'flex-md-row-reverse': i % 2 != 0
          }">
          <img alt="career points" [src]="careerPoints.imgURL" [alt]="careerPoints.title" height="200px" class="mb-3 mb-md-0 motive-img" [ngClass]="{
              'pe-md-5': i % 2 == 0,
              'ps-md-5': i % 2 != 0
            }" loading="lazy"/>
          <div class="d-flex flex-column justify-content-center align-items-start">
            <p class="fw-bold h4">{{ careerPoints.title }}</p>
            <p class="fs-6">{{ careerPoints.content }}</p>
          </div>
        </div>
      </div>
      
    <div class="d-flex justify-content-center py-2">
        <button class="btn-primary btn fw-bold fs-5" [routerLink]="OPENINGS_LINK">View Openings</button>
    </div>
</section>