<ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="data">
    <ngu-tile *nguCarouselDef="let item; let i = index">
        <ng-container *ngIf="i % 2 == 0;else elseTemplate">
            <div class="background" [ngStyle]="{'background-image': 'url(' + item.imgUrl + ')'}">
                <p class="clr-white display-4 text-md-left text-center  left ms-md-5">{{item.title}}</p>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <div class="background" [ngStyle]="{'background-image': 'url(' + item.imgUrl + ')'}">
                <p class="clr-white display-4 text-md-right text-center  right me-md-5">{{item.title}}</p>
            </div>
        </ng-template>

    </ngu-tile>
    <button NguCarouselPrev class="nav-btn left-nav" [style.opacity]="myCarousel.isFirst() ? 0.2 : 0.7"
        style="z-index: 1">
        <i class="fa fa-angle-left"></i>
    </button>

    <button NguCarouselNext class="nav-btn right-nav" [style.opacity]="myCarousel.isLast() ? 0.2 : 0.7">
        <i class="fa fa-angle-right" style="user-select: auto"></i>
    </button>

    <ul class="myPoint" NguCarouselPoint>
        <li *ngFor="let number of myCarousel.pointNumbers(); let i = index"
            [class.active]="number == myCarousel.activePoint()" (click)="myCarousel.moveTo(number)"></li>
    </ul>
</ngu-carousel>