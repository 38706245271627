import { isPlatformBrowser, NgStyle, NgFor } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subject, Subscription, pipe } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MyEmitterService } from '../../app-emitter.service';
import { RouteConstants } from '../../config/route.config';
import { IIndustryDetails } from '../../core/models/industries.model';
import { FetchMetaService } from '../../queries/Meta/fetchmeta.service';
import META_QUERY from '../../queries/Meta/meta.service';
import { CanonicalService } from '../../queries/canonical/canonical.service';

@Component({
    selector: 'app-industries',
    templateUrl: './industries.component.html',
    styleUrls: ['./industries.component.scss'],
    providers: [ Apollo, Title, Meta, MyEmitterService, FetchMetaService]
})
export class IndustriesComponent implements OnInit, AfterViewInit, OnDestroy {
    CONTACTUS_LINK = `/${RouteConstants.CONTACTS}/.`;
    SOLUTIONS_LINK = `/${RouteConstants.USECASES}`;
    ERROR_LINK = RouteConstants.ERROR;
    private destroy$ = new Subject<void>();
    isBrowser: boolean;

    id = ['retail', 'manufacturing', 'Logistics', 'healthcare', 'financial-services', 'itites'];
    input = 'data';
    metaContent: any = {
        'retail': { title: 'Retail | Deliver Digital Experiences and Drive Business Growth | COSGrid Networks', desc: 'Discover how COSGrid Networks empowers the retail industry with exceptional digital experiences and business growth.', key: 'Retail, digital experiences, smart retail, online commerce, hybrid commerce, technology-led customer experiences, smart mirrors, same-day delivery, seamless connectivity, secure connectivity, PoS, Inventory management, loyalty programs, AR/VR, IoT, Edge analytics, centralized IT' },
        'manufacturing': { title: 'Manufacturing | Effective and Improved Productivity in Your Smart Factory | COSGrid Networks', desc: 'Discover how COSGrid Networks empowers productivity in manufacturing. ', key: 'Manufacturing, productivity, smart factory, Industry 4.0, Cloud migration, smart supply chain, secure connectivity, QoS, SD-WAN, traffic steering, improved production, flexible migration to the Cloud, secure access to applications, centralized IT' },
        'restaurants_hospitality': { title: 'Restaurants / Hospitality | Deliver Seamless Experiences and Boost Customer Loyalty | COSGrid Networks', desc: 'Discover how COSGrid Networks empowers Restaurant & Hospitality with seamless experiences and customer loyalty. ', key: 'Restaurants, Hospitality, seamless experiences, boost customer loyalty, self-service ordering, fast and secure payments, loyalty programs, take-out, delivery, high-quality internet browsing, prioritized traffic steering, reliable connectivity, secure internet access, Edge deployments, IoT, SD-WAN, centralized IT' },
        'healthcare': { title: 'Healthcare | Transforming Healthcare for Agility & Security | COSGrid Networks', desc: 'Discover how COSGrid Networks transforms healthcare with agility and security.', key: 'Healthcare, digital transformation, agility, security, patient records, secure access, SD-WAN, IoT, cloud, connected medical devices, reliable access to applications, telehealth enablement' },
        'financial-services': { title: 'Financial Services | Seamless and Secure Customer Experiences in Your Smart Branch | COSGrid Networks', desc: 'Discover how COSGrid Networks enables seamless and secure customer experiences in the financial services industry.', key: 'Financial Services, smart branch, seamless customer experiences, secure connectivity, network security, SD-WAN, fintech, regulatory compliance, agile connectivity, rapid branch rollout, innovative solution' },
    }

    isEven(index: number): boolean {
        return index % 2 === 0;
    }

    speed = 100
    counterStarted = false

    animate = (obj: any, initVal: any, lastVal: any | number | null, duration: number) => {
        let startTime: number | any = null;

        //get the current timestamp and assign it to the currentTime variable
        let currentTime = Date.now();

        //pass the current timestamp to the step function
        const step = (currentTime: number | any) => {

            //if the start time is null, assign the current time to startTime
            if (!startTime) {
                startTime = currentTime;
            }

            //calculate the value to be used in calculating the number to be displayed
            const progress = Math.min((currentTime - startTime) / duration, 1);

            //calculate what to be displayed using the value gotten above
            obj.innerHTML = Math.floor(progress * (lastVal - initVal) + initVal);

            //checking to make sure the counter does not exceed the last value (lastVal)
            if (progress < 1) {
                window.requestAnimationFrame(step);
            } else {
                window.cancelAnimationFrame(window.requestAnimationFrame(step));
            }
        };
        //start animating
        window.requestAnimationFrame(step);
    }


    ngAfterViewInit(): void {
        this.checkScroll();
    }

    @HostListener('window:scroll', [])
    onWindowScroll(): void {
        console.log("window scroll");
        if (this.isBrowser)
            this.checkScroll();
    }

    private checkScroll(): void {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

        if (scrollPosition > viewportHeight / 3) {
            if (!this.counterStarted) {
                this.counterStarted = true;
                this.startCounter();
            }
        } else {
            this.counterStarted = false; // Reset flag if scrolled back above the threshold
        }

    }

    startCounter() {
        const counters = document.querySelectorAll('.value');
        counters.forEach(counter => {
            const value = counter.getAttribute('percent')
            if (isPlatformBrowser(this.platformId))
                this.animate(counter, 0, value, 1500);
        });
    }


    main: IIndustryDetails = {
        title: 'string',
        subtitle: 'string',
        imgUrl: 'string',
        statistics: [],
        challengeDescription: 'string',
        challenges: [],
        trendsDescription: 'string',
        trends: [],
        features: [],
        benefitHeading: 'string',
        benefits: [],
        relatedProducts: []
    };


    productReference = {
        ztna: {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
            imgUrl: 'https://vcdn.cosgrid.com/website/assets4/home/MicroZAccess_1.png',
            name: 'ZTNA -Zero Trust Network Access',
            width: '70%'
        },
        sase: {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
            imgUrl: 'https://vcdn.cosgrid.com/website/assets3/Products/SASE/2.webp',
            name: 'SASE- Secure Access Service Edge',
            width: '60%'
        },
        sig: {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
            imgUrl: 'https://vcdn.cosgrid.com/website/assets4/home/our-products-sig.webp',
            fragment: 'sig',
            name: 'SWG- Secure Web Gateway',
            width: '70%',
        },
        sdwan: {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
            imgUrl: 'https://vcdn.cosgrid.com/website/assets3/Products/SDWANv1/1.webp',
            name: 'Secure & Reliable Connectivity SD-WAN',
            width: '50%'
        },
        firewall: {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`,
            imgUrl: 'https://vcdn.cosgrid.com/website/assets3/Products/Firewall/firewall%20logo%20cgrid.webp',
            name: 'NGFW - Next Generation Firewall',
            width: '60%'
        },
        netshield: {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
            imgUrl: 'https://vcdn.cosgrid.com/website/assets3/Products/NDR/NS.svg',
            name: 'NDR- Network Detection and Response',
            width: '50%'
        }
    }


    industries: IIndustryDetails[] = [

        {
            title: "Retail",
            subtitle: "Cutting-edge cyber security platform designed to address the unique challenges & evolving Threats faced by Retail Sector.",
            imgUrl: "https://vcdn.cosgrid.com/website/assets4/industry/retail-new.jpg",
            statistics: [
                {
                    percentage: 75,
                    description: "Retailers have experienced a data breach."
                },
                {
                    percentage: 89,
                    description: "Retailers affected by ransomware reported revenue or business lossess."
                },
                {
                    percentage: 60,
                    description: "Small retial businesses that experience a cyberattack go out of business within six months."
                }
            ],
            challengeDescription: `In retial industry, adapting to customer needs, grapples with rising cybersecurity risks due to handling large amounts of sensitive customer data across various channels.`,
            challenges: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/data-leak.png',
                    title: "Data Protection Concerns",
                    description: `Customers seamlessly transition
                                between online and physical stores,
                                forming a network prone to data
                                breaches. Retailers manage sensitive
                                customer data, including payments
                                and personal info, necessitating
                                comprehensive security measures.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/work-from-home.png',
                    title: 'Increasing Remote & Hybrid Workforce',
                    description: `Managing access and security for
                                employees working remotely or in
                                stores with varying levels of network
                                security adds complexity. Balancing
                                secure access with operational needs
                                is an ongoing challenge.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/security-breach.png',
                    title: "Keeping Pace with Evolving Threats",
                    description: `Cybercriminals innovate constantly,
                                creating challenges for traditional
                                security solutions facing evolving attack
                                methods like ransomware and phishing.
                                Adoption of cloud computing, mobile
                                devices, and IoT expands the attack
                                surface, demanding complex network
                                protection.
                                `
                }
            ],
            trendsDescription: `The retail industry is currently experiencing a digital transformation, incorporating omnichannel experiences, cloud adoption, and mobile
integration. This shift towards digitalization also emphasizes the importance of cybersecurity.`,
            trends: [
                {
                    description: `Retailers adopt <span class="clr-blue-dark">cloud-based solutions</span> for inventory management, customer
                                relationship management (CRM), and other operations, cloud security becomes
                                paramount. This includes securing cloud workloads, data encryption, and access
                                controls for cloud-based resources.
                                `
                },
                {
                    description: `Retailers are implementing security solutions that
                                encompass their entire network, including physical
                                stores, online platforms, and mobile applications. This
                                ensures consistent security policies and protections
                                across all customer touchpoints i.e.) enhanced
                                <span class="clr-blue-dark">Omnichannel Retail Security.</span>
                                `
                },
                {
                    description: `The ever-expanding attack surface and the growing sophistication of cyber
                                threats necessitate robust security strategies. Retailers are increasingly
                                adopting <span class="clr-blue-dark">zero-trust security models, automation, and AI /ML -
                                powered solutions</span> to combat the expanding attack surface and
                                sophisticated cyber threats.
                                `
                }
            ],
            features: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/integrated-lock.png',
                    title: 'Integrated & Enhanced Security',
                    description: `Integrates ZTNA, SWG &
                                MFA, adding an extra layer of
                                security by requiring
                                additional verification factors
                                beyond usernames and
                                passwords. This significantly
                                reduces the risk of
                                unauthorized access attempts.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/cloud-computing.png',
                    title: 'Cloud-Native Architecture',
                    description: `Secure cloud-based applications
                                increasingly used in retail for
                                inventory management,
                                customer relationship
                                management (CRM), and other
                                critical operations. This ensures
                                data protection even when
                                using cloud-based software.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/auditing.png',
                    title: 'Consistent Security Polices',
                    description: `Enables retailers to maintain
                                consistent security policies for
                                all outlets, including physical
                                stores, online platforms, and
                                mobile apps, to eliminate
                                security gaps and vulnerabilities
                                across various customer
                                touchpoints.`
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/centralized.png',
                    title: 'Centralized Management & Visiblity',
                    description: `Simplifies security management
                                for geographically dispersed
                                retail locations by offering a
                                centralized platform for policy
                                enforcement and security
                                monitoring across all branches.
                                `
                },
            ],
            benefitHeading: "SASE Advantage: Securing Your Storefront",
            benefits: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/experience.png',
                    title: 'Improved Customer/User Experience',
                    description: `Boost employee & User satisfaction and
                                productivity with secure remote access.
                                Simplify access, reduce VPN tickets, and ensure
                                fast, reliable access to SaaS data through cloudbased security while reducing IT support.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/mitigate-risk-new.png',
                    title: 'Mitigate risk and ensure compliance',
                    description: `Boost employee & User satisfaction and
                                productivity with secure remote access.
                                Simplify access, reduce VPN tickets, and ensure
                                fast, reliable access to SaaS data through cloudbased security while reducing IT support.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/global-network.png',
                    title: 'Secure Remote Access from Anywhere',
                    description: `Retailers can securely enable remote access for
                                employees to critical systems and data, ensuring
                                flexibility without compromising security. Zero
                                Trust is enforced by verifying all users and devices
                                accessing customer data, minimizing risks of
                                unauthorized access to sensitive information.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/reduce-cost.png',
                    title: 'Simplify IT and Reduce costs',
                    description: `Eliminates the need for complex network
                                segmentation and perimeter security solutions,
                                potentially leading to cost savings on security
                                infrastructure . The cloud-based nature enables
                                easy scaling as retail operations grow and adopt
                                new technologies.
                                `
                }
            ],
            relatedProducts: [
                {
                    product: this.productReference.sase
                },
                {
                    product: this.productReference.ztna
                },
                {
                    product: this.productReference.firewall
                },
                {
                    product: this.productReference.sdwan
                }
            ]
        },
        {
            title: "Manufacturing",
            subtitle: `Cutting-edge cyber security platform designed to address the unique challenges & evolving threats faced by Manufacturing Sector.`,
            imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/manufacturing-new.jpg',
            statistics: [
                {
                    percentage: 61,
                    description: `Manufacturing and production organizations reported an increase in cyberattacks.`
                },
                {
                    percentage: 73,
                    description: `OT devices in manufacturing environments are completely unmanaged, leaving them prone to cyberattacks.`
                },
                {
                    percentage: 40,
                    description: `Manufacturers experience cyber incidents or attacks on their infrastructure every year.`
                }
            ],
            challengeDescription: `In today’s digital age, cybersecurity is a pressing concern for businesses across all sectors, and the manufacturing industry is no
                                exception. With the advent of Industry 4.0 and the increasing reliance on operational technology (OT)/IoT, manufacturing facilities
                                are becoming more vulnerable to cyber threats.`,
            challenges: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/data-leak.png',
                    title: "Increasing Attack Surface",
                    description: `Modern manufacturing facilities no longer
                                operate in isolation. Industrial Control
                                Systems (ICS) overseeing critical
                                operations are now more interconnected
                                with the internet and corporate
                                networks, expanding potential
                                vulnerabilities for malicious exploitation.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/history.png',
                    title: 'Legacy & Outdated Security',
                    description: `Manufacturers often use outdated
                                equipment and systems without
                                strong security, leaving them open to
                                exploits. Upgrading is costly and
                                complex. Breaches in the supply
                                chain can jeopardize data and
                                operations.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/compliant.png',
                    title: "Evolving Threat Landscape",
                    description: `Ransomware attacks encrypting data and
                                disrupting operations pose a major
                                threat. Destructive ICS attacks can cause
                                physical damage and halt production.
                                Cybercriminals evolve tactics; poses the
                                need for manufacturers to guard against
                                zero-day exploits and social engineering
                                affecting employees.
                                `
                }
            ],
            trendsDescription: `The manufacturing industry is undergoing a digital revolution, with increased automation, cloud adoption, and interconnectivity. However, this
                                digital transformation brings with it a heightened focus on cybersecurity.
                                `,
            trends: [
                {
                    description: `Increase in connected devices in manufacturing (IIoT) poses security
                            risks. Makers are adopting strong <span class="clr-blue-dark">IIoT security</span> to protect devices and
                            data. <span class="clr-blue-dark">AI and ML</span> help detect anomalies, threats, and bolster security in
                            manufacturing. They analyze data to prevent cyberattacks.
                            `
                },
                {
                    description: `As manufacturers adopt <span class="clr-blue-dark">cloud-based solutions</span> for
                                data storage, management systems, and analytics, cloud
                                security becomes paramount. This includes securing
                                cloud workloads, data encryption, and access controls for
                                cloud-based resources.
                                `
                },
                {
                    description: `<span class="clr-blue-dark">Zero Trust model</span> assumes no inherent trust within the network.
                                Every user, device, and application attempting to access manufacturing
                                systems, regardless of origin (internal or external), requires continuous
                                verification. This minimizes the attack surface and potential damage
                                from breaches.
                                `
                },
                {
                    description: `Evolving <span class="clr-blue-dark">regulations like NIST Cybersecurity
                                Framework</span> for Manufacturing emphasize robust
                                cybersecurity practices. Manufacturers are adopting
                                automation tools to streamline compliance processes
                                and simplify security assessments.
                                `
                }
            ],
            features: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/integrated-lock.png',
                    title: 'Integrated & Enhanced Security',
                    description: `Integrates ZTNA, SWG &
                                MFA, adding an extra layer of
                                security by requiring
                                additional verification factors
                                beyond usernames and
                                passwords. This significantly
                                reduces the risk of
                                unauthorized access attempts.
                                                    `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/cloud-computing.png',
                    title: 'Enhanced ICS Security',
                    description: `Enforcing Zero Trust verifies
                                all users/devices accessing ICS
                                components, regardless of
                                location, reducing attack
                                surface. And also enables
                                micro- segmentation, isolating
                                critical components to limit
                                potential breach damage.`
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/auditing.png',
                    title: 'IIoT Security & Remote Access',
                    description: `Secure IIoT devices in
                                manufacturing by managing
                                device identity, access, and
                                vulnerabilities. Enable secure
                                remote access for personnel to
                                monitor systems, fostering
                                flexibility without compromising
                                security.`
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/centralized.png',
                    title: 'Centralized Management & Visiblity',
                    description: `Advanced network traffic
                                monitoring detects suspicious
                                activity on the manufacturing
                                network, including
                                cyberattacks on IIoT devices
                                and unauthorized access
                                attempts to sensitive data.`
                },
            ],
            benefitHeading: 'Forging a Secure Future for Manufacturing',
            benefits: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/experience.png',
                    title: 'Improved Customer/User Experience',
                    description: `A frictionless and secure experience fosters a
                                more positive work environment., reduce VPN -
                                related help desk tickets, boost user
                                productivity, and cut IT support while ensuring
                                fast and reliable access to SaaS applications and
                                data through cloud-based security and
                                networking in our Solutions.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/mitigate-risk-new.png',
                    title: 'Improved Operational Resilience with ease of management',
                    description: `Centralized platform manages security
                                policies for manufacturing network, cloud,
                                on-premises, and remote access. Minimizes
                                cyberattack disruptions by securing ICS
                                access and enabling secure remote
                                management.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/global-network.png',
                    title: 'Secure Zero Trust Data Access',
                    description: `Ensure strict authentication for all users and
                                devices accessing financial data irrespective of
                                location. This limits breaches, acting as a secure
                                gateway to your financial assets for verified
                                individuals.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/reduce-cost.png',
                    title: 'Simplify IT and Reduce costs',
                    description: `Consolidate multiple security point solutions,
                                streamlining management overhead and
                                potentially leading to cost savings. It's like
                                merging multiple security guards into a single,
                                efficient team.`
                }
            ],
            relatedProducts: [
                {
                    product: this.productReference.sase
                },
                {
                    product: this.productReference.ztna
                },
                {
                    product: this.productReference.firewall
                },
                {
                    product: this.productReference.sdwan
                }
            ]
        },
        {
            title: "Logistics",
            subtitle: "Cutting-edge cyber security platform designed to address the unique challenges & evolving threats faced by logistics Sector",
            imgUrl: "https://vcdn.cosgrid.com/website/assets4/industry/logistics-new.jpg",
            statistics: [
                {
                    percentage: 75,
                    description: `increase in ransomware
                                attacks targeted at logistics
                                firms in the past two years.
                                `
                },
                {
                    percentage: 43,
                    description: `logistics companies have
                                experienced cybersecurity
                                incidents that disrupted their
                                operations.
                                `
                },
                {
                    percentage: 50,
                    description: `Logistics companies have
                                experienced some form of
                                supply chain attack.`
                }
            ],
            challengeDescription: `The logistics industry, the backbone of global trade, is undergoing a digital revolution. Increased automation, reliance on
interconnected technologies, and a growing emphasis on data-driven operations introduce a complex cybersecurity landscape.`,
            challenges: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/security-breach.png',
                    title: "Increasing Attack Surface",
                    description: `Logistics networks, with various partners
                                and applications, face expanded security
                                risks due to interconnectivity. Security
                                breaches can disrupt supply chains. The
                                industry's uptake of IoT devices for
                                tracking and management brings new
                                security challenges when not secured
                                adequately.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/data-leak.png',
                    title: 'Data Breaches and Sensitive Information',
                    description: `Logistics firms manage a large
                                volume of confidential data, such as
                                customer details, financial
                                information, and shipment tracking
                                data. A security breach could have
                                severe consequences, resulting in
                                financial harm, damage to reputation,
                                and potential legal consequences.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/work-from-home.png',
                    title: "Evolving Remote Workforce",
                    description: `Increasing trends of remote work in
                                logistics, particularly for dispatchers
                                and customer service representatives,
                                highlights the importance of ensuring
                                secure remote access to essential
                                systems and data, all while effectively
                                addressing the security challenges that
                                come with remote work setups.
                                `
                }
            ],
            trendsDescription: `The logistics sector, which plays a crucial part in global trade, is adopting digital transformation. Yet, this shift towards digitalization brings about an
                            increasing worry: cybersecurity risks.
                            `,
            trends: [
                {
                    description: `<span class="clr-blue-dark">Cloud-based technology</span> is being more and more utilized for
                                warehouse management systems, transportation management systems,
                                and other logistics applications. Logistics companies are giving top priority
                                to cloud security measures such as workload protection, data encryption,
                                and access controls for cloud-based resources.
                                `
                },
                {
                    description: `Many logistics companies are now using <span class="clr-blue-dark">automation tools</span>
                                to make <span class="clr-blue-dark">compliance processes</span> more efficient and
                                security assessments easier to manage. <span class="clr-blue-dark">AI and ML</span> can be
                                powerful tools for anomaly detection, threat identification,
                                and proactive security measures in logistics operations.                    
                                `
                },
                {
                    description: `Acknowledging that security breaches in one company can cause
                                disruptions in the entire supply chain, logistics firms are working
                                together with partners and vendors to evaluate and enhance security
                                measures across the supply chain network. This collaborative approach
                                enhances the <span class="clr-blue-dark">overall security readiness of the supply chain</span>.
                                `
                },
                {
                    description: `With the logistics industry integrating more IoT devices for
                                tracking and automation, securing these devices is crucial. The
                                merging of <span class="clr-blue-dark">IT and OT networks</span> in logistics demands a
                                unified security approach across both domains.
                                `
                }
            ],
            features: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/integrated-lock.png',
                    title: 'Integrated & Enhanced Security',
                    description: `Integrates ZTNA, SWG &
                                MFA, adding an extra layer of
                                security by requiring
                                additional verification factors
                                beyond usernames and
                                passwords. This significantly
                                reduces the risk of
                                unauthorized access attempts.
                                                    `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/cloud-computing.png',
                    title: 'IoT & Device Security',
                    description: `Secure IoT devices in
                                warehouses and connected
                                fleets with identity
                                management, access
                                control, and vulnerability
                                assessments to protect
                                devices and their data.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/auditing.png',
                    title: 'Streamlined Auditing & Reporting',
                    description: `Generates comprehensive
                                audit logs that track user
                                activity, access attempts, and
                                security events. This simplifies
                                compliance reporting
                                demonstrations and facilitates
                                security investigations in case
                                of incidents.`
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/centralized.png',
                    title: 'Centralized Management & Visiblity',
                    description: `Provides advanced network
                                traffic monitoring to detect
                                suspicious activity within the
                                logistics network, including
                                identifying potential
                                cyberattacks on warehouse
                                systems, connected vehicles,
                                or unauthorized data access.`
                },
            ],
            benefitHeading: `Safeguarding Your Supply Chain & Organization`,
            benefits: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/experience.png',
                    title: 'Improved Customer/User Experience',
                    description: `A frictionless and secure experience fosters a
                                more positive work environment., reduce VPN -
                                related help desk tickets, boost user
                                productivity, and cut IT support while ensuring
                                fast and reliable access to SaaS applications and
                                data through cloud-based security and
                                networking in our Solutions.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/mitigate-risk-new.png',
                    title: 'Enhanced security and supply chain resilience.',
                    description: `Provides a single platform to manage security
                                policies for the logistics network, covering
                                cloud, on-premises, remote access, and
                                devices, reducing disruptions from
                                cyberattacks by securing system access and
                                enabling remote management securely.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/global-network.png',
                    title: 'Secure Zero Trust Data Access',
                    description: `Ensure strict authentication for all users and
                                devices accessing organization data irrespective of
                                location. This limits breaches, acting as a secure
                                gateway to your financial assets for verified
                                individuals.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/reduce-cost.png',
                    title: 'Simplify IT and Reduce costs',
                    description: `Consolidate multiple security point solutions,
                                streamlining management overhead and
                                potentially leading to cost savings. It's like
                                merging multiple security guards into a single,
                                efficient team.`
                }
            ],
            relatedProducts: [
                {
                    product: this.productReference.sase
                },
                {
                    product: this.productReference.ztna
                },
                {
                    product: this.productReference.firewall
                },
                {
                    product: this.productReference.sdwan
                }
            ]
        },
        {
            title: "Healthcare",
            subtitle: 'Cutting edge cyber security platform designed to address the unique challenges faced by healthcare organizations in safeguarding sensitive patient data and maintaining regulatory compliance.',
            imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/healthcare-new.jpg',
            statistics: [
                {
                    percentage: 67,
                    description: 'Healthcare organizations experienced a data breach in the past year.'
                },
                {
                    percentage: 82,
                    description: 'Healthcare IT professionals cite security as their top concern.'
                },
                {
                    percentage: 59,
                    description: 'Remote work has increased in the healthcare sector, leading to a rise in cyber attacks targeting remote access technologies.'
                }
            ],
            challengeDescription: 'A rise in cyber attacks targeting health organizations, increased use of mobile devices and remote work, and the need to securely access and share patient information across various platforms.  These challenges can result in data breaches, financial losses, and damage to reputation.',
            challenges: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/data-leak.png',
                    title: "Data Breaches",
                    description: "Healthcare organizations are prime targets for cyber criminals due to the valueable personal and medical information they possess.  With the increasing sophistication of cyber attacks, traditional security measures are no longer sufficient to protect against data breaches."
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/work-from-home.png',
                    title: 'Remote Workforce',
                    description: 'The shift towards remote work has introduced new vulnerability points in healthcare networks, making it challenging to ensure secure access to critical systems and data for employees working from various locations.'
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/compliant.png',
                    title: "Compliance Requirements",
                    description: `Healthcare organizations are required to
                                comply with stringent data protection
                                regulations such as HIPAA (Health
                                Insurance Portability and Accountability
                                Act). Non-compliance can result in hefty
                                fines and damage to the organization's
                                reputation.
                                `
                }
            ],
            trendsDescription: `HIPAA mandates that organizations safeguard patient data, and deploying COSGrid solutions can streamline the organization compliance efforts.
In this era of advancing healthcare, healthcare organizations must embrace digital transformation for their traditional systems and tools.`,
            trends: [
                {
                    description: `Emerging technologies such as <span class='clr-blue-dark'>IoT, AI, and Big Data</span>
transform healthcare. They improve efficiency, data
insights, healthcare predictions, remote monitoring, and
simplify medical procedures.
`
                },
                {
                    description: `<span class='clr-blue-dark'>Cloud</span> technology is empowering hospitals by
providing convenient storage and continuous
availability of electronic health records (EHRs)
regardless of the location of care providers and
patients.`
                },
                {
                    description: `Hospitals use <span class='clr-blue-dark'>VDIs to access patient records</span>
securely and quickly on mobile devices.
`
                },
                {
                    description: `<span class='clr-blue-dark'>Telehealth</span> services allow healthcare professionals
to conduct remote medical appointments, reducing
costs and providing high-quality care to distant
patients.`
                }
            ],
            features: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/integrated-lock.png',
                    title: 'Integrated & Enhanced Security',
                    description: `Integrates
                                secure web gateway, zero trust
                                network access, & firewall as
                                a service for simplified security
                                management and enhanced
                                protection.`
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/cloud-computing.png',
                    title: 'Cloud-Native Architecture',
                    description: `Cloud-native tech for
                                adaptable, high- performance
                                security in healthcare IT,
                                ensuring mission-critical app
                                availability.`
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/auditing.png',
                    title: 'Efficient Auditing & Reporting',
                    description: `Create detailed audit
                                logs for compliance and
                                security audits.`
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/centralized.png',
                    title: 'Centralized Policy control & Management',
                    description: `Easily manage security
                                policies and access controls
                                from one platform, reducing
                                complexity.`
                },
            ],
            benefitHeading: 'Delivering Superior Smart SASE Care',
            benefits: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/improved-doctor.png',
                    title: 'Improved Patient/Employee Experience with Performance',
                    description: `Simplify secure access, reduce VPN -related
                                help desk tickets, boost user productivity,
                                and cut IT support while ensuring fast and
                                reliable access to healthcare applications
                                and data through cloud-based security and
                                networking in our Solutions.`
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/mitigate-risk-new.png',
                    title: 'Mitigate risk and ensure compliance',
                    description: `Prevent ransomware attacks and block
                                unauthorized access to your network by
                                shifting to an internet-based network and
                                reducing the attack surface. Segregate traffic
                                types and ensure compliance standards for
                                IoT, OT, and EMR data.`
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/global-network.png',
                    title: 'Real-Time Visibility',
                    description: `Ease of compliance efforts through centralized
                                security policies and detailed logging, reducing
                                audit preparation time and costs. It also allows
                                secure remote access for branch offices and
                                telehealth services, removing the necessity for
                                costly on-site security solutions.`

                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/reduce-cost.png',
                    title: 'Simplify IT and Reduce costs',
                    description: `By centralizing monitoring and control in
                                healthcare facilities, staff can effortlessly deploy
                                updates, manage business policies, customize rules,
                                monitor performance, and assess capacity at a
                                single location. This streamlines network oversight
                                and issue resolution, eliminating the need to
                                physically visit remote sites.`
                }
            ],
            relatedProducts: [
                {
                    product: this.productReference.sase
                },
                {
                    product: this.productReference.ztna
                },
                {
                    product: this.productReference.firewall
                },
                {
                    product: this.productReference.sdwan
                },
                {
                    product: this.productReference.netshield
                }
            ]
        },
        {
            imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/Finserv-new.jpg',
            title: "Financial Services",
            subtitle: `Cutting-edge cyber security platform
                    designed to address the unique challenges
                    & evolving threats faced by Financial
                    Services Sector
                    `,
            statistics: [
                {
                    percentage: 71,
                    description: `Financial institutions experienced a cyberattack in past year.`
                },
                {
                    percentage: 22,
                    description: `Financial Services accounts for
                                22 percent of all data
                                breaches.
                                `
                },
                {
                    percentage: 64,
                    description: `Ransomware attacks on financial
                                services on the past year.
                                `
                }
            ],
            challengeDescription: `The financial services industry, entrusted with safeguarding sensitive financial data and facilitating secure transactions, faces a unique
                                and ever-evolving landscape of cybersecurity challenges.`,
            challenges: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/data-leak.png',
                    title: "Increasing Attack Surface",
                    description: `Financial institutions operate within a
                                complex ecosystem, collaborating with
                                partners and third-party applications to
                                extend services, increasing vulnerability to
                                cyber threats like ransomware and
                                phishing scams.: Open banking initiatives,
                                allowing third-party applications to access
                                customer financial data with consent,
                                introduce new security considerations.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/work-from-home.png',
                    title: 'Remote Workforce & Cloud Adoption',
                    description: `The shift towards remote
                                workforces & the increasing use of
                                cloud-based applications introduce
                                emerging security challenges.
                                Traditional security measures
                                designed for on-premises networks
                                may not adequately protect data
                                accessed remotely or stored in the
                                cloud.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/compliant.png',
                    title: "Evolving Regulatory Landscape",
                    description: `Financial institutions must comply with
                                a growing web of regulations like PCI
                                DSS (Payment Card Industry Data
                                Security Standard) and GDPR
                                (General Data Protection Regulation).
                                These regulations mandate robust
                                data security measures, requiring
                                continuous adaptation and investment
                                in security solutions.
                                `
                }
            ],
            trendsDescription: `The financial services sector is always evolving, and so are cyber attackers. Here are some important trends influencing the cybersecurity
                                landscape for financial organizations.`,
            trends: [
                {
                    description: `Evolving Use of <span class='clr-blue-dark'>Artificial Intelligence (AI) and Machine
                                Learning (ML)</span> in Financial services for threat detection, anomaly
                                analysis, and fraud prevention, while cybercriminals may use them to
                                automate attacks and evade detection.
                                `
                },
                {
                    description: `As per <span class='clr-blue-dark'>Data privacy regulations</span> , it is crucial for
                                financial institutions to focus on data privacy and give
                                users more control over their financial information. This
                                involves providing transparent data privacy policies and
                                tools for controlling data access and permissions.
                                `
                },
                {
                    description: `<span class='clr-blue-dark'>Cybersecurity Mesh Architecture</span>: This emerging concept
                                proposes a distributed security architecture with interconnected
                                security services deployed at various points in the network. It offers
                                greater flexibility, scalability, and adaptability to the dynamic security
                                needs of financial institutions.
                                `
                }
            ],
            features: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/integrated-lock.png',
                    title: 'Integrated & Enhanced Security',
                    description: `Integrates ZTNA, SWG & MFA,
                                adding an extra layer of security
                                by requiring additional
                                verification factors beyond
                                usernames and passwords. This
                                significantly reduces the risk of
                                unauthorized access attempts.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/trust.png',
                    title: 'Enhanced Device Trust',
                    description: `Enforce device security policies
                                on employee laptops and
                                mobile devices used for
                                accessing financial data and
                                applications. This ensures
                                devices meet minimum security
                                standards before granting access
                                to sensitive information.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/auditing.png',
                    title: 'Streamlined Auditing & Reporting',
                    description: `Generates comprehensive audit
                                logs that track user activity, access
                                attempts, and security events.
                                This simplifies compliance
                                reporting demonstrations and
                                facilitates security investigations in
                                case of incidents.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/centralized.png',
                    title: 'Centralized Management & Visibility',
                    description: `Offers a unified platform to
                                oversee security policies
                                throughout the full network,
                                covering remote access, cloud
                                apps, on prem and data
                                centers.
                                `
                },
            ],
            benefitHeading: "Unified SASE Security for a Secure Financial Future",
            benefits: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/experience.png',
                    title: 'Improved Customer/User Experience',
                    description: `A frictionless and secure experience fosters a
                                more positive work environment., reduce VPN -
                                related help desk tickets, boost user
                                productivity, and cut IT support while ensuring
                                fast and reliable access to SaaS applications and
                                data through cloud-based security and
                                networking in our Solutions.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/mitigate-risk-new.png',
                    title: 'Mitigate risk and ensure compliance',
                    description: `Minimizes the potential attack surface by
                                continuously verifying every user and device
                                attempting to access IT/ITES resources,
                                regardless of location or network origin.
                                Ensures compliance with data privacy
                                regulations through granular access control
                                and detailed audit trails.
                                `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/global-network.png',
                    title: 'Secure Zero Trust Data Access',
                    description: `Ensure strict authentication for all users and
                                devices accessing financial data irrespective of
                                location. This limits breaches, acting as a secure
                                gateway to your financial assets for verified
                                individuals.
                                `

                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/reduce-cost.png',
                    title: 'Simplify IT and Reduce costs',
                    description: `Consolidate multiple security point solutions,
                                streamlining management overhead and
                                potentially leading to cost savings. It's like
                                merging multiple security guards into a single,
                                efficient team.
                                `
                }
            ],
            relatedProducts: [
                {
                    product: this.productReference.sase
                },
                {
                    product: this.productReference.ztna
                },
                {
                    product: this.productReference.firewall
                },
                {
                    product: this.productReference.sdwan
                },
                {
                    product: this.productReference.netshield
                }
            ]
        },
        {
            title: "IT/ITES",
            subtitle: 'Cutting edge cyber security platform designed to address the unique challenges faced by IT/ITES Landscape.',
            imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/itites-new.jpg',
            statistics: [
                {
                    percentage: 43,
                    description: `IT/ITES organizations
                                    experienced a cyberattack in
                                    past year targeting their cloud
                                    workloads.
                                    `
                },
                {
                    percentage: 13,
                    description: `Remote workforces are
                                    more likely to fall victim to
                                    phishing attacks.
                                    `
                },
                {
                    percentage: 41,
                    description: `Organizations identified hybrid IT
                                    situations as their biggest cyber
                                    security challenge.
                                    `
                }
            ],
            challengeDescription: 'The IT/ITES sector, despite its continuous advancements and expansion, is constantly confronted with ongoing challenges',
            challenges: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/data-leak.png',
                    title: "Integration Complexity",
                    description: `Many IT/ITES organizations rely on a
                                    patchwork of security solutions from
                                    different vendors. Integrating these
                                    disparate systems can be complex
                                    and time-consuming, creating
                                    vulnerabilities and hindering overall
                                    security effectiveness.`
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/work-from-home.png',
                    title: 'Increasing Remote & Hybrid Workforce',
                    description: `Enabling secure access to critical
                                    resources for geographically
                                    dispersed teams with Securing a
                                    Hybrid & Multi- Cloud Environment.
                                    Since, sensitive data across onpremises, cloud, and SaaS
                                    applications requires a holistic
                                    approach.
                                    `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/compliant.png',
                    title: "Keeping Pace with Evolving Threats",
                    description: `Cybercriminals innovate constantly,
                                    creating challenges for traditional
                                    security solutions facing evolving attack
                                    methods like ransomware and
                                    phishing. Adoption of cloud
                                    computing, mobile devices, and IoT
                                    expands the attack surface, demanding
                                    complex network protection.
                                    `
                }
            ],
            trendsDescription: `IT/ITES sector is currently experiencing a major shift with significant digital transformation. With technology advancing quickly, three main factors
cloud computing, cybersecurity, and compliance - are influencing the industry's future.
                `,
            trends: [
                {
                    description: `Businesses are increasingly embracing the flexibility, scalability, and
cost-effectiveness of cloud solutions. This shift towards <span class='clr-blue-dark'>hybrid and
multi-cloud environments</span> is driving the development of new
cloud-native technologies and security measures.
`
                },
                {
                    description: `<span class='clr-blue-dark'>Data privacy regulations</span> are popping up around
the globe, adding another layer of complexity for
IT/ITES businesses. Organizations are focusing on
data governance, automation of compliance
processes, and integrating security practices to meet
these evolving requirements.`
                },
                {
                    description: `The ever-expanding attack surface and the growing sophistication of
cyber threats necessitate robust security strategies. Organizations are
increasingly adopting <span class='clr-blue-dark'>zero-trust security models, automation,
and AI-powered solutions</span> to combat the expanding attack surface
and sophisticated cyber threats.
`
                },
            ],
            features: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/integrated-lock.png',
                    title: 'Integrated & Enhanced Security',
                    description: `Integrates ZTNA, SWG & MFA,
                                    adding an extra layer of security
                                    by requiring additional
                                    verification factors beyond
                                    usernames and passwords. This
                                    significantly reduces the risk of
                                    unauthorized access attempts.
                                    `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/cloud-computing.png',
                    title: 'Cloud-Native Architecture',
                    description: `Cloud-native architecture
                                    promotes collaboration
                                    between development and
                                    operations (DevOps),
                                    emphasizing continuous
                                    delivery, rapid iteration, and
                                    infrastructure automation, in
                                    line with agile methodologies.
                                    `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/auditing.png',
                    title: 'Scalable & Agility',
                    description: `Cloud-based nature allows
                                    for easy scaling as IT/ITES
                                    businesses grow and adopt
                                    new technologies. This
                                    eliminates the need for
                                    complex on-prem security
                                    infrastructure deployments.
                                    `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/centralized.png',
                    title: 'Centralized Management & Visibility',
                    description: `Offers a unified platform to
                                    oversee security policies
                                    throughout the full network,
                                    covering remote access,
                                    cloud apps, and data centers
                                    for IT/ITES teams, enhancing
                                    efficiency.
                                    `
                },
            ],
            benefitHeading: 'SASE Advantage: Unified Security for a Modern IT/ITES',
            benefits: [
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/experience.png',
                    title: 'Improved Customer/User Experience',
                    description: `Simplify secure access, reduce VPN -related
                                    help desk tickets, boost user productivity, and
                                    cut IT support while ensuring fast and reliable
                                    access to SaaS applications and data through
                                    cloud-based security and networking in our
                                    Solutions.
                                    `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/mitigate-risk-new.png',
                    title: 'Mitigate risk and ensure compliance',
                    description: `Minimizes the potential attack surface by
                                    continuously verifying every user and device
                                    attempting to access IT/ITES resources,
                                    regardless of location or network origin.
                                    Ensures compliance with data privacy
                                    regulations through granular access control
                                    and detailed audit trails.
                                    `
                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/global-network.png',
                    title: 'Secure Remote Access from Anywhere',
                    description: `Empowers a remote IT/ITES workforce with
                                        secure access to critical applications and
                                        resources from any location, on any device. This
                                        ensures business continuity and facilitates
                                        collaboration without compromising security.
                                        `

                },
                {
                    imgUrl: 'https://vcdn.cosgrid.com/website/assets4/industry/reduce-cost.png',
                    title: 'Simplify IT and Reduce costs',
                    description: `Eliminates the need for complex network
                                    segmentation and perimeter security solutions,
                                    potentially leading to cost savings on security
                                    infrastructure And also simplifies user provisioning
                                    and deprovisioning by automating access controls.
                                    This reduces administrative overhead for IT/ITES.
                                    `
                }
            ],
            relatedProducts: [
                {
                    product: this.productReference.sase
                },
                {
                    product: this.productReference.ztna
                },
                {
                    product: this.productReference.firewall
                }
            ]
        },
    ]

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private emitter: MyEmitterService, private titleService: Title, private metaService: Meta, private fetchMetaService: FetchMetaService, private apollo: Apollo, @Inject(PLATFORM_ID) private platformId: object, private canonicalService: CanonicalService) {
        this.isBrowser = isPlatformBrowser(this.platformId)
    }

    loadIndustryData() {

        if (!this.id.includes(this.input)) {
            this.router.navigate([this.ERROR_LINK + '/' + [this.input]]);
        }
        try {
            if (this.fetchMetaService.getData() == false) {
                let metatags = [];
                this.apollo
                    .watchQuery({
                        query: META_QUERY,
                    })
                    .valueChanges
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((result) => {
                        let meta = [];
                        meta = (result.data as any).metaTags.data.map((metaTag: any) => (
                            {
                                ...metaTag,
                            }
                        ));
                        for (let i = 0; i < meta.length; i++) {
                            metatags[meta[i].attributes.URL_ID] = meta[i];
                        }
                        // console.log("Meta Tags : ", this.metatags);
                        this.fetchMetaService.storeData(metatags);
                        let metaTagsData = this.fetchMetaService.getData();
                        //   console.log("Meta Tags ", metaTagsData);
                        this.titleService.setTitle(metaTagsData[this.input].attributes.title);
                        this.metaService.updateTag({
                            name: 'description',
                            content:
                                metaTagsData[this.input].attributes.description,
                        });
                        this.metaService.updateTag({
                            name: 'keywords',
                            content: metaTagsData[this.input].attributes.keywords
                        });
                    });
            }
            else {
                //   console.log("Data Alreday Fetched");
                const data = this.fetchMetaService.getData();
                this.titleService.setTitle(data[this.input].attributes.title);
                this.metaService.updateTag({
                    name: 'description',
                    content:
                        data[this.input].attributes.description,
                });
                this.metaService.updateTag({
                    name: 'keywords',
                    content: data[this.input].attributes.keywords
                });

            }

        }
        catch {

        }
        // this.titleService.setTitle(this.metaContent[this.input].title);
        // this.metaService.updateTag({ name: 'description', content: `${ this.metaContent[this.input].desc }` });
        // this.metaService.updateTag({ name: 'keywords', content: `${ this.metaContent[this.input].key }` });
        let a = this.id.indexOf(this.input);
        this.main = this.industries[a];

        //   console.log(this.main)

        this.emitter.getindustriesEvent().pipe(takeUntil(this.destroy$)).subscribe((data) => {
            //   console.log(data.split("/"));
            let a = this.id.indexOf(data.split('/')[1]);
            // this.titleService.setTitle(this.metaContent[data.split('/')[1]].title);
            // this.metaService.updateTag({ name: 'description', content: `${ this.metaContent[data.split('/')[1]].desc }` });
            // this.metaService.updateTag({ name: 'keywords', content: `${ this.metaContent[data.split('/')[1]].key }` });
            this.main = this.industries[a];
            try {
                if (this.fetchMetaService.getData() == false) {
                    let metatags = [];
                    this.apollo
                        .watchQuery({
                            query: META_QUERY,
                        })
                        .valueChanges
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((result) => {
                            let meta = [];
                            meta = (result.data as any).metaTags.data.map((metaTag: any) => (
                                {
                                    ...metaTag,
                                }
                            ));
                            for (let i = 0; i < meta.length; i++) {
                                metatags[meta[i].attributes.URL_ID] = meta[i];
                            }
                            // console.log("Meta Tags : ", this.metatags);
                            this.fetchMetaService.storeData(metatags);
                            let metaTagsData = this.fetchMetaService.getData();
                            //   console.log("Meta Tags ", metaTagsData);
                            this.titleService.setTitle(metaTagsData[data.split('/')[1]].attributes.title);
                            this.metaService.updateTag({
                                name: 'description',
                                content:
                                    metaTagsData[data.split('/')[1]].attributes.description,
                            });
                            this.metaService.updateTag({
                                name: 'keywords',
                                content: metaTagsData[data.split('/')[1]].attributes.keywords
                            });
                        });
                }
                else {
                    //   console.log("Data Alreday Fetched");
                    const data = this.fetchMetaService.getData();
                    this.titleService.setTitle(data[data.split('/')[1]].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content:
                            data[data.split('/')[1]].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: data[data.split('/')[1]].attributes.keywords
                    });

                }

            }
            catch {

            }
        });

    }

    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        // Subscribe to route params to handle the changes
        this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe(params => {
            this.input = params['id'];
            this.loadIndustryData();
        });

    }

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }

}
