import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
    selector: 'image-list',
    templateUrl: 'image-list.html',
    styleUrls: ['./image-list.scss'],
})
export class ImageList {
    screenWidth: number = 0;

    @Input() gridHeight: any;
    public innerWidth: any;

    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {

                if (isPlatformBrowser(this.platformId)) {
            this.screenWidth = window.innerWidth;
        }
    }

    @Input() gridClass: string = '';
    @Input() data: any;
    @Input() descCenter: boolean = false;
    @Input() titleCenter: boolean = false;
    @Input() alignCenter: boolean = true;
    @Input() xlSingleLineGrids = '4';
    @Input() showShadow = true;
    @Input() halfWidth: boolean = false;
    @Input() shadowColor: string = '#fff';
    @Input() whiteColor: boolean = false;
    // 1 -> Top
    // 2 -> Right

    @Input() imagePosition = '1';

    constructor(@Inject(PLATFORM_ID) protected platformId: Object) {
        if (isPlatformBrowser(this.platformId)) {
            this.innerWidth = window.screen.availWidth;
        }
    }
}
