<div class="submit-ticket rounded">
    <div class="d-flex justify-content-between align-items-center my-4">
        <p class="h3 fw-bold">COSGrid - Submit Ticket</p>
        <img src="https://vcdn.cosgrid.com/website/assets/images/navbar/fLogo.svg" alt="COSGrid"
            width="17%">
    </div>
    <form [formGroup]="ticketForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col">
                <p class="fs-6">Case Type<span style="color: red;">*</span></p>
                <select name="case type" id="case-type" formControlName="caseType">
                    <option value="" default>Select</option>
                    <option *ngFor="let item of caseTypeList" [value]="item.value">{{item.name}}</option>
                </select>
                <div class="error" *ngIf="ticketForm.controls['caseType'].touched && ticketForm.controls['caseType'].status == 'INVALID'">
                    Please select one Case Type
                </div>
            </div>
            <div class="col">
                <p class="fs-6">Subject</p>
                <input type="text" formControlName="subject" placeholder="Enter Subject">
                <div class="error" *ngIf="ticketForm.controls['subject'].touched && ticketForm.controls['subject'].status == 'INVALID'">
                    Please Enter Subject
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="fs-6">Priority<span style="color: red;">*</span></p>
                <select name="priority" id="priority" formControlName="priority">
                    <option value="" default>Select</option>
                    <option *ngFor="let item of priorityList" [value]="item.value">{{item.name}}</option>
                </select>
                <div class="error" *ngIf="ticketForm.controls['priority'].touched && ticketForm.controls['priority'].status == 'INVALID'">
                    Please select one Priority
                </div>
            </div>
            <div class="col">
                <p class="fs-6">Company Name<span style="color: red;">*</span></p>
                <input type="text" formControlName="companyName" placeholder="Enter Company name">
                <div class="error" *ngIf="ticketForm.controls['companyName'].touched && ticketForm.controls['companyName'].status == 'INVALID'">
                    Please Enter your Company name
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="fs-6">Description<span style="color: red;">*</span></p>
                <textarea name="description" id="description" formControlName="description"
                    placeholder="Type here"></textarea>
                    <div class="error" *ngIf="ticketForm.controls['description'].touched && ticketForm.controls['description'].status == 'INVALID'">
                        Please type the Description
                    </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="fs-6">First Name<span style="color: red;">*</span></p>
                <input type="text" formControlName="firstName" placeholder="Enter First name">
                <div class="error" *ngIf="ticketForm.controls['firstName'].touched && ticketForm.controls['firstName'].status == 'INVALID'">
                    Please Enter your First Name
                </div>
            </div>
            <div class="col">
                <p class="fs-6">Last Name<span style="color: red;">*</span></p>
                <input type="text" formControlName="lastName" placeholder="Enter Last name">
                <div class="error" *ngIf="ticketForm.controls['lastName'].touched && ticketForm.controls['lastName'].status == 'INVALID'">
                    Please Enter your Last name
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="fs-6">Email Address<span style="color: red;">*</span></p>
                <input type="email" formControlName="email" placeholder="xxx@gmail.com">
                <div class="error" *ngIf="ticketForm.controls['email'].touched && ticketForm.controls['email'].status == 'INVALID'">
                    Please Enter your Email address
                </div>
            </div>
            <div class="col">
                <p class="fs-6">Phone Number<span style="color: red;">*</span></p>
                <div class="d-flex align-items-center">
                    <input type="number" formControlName="countryCode" style="width: 50px;margin-right: 2px;"
                        placeholder="00">
                    <input type="tel" formControlName="phoneNumber" placeholder="000 000 0000">
                </div>
                <div class="error" *ngIf="ticketForm.controls['phoneNumber'].touched && ticketForm.controls['phoneNumber'].status == 'INVALID'">
                    Please Enter valid phone number
                </div>
                <div class="error" *ngIf="ticketForm.controls['phoneNumber'].valid && ticketForm.controls['countryCode'].touched && ticketForm.controls['countryCode'].invalid">
                    Please Enter country code
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="fs-6">Collaborator</p>
                <input type="text" formControlName="collaborator" placeholder="Enter Collaborator mailId's seperated with ' , '">
            </div>
        </div>
        <div class="row">
            <div class="col">
                <p class="fs-6">Preferred Working Hours<span style="color: red;">*</span></p>
                <select name="Preferred Working Hours" id="Preferred-Working-Hours" formControlName="preferredWorkingHours">
                    <option value="" default>Select</option>
                    <option *ngFor="let item of workingHoursList" [value]="item.value">{{item.name}}</option>
                </select>
                <div class="error" *ngIf="ticketForm.controls['preferredWorkingHours'].touched && ticketForm.controls['preferredWorkingHours'].status == 'INVALID'">
                    Please Select your working hours
                </div>
            </div>
            <div class="col">
                <p class="fs-6">Preferred Mode of Communication<span style="color: red;">*</span></p>
                <select name="mode fo communication" id="mode-fo-communication" formControlName="modeOfCommunication">
                    <option value="" default>Select</option>
                    <option value="email">Email</option>
                    <option value="phone">Phone</option>
                </select>
                <div class="error" *ngIf="ticketForm.controls['modeOfCommunication'].touched && ticketForm.controls['modeOfCommunication'].status == 'INVALID'">
                    Please Select your mode of communication
                </div>
            </div>
        </div>
        <div class="d-flex flex-column align-items-center mt-5">
            <ngx-recaptcha2 #captchaElem [siteKey]="recaptchaKey"
                (success)="handleSuccess($event)" [size]="size" [hl]="lang" [theme]="theme" [type]="type"
                formControlName="recaptcha">
            </ngx-recaptcha2>
            <button class="btn btn-primary w-30 fs-6" type="submit">Submit</button>
        </div>
       
    </form>
</div>