
<!-- firewall -->
<section class="px-md-5 my-5 px-2 pt-3">
    <div class="row mx-lg-5">
        <div class="col-lg-7 d-flex flex-column pt-3 mt-lg-5 heading">
            <h1 class="fw-bold font-justify h2 text-center text-md-left">Security Simplified with Seamless Management</h1>
            <img src="https://vcdn.cosgrid.com/website/assets3/Products/Firewall/firewall logo cgrid.webp" alt="title"
                class="w-70 mt-2" loading="lazy" />
        </div>
        <div class="col-lg-5 mt-3 mt-lg-0 d-flex justify-content-center">
            <img src="https://vcdn.cosgrid.com/website/assets3/Products/Firewall/firewall-cover.png" alt="firewall"
                class="w-90 animate-bob" loading="lazy" />
        </div>
    </div>
</section>
<!-- overview -->
<section class="px-md-5 my-5 px-2 overview">
    <div class="row p-4">
        <div class="px-lg-5 col-12 col-lg-6 d-flex flex-column justify-content-center">
            <h1 class="mb-3 fw-bold clr-white h2">{{ firewallOverview.title }}</h1>
            <p class="text-justify clr-white fs-6">{{ firewallIntro }}</p>
            <div class="">
                <a href="https://vcdn.cosgrid.com/website/strapi/CG%20NFRXG%20%20datasheet_57c64fbc-cbea-4f8d-bdad-3145a66718d3.pdf"
                    target="_blank" class="btn btn-primary rounded-pill hover-popup align-center fw-bold">Download Datasheet</a>
            </div>
        </div>
        <div class="col-lg-6 ">
            <carousel-list [data]="firewallHighlights"
                baseUrl="https://vcdn.cosgrid.com/website/assets3/Products/Firewall/" [descOnly]="true"></carousel-list>
        </div>
    </div>
</section>


<!-- Overview image -->
<section class="px-md-5 my-5 px-2">
    <div class="row px-lg-5 px-1 mt-5">
        <div class="d-flex flex-column align-items-center">
            <h1 class="text-justify mt-3 fw-bold mb-3 h2">{{ firewallOverview.imgTitle }}</h1>
            <img src="https://vcdn.cosgrid.com/website/assets3/Products/Firewall/COSGrid NFRG - Next Gen Firewall.png"
                [alt]="firewallOverview.imgTitle" class="w-80 my-3" loading="lazy" />
        </div>
    </div>
</section>

<!-- overview points -->
<section class="px-md-5 my-5 px-2">
    <p class="my-3 fw-bold clr-blue-dark fs-4">{{ firewallOverview.listTitle }}</p>
    <p class="d-flex text-justify" *ngFor="let item of firewallOverview.list">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="23px" height="18px">
            <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
            <path fill="#006d8f"
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
        </svg>
        <span class="ms-2">{{item}}</span>
    </p>
</section>

<!-- features -->
<section class="px-md-5 my-5 px-2">
    <h1 class="fw-bold clr-blue-dark h2 text-center">Features</h1>
    <slider-list class="d-flex flex-column justify-content-center align-items-center" [data]="{
        data: firewallFeatures,
        imgBaseUrl: 'https://vcdn.cosgrid.com/website/assets3/Products/Firewall/'
    }"></slider-list>
</section>
<!-- advantages -->
<section class="px-md-5 my-5 px-2">
    <div class="row">
        <div class="col text-md-left">
            <h1 class="fw-bold text-center pt-4 clr-blue-dark h2">
                COSGrid NGFW Advantages
            </h1>
        </div>
    </div>
    <div class="w-100 px-0 px-md-5">
        <table-list [data]="firewallAdvantages" class="container"
            baseUrl="https://vcdn.cosgrid.com/website/assets3/Products/Firewall/" [xlGridsInARow]="3"
            [totalRowCount]="2"></table-list>
    </div>
</section>
<!-- benifits -->
<section class="px-md-5 my-5 px-2">
    <image-points
        [image]="'https://vcdn.cosgrid.com/website/assets3/Products/Firewall/COSGrid NG Firewall DECK.pptx.png'"
        [title]="'NGFW Benefits'" [data]="ngfwBenifits" leftContentXLCol="7"></image-points>
</section>

<!-- contact us -->
<section class="px-md-5 my-5 px-2">
    <div class="row background-grey p-lg-5 my-5">
        <h1 class="text-center fw-bold h2">Interested in the product?</h1>
        <p class="text-center fs-6">click the button below & submit your details - we call you back</p>
        <div class="d-flex justify-content-center">
            <a routerLink="/company/contacts" class="btn btn-primary text-uppercase fs-6 fw-bold">contact us</a>
        </div>
    </div>
</section>