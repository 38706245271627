import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteConstants } from '../../config/route.config';
import { ProductsComponentComponent } from './products-component/products-component.component';
import { SdWanComponent } from './sd-wan/sd-wan.component';
import { ZtnaComponent } from './ztna/ztna.component';
import { FirewallComponent } from './firewall/firewall.component';
import { SaseComponent } from './sase/sase.component';
import { ProductNDRComponent } from './productndr/productndr.component';
import { DebugRouteGuard } from '../../debug-route.guard';


const routes: Routes = [
  { path: '', redirectTo: `${RouteConstants.ZTNA}/microzaccess`, pathMatch: 'full' },
  { path: `${RouteConstants.ZTNA}/microzaccess`, component: ZtnaComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.SD_WAN, component: SdWanComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.FIREWALL, component: FirewallComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.SASE, component: SaseComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.NETSHIELD, component: ProductNDRComponent, canActivate: [DebugRouteGuard] },
  { path: `${RouteConstants.COMPONENT}/:id`, component: ProductsComponentComponent, canActivate: [DebugRouteGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class ProductsRoutingModule { }
