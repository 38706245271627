import { RouterModule, Routes } from '@angular/router';
import { RouteConstants } from './config/route.config';
import { NgModule } from '@angular/core';
import { DebugRouteGuard } from './debug-route.guard';
import { HomeComponent } from './pages/home/home.component';
import { SitemapComponent } from './pages/others/sitemap/sitemap.component';
import { PrivacyPolicyComponent } from './pages/others/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './pages/others/terms-of-use/terms-of-use.component';
import { IndustriesComponent } from './pages/industries/industries.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: RouteConstants.HOME, redirectTo: '' },
    { path: RouteConstants.SITEMAP, component: SitemapComponent },
    { path: RouteConstants.PRIVACY_POLICY, component: PrivacyPolicyComponent },
    { path: RouteConstants.TERMS_OF_USE, component: TermsOfUseComponent },
    // { path: RouteConstants.Support, component: ContactpageComponent},
    // { path: RouteConstants.USECASE, component: UsecasesComponent},
    // { path: `${RouteConstants.PRODUCTS}/${RouteConstants.VPN}`, component: ProductVPNComponent},
    { path: RouteConstants.PRODUCTS, loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsModule) },
    { path: RouteConstants.PARTNERS, loadChildren: () => import('./pages/partners/partners.module').then(m => m.PartnersModule) },
    { path: RouteConstants.COMPANY, loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule) },
    { path: RouteConstants.SUPPORT, loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule) },
    { path: `${RouteConstants.INDUSTRIES}`, redirectTo: `${RouteConstants.INDUSTRIES}/financial-services`, pathMatch: 'full' },
    { path: `${RouteConstants.INDUSTRIES}/:id`, component: IndustriesComponent },
    { path: RouteConstants.RESOURCES, loadChildren: () => import('./pages/resources/resources.module').then(m => m.ResourcesModule) },
    { path: '**', component: PageNotFoundComponent, data: { is404: true } },

];

export default  routes;

@NgModule({
    imports: [
    RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
}),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }