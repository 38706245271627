
import { Component, OnDestroy, OnInit } from '@angular/core';
import ICareerCollection from '../../../core/models/careers.model';
import { Title, Meta } from '@angular/platform-browser';

import { Subject, Subscription } from 'rxjs';

import { Apollo } from 'apollo-angular';
import { takeUntil } from 'rxjs/operators';
import { RouteConstants } from '../../../config/route.config';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import META_QUERY from '../../../queries/Meta/meta.service';
import { CanonicalService } from './../../../queries/canonical/canonical.service';

@Component({
    selector: 'app-careers',
    templateUrl: './careers.component.html',
    styleUrls: ['./careers.component.scss'],
    providers: [Apollo, Meta, Title, FetchMetaService]
})
export class CareersComponent implements OnInit, OnDestroy {
    OPENINGS_LINK = `${RouteConstants.OPENINGS}`;
    private destroy$ = new Subject<void>()
    backgroundBanner: string = 'https://vcdn.cosgrid.com/website/assets4/careers/careers-bg.jpg'
    carrerCollection: ICareerCollection = {
        title: 'Careers at COSGrid',
        description: [
            'We are an Enterprise Networking startup transforming the way Networks are Built, Secured, Operated and Managed. We work on the bleeding edge technologies that solves critical customer problems in the cloud and IoT centric world. This is being led by comprehensive research and intellectual property development.',
            'We are looking for creative and results-oriented developers, marketing and sales professionals who take ownership, drive and scale the business to next level. If this is the next challenge you seek, get in touch with us.',
        ],
        imgCollection: [
            {
                imgURL: 'https://vcdn.cosgrid.com/website/assets3/Company/Careers/work_as_team.svg',
                title: 'Work as a Team',
                content: `We value individuals for their unique viewpoints and experiences, and have the ability to engage in constructive
                discussions and progress together as a unified team. Our team fosters an open-minded approach and strives to
                find the optimal resolution that benefits COSGrid. We firmly believe that our collective success is intertwined
                with the success of the company.`,
            },
            {
                imgURL: 'https://vcdn.cosgrid.com/website/assets3/Company/Careers/take%20calculated%20risks.svg',
                title: 'Work Agile',
                content: `Our organization prides itself on being accessible and open, and we are committed to upholding honesty and
                ethical behavior in all aspects of our operations. We foster a culture of constructive communication, where we
                support and acknowledge each other's achievements, tackle obstacles head-on, and demonstrate empathy
                through active listening.`,
            },
            {
                imgURL: 'https://vcdn.cosgrid.com/website/assets3/Company/Careers/kickstart%20your%20career%20with%20us.svg',
                title: 'Embark on a promising career path with us',
                content: `Embark on a groundbreaking career path with us at COSGrid Networks Shape the future of technology and
                make a real impact, alongside talented minds and industry leaders. We offer competitive salaries, equity
                opportunities, and the chance to learn and grow every day.
                `,
            },
            {
                imgURL: 'https://vcdn.cosgrid.com/website/assets3/Company/Careers/work%20agile.svg',
                title: 'Embrace calculated risks.',
                content: `At COSGrid, we believe in pushing the boundaries of what's possible. We empower our people to embrace
                calculated risks, experiment with bold ideas, and drive innovation. If you're a creative problem-solver who
                thrives in a dynamic environment, join us and be part of something groundbreaking.`,
            },
            {
                imgURL: 'https://vcdn.cosgrid.com/website/assets3/Company/Careers/develop%20software%20for%20all%20platforms.svg',
                title: 'Join us on our journey of growth and evolve alongside us',
                content: `Embark on a journey of professional growth and innovation with us at COSGrid Networks, We're rapidly expanding,
                and we're looking for passionate individuals who want to evolve alongside us. Contribute your skills and help us
                shape the future of our industry.`,
            },
        ],
    };
    constructor(
        private titleService: Title,
        private metaService: Meta,
        private fetchMetaService: FetchMetaService,
        private apollo: Apollo,
        private canonicalService: CanonicalService
    ) { }

    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        try {
            if (this.fetchMetaService.getData() == false) {
                let metatags = [];
                this.apollo
                    .watchQuery({
                        query: META_QUERY,
                    })
                    .valueChanges
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((result) => {
                        let meta = [];
                        meta = (result.data as any).metaTags.data.map((metaTag: any) => ({
                            ...metaTag,
                        }));
                        for (let i = 0; i < meta.length; i++) {
                            metatags[meta[i].attributes.URL_ID] = meta[i];
                        }
                        // console.log("Meta Tags : ", this.metatags);
                        this.fetchMetaService.storeData(metatags);
                        let metaTagsData = this.fetchMetaService.getData();
                        // console.log('Meta Tags ', metaTagsData);
                        this.titleService.setTitle(metaTagsData['careers'].attributes.title);
                        this.metaService.updateTag({
                            name: 'description',
                            content: metaTagsData['careers'].attributes.description,
                        });
                        this.metaService.updateTag({
                            name: 'keywords',
                            content: metaTagsData['careers'].attributes.keywords,
                        });
                    });
            } else {
                // console.log('Data Alreday Fetched');
                const data = this.fetchMetaService.getData();
                this.titleService.setTitle(data['careers'].attributes.title);
                this.metaService.updateTag({
                    name: 'description',
                    content: data['careers'].attributes.description,
                });
                this.metaService.updateTag({
                    name: 'keywords',
                    content: data['careers'].attributes.keywords,
                });
            }
        } catch {
            // this.titleService.setTitle('Career Opportunities ');
            // this.metaService.updateTag({ name: 'description', content: 'Join the innovative Enterprise Networking startup. Take your career to the next level.' });
            // this.metaService.updateTag({ name: 'keywords', content: 'career opportunities, join us, enterprise networking, bleeding edge technologies, cloud, IoT, developers, marketing professionals, sales professionals, team, growth, agile work, software development' });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
