<!-- <div style="height: 10vh"></div> -->
<Section id="pageNotFound">
    <div class="card">
        <div class="message-box">
            <h1>404 Error</h1>
            <p>Sorry the page that u are looking for doesn't exist</p>
            <div class="buttons-con">
                <div class="action-link-wrap">
                    <a onclick="history.back(-1)" class="link-button link-back-button">Go Back</a>
                    <a routerLink="" class="link-button">Go to Home Page</a>
                </div>
            </div>
            <!-- <div class="container ">
        <img src="../../assets/images/navbar/COSGrid-logo.png" alt="cosgrid-logo" class="mb-5 img-fluid" />
        <p class="lead text-muted fw-normal"><span class="text-danger">404.</span> That’s an error.</p>
        <p class="lead fw-normal">The requested URL was not found on this server.</p>
        <a routerLink="/home" class="btn-blue btn mt-4 rounded pl-4 pr-4">
            Go back to home &nbsp;
            <i class="fas fa-arrow-right"></i>
        </a>
    </div> -->
        </div>
    </div>
</Section>