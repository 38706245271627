import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IEmailContactForm } from './core/models/emaildownload';
import { environment } from '../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ContactemailserviceService {
    constructor(private http: HttpClient) {}

    postContactForm(contactForm: IEmailContactForm) {
       // console.log(contactForm);
        // const url = `${environment.apiUrl}contact-emailto-users/?token=${environment.strapiApiKey}`;
        const url = `${environment.apiUrl}/api/contact-emailto-users/`;
        return this.http.post(url, contactForm);
    }
}
