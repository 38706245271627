import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { CanonicalService } from '../../../../queries/canonical/canonical.service';

@Component({
  selector: 'app-support-terms',
  templateUrl: './support-terms.component.html',
  styleUrls: ['./support-terms.component.scss']
})
export class SupportTermsComponent implements  OnDestroy {
  private destroy$ = new Subject<void>()
  raiseTicketForm: FormGroup
  priorityList = [
    {
      name: 'Urgent',
      value: 'urgent'
    },
    {
      name: 'High',
      value: 'high'
    },
    {
      name: 'Medium',
      value: 'medium'
    },
    {
      name: 'Low',
      value: 'low'
    }
  ]

  productList = [
    {
      name: 'MicroZAcess (ZTNA)',
      value: 'MicroZAcess (ZTNA)'
    },
    {
      name: 'ReFlex SD-WAN',
      value: 'ReFlex SD-WAN'
    },
    {
      name: 'Next Generation Firewall (NGFW)',
      value: 'Next Generation Firewall (NGFW)'
    },
    {
      name: 'COSGrid SASE',
      value: 'COSGrid SASE'
    },
    {
      name: 'NetShield (NDR)',
      value: 'NetShield (NDR)'
    }
  ]

  workingHoursList = [

    {
      name: 'India Standard Time (Asia / Kolkata)',
      value: 'india-standard-time(Asia / Kolkata)'
    },

  ]

  modeOfCommunicationList = [
    {
      name: 'Phone',
      value: 'phone'
    },
    {
      name: 'Email',
      value: 'email'
    },
    {
      name: 'Chat',
      value: 'chat'
    }
  ]
  constructor(
    private fb: FormBuilder, 
    private canonicalService: CanonicalService
  ) {
    this.raiseTicketForm = this.fb.group({
      issueIn: [''],
      subject: [''],
      product: ['', Validators.required],
      priority: ['', Validators.required],
      companyName: ['', Validators.required],
      description: ['', Validators.required],
      modeOfCommunication: ['', Validators.required],
      workingHours: ['', Validators.required]
    })
   }

  validateForm() {
    if (!this.raiseTicketForm.valid) {
        for (let i in this.raiseTicketForm.controls) {
            this.raiseTicketForm.controls[i].markAsTouched();
        }
        return false;
    } else {
        return true;
    }
}

  submitTicket(): void {
    if(this.validateForm()){
      console.log(this.raiseTicketForm.value);
    }
    else {
      
    }
  }

  ngOnInit(): void {
    this.canonicalService.setCanonicalTag();
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
}


}
