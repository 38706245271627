import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PartnersRoutingModule } from './partners-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { PartnersComponent } from './partners/partners.component';
import { GraphQLModule } from '../../graphql.module';


@NgModule({
  declarations: [
    PartnersComponent
  ],
  imports: [
    CommonModule,
    PartnersRoutingModule,
    SharedModule, 
    GraphQLModule
  ]
})
export class PartnersModule { }
