import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';

@Component({
    selector: 'table-list',
    templateUrl: 'table-list.html',
    styleUrls: ['table-list.scss'],
})
export class TableList {
    constructor(@Inject(PLATFORM_ID) protected platformId: Object) {
        if (isPlatformBrowser(this.platformId)) {
            this.getCurrentGridClassName(screen.width);
        }
    }

    @Input() data: any;
    @Input() imageExtension = 'png';
    @Input() xlGridsInARow = 4;
    tableSingleCellDynamicClass = '';
    @Input() threeCellsInRowClassName = 'threeCellsInRow';
    @Input() baseUrl = 'https://vcdn.cosgrid.com/website/assets4/home/why-choose-cosgrid/';
    @Input() spaceBtnTitleNImage = true;
    @Input() totalRowCount: number | undefined;

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        if (isPlatformBrowser(this.platformId))
            this.getCurrentGridClassName(event.target?.innerWidth);
    }
    getCurrentGridClassName(width: number) {
        if (width >= 992) {
            if (this.xlGridsInARow == 3) {
                this.tableSingleCellDynamicClass = this.threeCellsInRowClassName;
            } else {
                this.tableSingleCellDynamicClass = 'fourCellsInRow';
            }
        } else if (width >= 864) {
            this.tableSingleCellDynamicClass = this.threeCellsInRowClassName;
        } else {
            this.tableSingleCellDynamicClass = 'twoCellsInRow';
            if (this.totalRowCount == 2) this.totalRowCount = 3;
        }
    }
}
