import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subject, Subscription } from 'rxjs';
import { REPORTS_QUERY } from '../../../../queries/reports/reports.service';
import { Title, Meta } from '@angular/platform-browser';
import { FetchMetaService } from '../../../../queries/Meta/fetchmeta.service';

import META_QUERY from '../../../../queries/Meta/meta.service';
import { takeUntil } from 'rxjs/operators';
import { CanonicalService } from '../../../../queries/canonical/canonical.service';

@Component({
  selector: 'app-whitepapers',
  templateUrl: './whitepapers.component.html',
  styleUrls: ['./whitepapers.component.scss']
})
export class WhitepapersComponent implements OnInit, OnDestroy {
  private reportsBlog!: Subscription;
  reports: any[] = [];
  loading!: boolean;
  // private queryBlog: Subscription;
  private destroy$ = new Subject<void>();

  constructor(
    private apollo: Apollo,
    private router: Router,
    private titleService: Title,
    private metaService: Meta,
    private fetchMetaService: FetchMetaService,
    private canonicalService: CanonicalService
  ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalTag()
    if (this.fetchMetaService.getData() == false) {
        let metatags = [];
        this.apollo
            .watchQuery({
                query: META_QUERY,
            })
            .valueChanges.pipe(takeUntil(this.destroy$)).subscribe((result) => {
                let meta = [];
                meta = (result.data as any).metaTags.data.map((metaTag: any) => ({
                    ...metaTag,
                }));
                for (let i = 0; i < meta.length; i++) {
                    metatags[meta[i].attributes.URL_ID] = meta[i];
                }
                // console.log("Meta Tags : ", this.metatags);
                this.fetchMetaService.storeData(metatags);
                let metaTagsData = this.fetchMetaService.getData();
                // console.log("Meta Tags ", metaTagsData);
                this.titleService.setTitle(metaTagsData['library'].attributes.title);
                this.metaService.updateTag({
                    name: 'description',
                    content: metaTagsData['library'].attributes.description,
                });
                this.metaService.updateTag({
                    name: 'keywords',
                    content: metaTagsData['library'].attributes.keywords,
                });
            });
    } else {
        // console.log("Data Alreday Fetched");
        const data = this.fetchMetaService.getData();
        this.titleService.setTitle(data['library'].attributes.title);
        this.metaService.updateTag({
            name: 'description',
            content: data['library'].attributes.description,
        });
        this.metaService.updateTag({
            name: 'keywords',
            content: data['library'].attributes.keywords,
        });
    }

    this.loading = true;
    this.reportsBlog = this.apollo
        .watchQuery({
            query: REPORTS_QUERY,
        })
        .valueChanges.pipe(takeUntil(this.destroy$)).subscribe((result) => {
            this.reports = (result.data as any).reports.data.map((report: any) => ({
                ...report,
                image: report?.attributes?.Thumbnail?.data[0]?.attributes?.url,
                pdf: report?.attributes?.Document.data?.attributes?.url,
            }));
            this.reports = this.reports.filter(report => !['9', '11', '12', '15', '16', '17', '18', '19', '21'].includes(report.id))
            console.log(this.reports);
            this.loading = false;
        });
}

ngOnDestroy() {
    this.reportsBlog.unsubscribe();
    this.destroy$.next()
    this.destroy$.complete()
}

}
