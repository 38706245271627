import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class CareersService {
    constructor(private http: HttpClient) {}

    uploadResume(file: File, id: string) {
        const formData = new FormData();
        formData.append('files', file);
        formData.append('refId', id);
        formData.append('ref', 'api::application.application');
        formData.append('field', 'Resume');
        const url = `${environment.apiUrl}/api/upload`;
        return this.http.post(url, formData);
    }
}
