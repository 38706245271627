
import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { RouteConstants } from '../../../../config/route.config';
import { FetchMetaService } from '../../../../queries/Meta/fetchmeta.service';
import META_QUERY from '../../../../queries/Meta/meta.service';
import OPEN_POSITIONS_QUERY from '../../../../queries/carrers/open_position.qurey';
import { CanonicalService } from '../../../../queries/canonical/canonical.service';


@Component({
    selector: 'app-openings',
    templateUrl: './openings.component.html',
    styleUrls: ['./openings.component.scss'],
})
export class OpeningsComponent implements OnInit {
    OPENINGS_LINK = RouteConstants.CAREERS + '/' + RouteConstants.OPENINGS;
    private destroy$ = new Subject<void>()
    opening_positions: any[] = [];
    current_active_positions: any[] = [];
    loading: boolean = true;

    errors: any;

    constructor(
        private apollo: Apollo,
        private router: Router,
        private titleService: Title,
        private metaService: Meta,
        private fetchMetaService: FetchMetaService,
        private canonicalService: CanonicalService
    ) { }

    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        try {
            if (this.fetchMetaService.getData() == false) {
                let metatags = [];
                this.apollo
                    .watchQuery({
                        query: META_QUERY,
                    })
                    .valueChanges
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((result) => {
                        let meta = [];
                        meta = (result.data as any).metaTags.data.map((metaTag: any) => ({
                            ...metaTag,
                        }));
                        for (let i = 0; i < meta.length; i++) {
                            metatags[meta[i].attributes.URL_ID] = meta[i];
                        }
                        // console.log("Meta Tags : ", this.metatags);
                        this.fetchMetaService.storeData(metatags);
                        let metaTagsData = this.fetchMetaService.getData();
                        // console.log("Meta Tags ", metaTagsData);
                        this.titleService.setTitle(metaTagsData['openings'].attributes.title);
                        this.metaService.updateTag({
                            name: 'description',
                            content: metaTagsData['openings'].attributes.description,
                        });
                        this.metaService.updateTag({
                            name: 'keywords',
                            content: metaTagsData['openings'].attributes.keywords,
                        });
                    });
            } else {
                // console.log("Data Alreday Fetched");
                const data = this.fetchMetaService.getData();
                this.titleService.setTitle(data['openings'].attributes.title);
                this.metaService.updateTag({
                    name: 'description',
                    content: data['openings'].attributes.description,
                });
                this.metaService.updateTag({
                    name: 'keywords',
                    content: data['openings'].attributes.keywords,
                });
            }
        } catch {
            //     this.titleService.setTitle('COSGrid Networks | Current Job Openings | Join Our Team');
            // this.metaService.updateTag({ name: 'description', content: 'Join our passionate team of professionals committed to making a difference. Explore available roles and apply now to be part of our startup.' });
            // this.metaService.updateTag({ name: 'keywords', content: 'COSGrid Networks, job openings, current vacancies, careers, join our team, passionate professionals, talented individuals, enterprise networking startup, innovative, apply now' });
        }

        this.loading = true;
        this.apollo
            .watchQuery<any>({
                query: OPEN_POSITIONS_QUERY,
            })
            .valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe(({ data }) => {
                this.opening_positions = data.careers.data;
                this.current_active_positions = data.careers.data;
                this.setAvailableDepartments();
                this.loading = false;
            });
    }

    ngOnDestroy() {
        this.destroy$.next()
        this.destroy$.complete()
    }

    openingCountries = ['All Locations', 'Chennai', 'Mumbai', 'Bangalore'];
    openingDepartments = ['All Departments'];

    setAvailableDepartments() {
        this.openingDepartments = ['All Departments'];
        for (let countryObj of this.opening_positions) {
            this.openingDepartments.push(countryObj.attributes.type);
        }
    }

    dropdownDepartmentsSelect(event: Event) {
      const target = event.target as HTMLSelectElement; // Cast to HTMLSelectElement
      const selectedValue = target.value;
        if (selectedValue == 'All Departments') {
            this.current_active_positions = this.opening_positions;
        } else {
            this.current_active_positions = [];
            for (let countryObj of this.opening_positions) {
                if (countryObj.attributes.type.includes(selectedValue)) {
                    this.current_active_positions.push(countryObj);
                }
            }
        }
    }

    dropdownCountrySelect(event: Event) {
      const target = event.target as HTMLSelectElement; // Cast to HTMLSelectElement
      const selectedValue = target.value;
        if (selectedValue == 'All Locations') {
            this.current_active_positions = this.opening_positions;
        } else {
            this.current_active_positions = [];
            for (let countryObj of this.opening_positions) {
                let newCountryObj = {
                    attributes: {
                        type: countryObj.attributes.type,
                        open_positions: {
                            data: [] as any[],
                        },
                    },
                };
                for (let singleCareerData of countryObj.attributes.open_positions.data) {
                    // console.log(singleCareerData);
                    if (singleCareerData.attributes.Location.includes(selectedValue)) {
                        newCountryObj.attributes.open_positions.data.push(singleCareerData);
                    }
                }
                this.current_active_positions.push(newCountryObj);
            }
        }
    }
}
