<section class="px-md-5 my-5 px-2 pt-3">
    <div class="row">
        <div class="col-12 col-md-6 d-flex flex-column justify-content-center">
            <h1 class="text-center text-black h2 fw-bold">{{ privacyPolicy.title }}</h1>
            <div *ngFor="let i of privacyPolicy.text" class="">
                <p class="blockquote fs-6" [innerHTML]="i"></p>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <img class="w-80" [src]="privacyPolicy.imgURL" alt="Privacy policy" loading="lazy" />
        </div>
    </div>
</section>

<section class="px-md-5 my-5 px-2">
    <div class="row">
        <div class="col-12 col-md-6 order-2 order-md-1">
            <img class="w-80" [src]="logFiles.imgURL" alt="Log Files" loading="lazy" />
        </div>
        <div class="col-12 col-md-6 d-flex flex-column justify-content-center order-1 order-md-2">
            <h1 class="text-center text-black h2 fw-bold">{{ logFiles.title }}</h1>
            <div *ngFor="let i of logFiles.text" class="">
                <p class="blockquote fs-6" [innerHTML]="i"></p>
            </div>
        </div>
    </div>
</section>

<section class="px-md-5 my-5 px-2">
    <div class="row">
        <div class="col-12 col-md-6 d-flex flex-column justify-content-center">
            <h1 class="text-center text-black h2 fw-bold">{{ cookiesWebBeacons.title }}</h1>
            <div *ngFor="let i of cookiesWebBeacons.text" class="">
                <p class="blockquote fs-6" [innerHTML]="i"></p>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <img class="w-80" [src]="cookiesWebBeacons.imgURL" alt="Cookies" loading="lazy" />
        </div>
    </div>
</section>

<section class="px-md-5 my-5 px-2">
    <div class="row">
        <div class="col-12 col-md-6 order-2 order-md-1">
            <img class="w-80" [src]="third.imgURL" alt="Third Image" loading="lazy" />
        </div>
        <div class="col-12 col-md-6 d-flex flex-column justify-content-center order-1 order-md-2">
            <h1 class="text-center text-black h2 fw-bold">{{ third.title }}</h1>
            <div *ngFor="let i of third.text" class="">
                <p class="blockquote fs-6" [innerHTML]="i"></p>
            </div>
        </div>

    </div>
</section>


<section class="px-md-5 my-5 px-2">
    <div class="row">
        <div class="col-12 col-md-6 d-flex flex-column justify-content-center">
            <h1 class="text-center text-black h2 fw-bold">{{ child.title }}</h1>
            <div *ngFor="let i of child.text" class="">
                <p class="blockquote fs-6" [innerHTML]="i"></p>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <img class="w-80" [src]="child.imgURL" alt="Privacy" loading="lazy" />
        </div>
    </div>
</section>


<section class="px-md-5 my-5 px-2">
    <div class="row">
        <div class="col-12 col-md-6 order-2 order-md-1">
            <img class="w-80" [src]="online.imgURL" alt="Privacy" loading="lazy" />
        </div>
        <div class="col-12 col-md-6 d-flex flex-column justify-content-center order-1 order-md-2">
            <h1 class="text-center text-black h2 fw-bold">{{ online.title }}</h1>
            <div *ngFor="let i of online.text" class="">
                <p class="blockquote fs-6" [innerHTML]="i"></p>
            </div>
        </div>
    </div>
</section>

<section class="px-md-5 my-5 px-2">
    <p class="fw-bold h5">Security and Confidentiality</p>
    <p class="blockquote fs-6">
        We use industry-standard information, security tools, and measures, as well as internal procedures
        and strict guidelines to prevent information misuse and data leakage. Our employees are subject to
        confidentiality obligations. We use measures and procedures that substantially reduce the risks of
        data misuse, but we cannot guarantee that our systems will be absolutely safe. If you become aware
        of any potential data breach or security vulnerability, you are requested to contact us immediately.
        We will use all measures to investigate the incident, including preventive measures, as required.
    </p>
    <p class="fw-bold h5">How do we use your information?</p>
    <p class="blockquote fs-6">
        In case you provide us with your information, we may use it in order to provide you with the
        Service. This means that we will use the information to communicate with you for updates, marketing
        offers or concerns you may have and conduct statistical and analytical research to improve the
        Service.
    </p>
    <p class="fw-bold h5">Changes to this Privacy Policy This Policy</p>
    <p class="blockquote fs-6">
        This Policy may be revised from time to time at our sole discretion, so please check it regularly.
        The last revision will be reflected in the “Last Updated” heading. Any change in this policy will be
        posted on the Site. Your continued use of the Services following any such changes will be considered
        as your consent to the amended Privacy Policy.
    </p>
    <p class="text-stronger text-black h4 fw-bold">{{ consent.title }}</p>

    <p class="blockquote fs-6">{{ consent.text }}</p>
    <p class="text-stronger text-black h4 fw-bold">{{ contactUs.title }}</p>

    <p [innerHTML]="contactUs.text" class="fs-6"></p>
    <p class="pt-2 pb-1 fs-6" style="color: #8d8b8b">© 2023 COSGrid Systems Pvt ltd. All Rights Reserved.</p>
</section>














