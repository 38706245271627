<div class="main-container">
    <!-- <div class="title">
      <img src="https://vcdn.cosgrid.com/website/assets/images/navbar/fLogo.svg" alt="COSGrid Logo" width="13%" />
    </div> -->
    <div class="content">
      <div class="col-3 w-30 menu">
        <div class="menu rounded">
          <ul class="top-menu rounded border">
            <li (click)="openBox('support')" [class.active]="openedBox === 'support'">
              <div class="d-flex align-items-center justify-content-between">
                <p class="m-0"> <img src="https://vcdn.cosgrid.com/website/assets4/support/support.png" alt="support" width="20px"> Support</p>
                <img *ngIf="openedBox == 'support'" src="https://vcdn.cosgrid.com/website/assets4/support/arrow-up.png" alt="open" width="20px">
                <img *ngIf="openedBox != 'support'" src="https://vcdn.cosgrid.com/website/assets4/support/arrow-down.png" alt="close" width="20px">
              </div>
              
            </li>
            <ul class="inner-menu rounded" [@expandCollapse]="openedBox === 'support' ? 'expanded' : 'collapsed'"
              style="background-color: #fff;">
              <li (click)="openContent('support-term')" class="ps-4" [class.active]="openedContent === 'support-term'">
                <img src="https://vcdn.cosgrid.com/website/assets4/support/documentation.png" alt="support-terms" width="18px"> Support Terms</li>
              <li (click)="openContent('submit-ticket')" class="ps-4" [class.active]="openedContent === 'submit-ticket'">
                <img src="https://vcdn.cosgrid.com/website/assets4/support/submit-ticket.png" alt="submit-ticket" width="18px"> Submit Ticket</li>
              <li (click)="openContent('contact-support')" class="ps-4" [class.active]="openedContent === 'contact-support'">
                <img src="https://vcdn.cosgrid.com/website/assets4/support/contact-support.png" alt="contact-support" width="18px"> Contact Support</li>
            </ul>
            <li (click)="openBox('documentation')" class=""><img src="https://vcdn.cosgrid.com/website/assets4/support/documentation.png" alt="documentation" width="18px"> Documentation</li>
          </ul>
        </div>
      </div>
      <div class="col-md-9 col-12 ps-md-4">
        <app-contact-support *ngIf="openedContent == 'contact-support'"></app-contact-support>
        <app-submit-ticket *ngIf="openedContent == 'submit-ticket'"></app-submit-ticket>
        <app-support-terms *ngIf="openedContent == 'support-term'"></app-support-terms>
      </div>
    </div>
  </div>