import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import META_QUERY from '../../../queries/Meta/meta.service';
import { CanonicalService } from '../../../queries/canonical/canonical.service';

@Component({
  selector: 'app-sd-wan',
  templateUrl: './sd-wan.component.html',
  styleUrls: ['./sd-wan.component.scss']
})
export class SdWanComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  intro = {
    imgUrl: 'https://vcdn.cosgrid.com/website/assets3/Products/SDWANv1/2.webp',
    title: 'Zero Glitch, Zero Trust & Zero Touch',
    subtitle: '',
    logoUrl: 'https://vcdn.cosgrid.com/website/assets3/Products/SDWANv1/1.webp',
    imgHeight: '',
    imgWidth: '',
    logoWidth: '',
    logoHeight: '',
  }
  overview = {
    desc: "COSGrid's ReFleX Z3 SD-WAN is a Cloud Managed Software Defined platform designed to transform WAN into a cloud-centric world. It is built to deliver the simplicity, flexibility and cost-effective WANs for any branch office locations and scale also, seamlessly run and integrate with DC locations and Clouds deployed. ReFleX Z3 SD-WAN provides pervasive and unified connectivity for delivering the reliability, provides centralized network management capabilities; automated zero touch provisioning of devices; and end-to-end visibility for validating business policy as well as reporting.",
    products: [
      {
        imgURL: 'shield (1)',
        title: 'Security',
        points: ['Next Generation Firewall', 'AES encrypted VPN', 'URL & Content Filtering'],
      },
      {
        imgURL: 'switch',
        title: 'Networking',
        points: [
          'WAN balancing and failover',
          'Application based routing',
          ' 4G/5G failover',
        ],
      },
      {
        imgURL: 'reliability',
        title: 'Application QoS',
        points: ['Multi-path traffic Aggregation', 'Quality of Service', 'Network Acceleration'],
      },
      {
        imgURL: 'dashboard (4)',
        title: 'Analytics & Management',
        points: ['Real-time traffic reports', 'NetFlow stats', 'Log Management'],
      },
    ],
  }

  challenges = {
    imageRoute: 'https://vcdn.cosgrid.com/website/assets/sdwan/Challenges/',
    data: [
      {
        image: 'bad-review',
        title: `Poor User Experience 
          `,
        desc: 'Lower available bandwidth to per user or application',
      },
      {
        image: 'risk',
        title: 'Business Continuity Risks',
        desc: 'Poor network quality & ineffective backup',
      },

      {
        image: 'operation',
        title: 'Unviable Branch Connectivity Costs',
        desc: 'Inefficient and costly (10x expensive than Broadband)',
      },
      {
        image: 'breach (1)',
        title: 'Increasing Cost of Data Breaches Y-o-Y',
        desc: 'Average cost of breach @ $8.19 mn USD',
      },
      {
        image: 'deployment (1)',
        title: 'Deployment and Operations Complexity',
        desc: 'Multiple Link types & SLA; Traffic@30% growth y-o-y',
      },
      {
        image: 'wall-clock',
        title: 'Higher Latency and round trip times',
        desc: 'Traffic Movement increases the overall round-trip times',
      },
    ],
  }

  features = {
    how_helps_text: 'How ReFleX Z3 SD-WAN helps?',
    how_helps: {
      imageRoute: 'https://vcdn.cosgrid.com/website/assets/sase/sdwan/',
      data: [
        {
          image: 'acceleration',
          title: 'Unified and Accelerated Access',
          desc: 'Resilient & secure access, Scalable security for infrastructure, User experience visibility',
        },
        {
          image: 'protection',
          title: 'Zero Trust platform',
          desc:
            'Software designed parameters, Distributed SDN/NFV, Micro-segmentation',
        },
        {
          image: 'process',
          title: 'Adaptive orchestration & management',
          desc: 'Deep network visibility, intelligent threat detection and mitigation',
        },
      ],
    },
  }

  applianes = {
    helps_text:
      'Branch routers of the past are replaced by ReFleX Z3 SD-WAN. They offer zero-touch configuration, centralised policy and management of appliances, virtual private network (VPN), and dynamic path selection based on business or application policy.',
    helps_img: 'https://vcdn.cosgrid.com/website/assets/sdwan/sdwan_solution.png',
  }

  key_differ = {
    imageRoute: 'https://vcdn.cosgrid.com/website/assets/sdwan/Differentiators/',
    data: [
      {
        image: 'bandwidth',
        title: 'Unified Transport & Service to improve Bandwidth',
        points: [' Multiple Active Links - 4G/5G', 'Multiple Network Service'],
      },
      {
        image: 'network (3)',
        title: 'Intelligent Traffic Steering to prevent Downtime',
        points: ['Application based routing', 'High performance Apps'],
      },
      {
        image: 'data-security (1)',
        title: 'Integrated Security that prevents redundancies',
        points: ['Scalable VPN tunnels with zero trust ', 'Easy NGFW functionality'],
      },
      {
        image: 'dashboard (4)',
        title: 'Ease of Ops at Scale through interactive dashboard',
        points: ['Simple workflow', 'Automated'],
      },
    ],
  }

  useCases = {
    title: 'Use Cases',
    subtext: 'The impact on Network Security at your organisation',
    main_text:
      'NetShield NDR offers a range of Network Detection and Response (NDR) use cases for businesses looking to protect their network environments. These use cases include:',
    usecases: [
      {
        image: 'remote access',
        title: 'Centralized Management through Dashboard',
        desc: ' Unifies all Visibility, Documentation and Compliance Issues. Easing the Burden on Firewalls',
      },
      {
        image: 'branch',
        title: 'Secure Branch Internet Access',
        desc: 'Secure Internet access to all branch locations. Undisrupted Data Transfers, WAN',
      },
      {
        image: 'acceleration (1)',
        title: 'SaaS Acceleration',
        desc: ' Undisrupted user Sessions with Acceleration No Downtime - 24/7 support',
      },
      {
        image: 'MPLS',
        title: 'Mpls Migration To <br> SD-Wan',
        desc: 'Flexibility. Reduction in cost. Switching of Combination of Internet Links (Broadband/4G)',
      },
      {
        image: 'app performacne',
        title: 'Improve Overall Application Performance',
        desc: 'Leap in Performance even in Remote Locations. Local break out of Traffic headed to Internet/ Cloud.',
      },
      {
        image: 'centralised management ',
        title: 'Anytime Secure Remote Access',
        desc: ' Ease of access remotely',
      },
      {
        image: 'network maiantand',
        title: 'Overall Network Security Maintanance',
        desc: 'Application-based firewalls, intrusion prevention systems (IPS), and URL filtering',
      },
      {
        image: 'video-call',
        title: 'Pickup calls over Broadband',
        desc: 'Undisrupted VoIP Calls/Video Session.',
      },
    ],
  }
  benefits = {
    text: 'Benefits of ReFleX Z3 SD-WAN',
    subtext: 'That helps your organisation worry less about network security',
    img: 'https://vcdn.cosgrid.com/website/assets3/Products/SDWANv1/20.webp',
    benifits_points: [
      {
        title: '',
        desc: '<span class="clr-blue-dark fw-bold">Zero-glitch Network experience</span> through patented Multi-path traffic Aggregation & Adv Traffic Steering',
      },
      {
        title: '',
        desc: ' <span class="clr-blue-dark fw-bold">Zero Trust Access & microsegment</span> using device trust and geo-fencing SaaS Application acceleration',
      },
      {
        title: '',
        desc: '<span class="clr-blue-dark fw-bold">Only NetSecOps platform</span> that delivers Network Security SD-WAN and UEM <span class="clr-blue-dark fw-bold"> in single console </span>',
      },
      {
        title: '',
        desc: '<span class="clr-blue-dark fw-bold">3X higher accuracy</span> in detection through multi-pronged approach',
      },
      {
        title: '',
        desc: '<span class="clr-blue-dark fw-bold">Zero  Touch operations</span> with simplified management'
      }
    ],
  }



  constructor(private canonicalService: CanonicalService, private titleService: Title, private metaService: Meta, private fetchMetaService: FetchMetaService, private apollo: Apollo) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalTag()
    if (this.fetchMetaService.getData() == false) {
      let metatags = [];
      this.apollo
        .watchQuery({
          query: META_QUERY,
        })
        .valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          let meta = [];
          meta = (result.data as any).metaTags.data.map((metaTag: any) => (
            {
              ...metaTag,
            }
          ));
          for (let i = 0; i < meta.length; i++) {
            metatags[meta[i]?.attributes.URL_ID] = meta[i];
          }
          // console.log("Meta Tags : ", this.metatags);
          this.fetchMetaService.storeData(metatags);
          let metaTagsData = this.fetchMetaService.getData();
          // console.log("Meta Tags ", metaTagsData);
          this.titleService.setTitle(metaTagsData['sd-wan']?.attributes.title);
          this.metaService.updateTag({
            name: 'description',
            content:
              metaTagsData['sd-wan']?.attributes.description,
          });
          this.metaService.updateTag({
            name: 'keywords',
            content: metaTagsData['sd-wan']?.attributes.keywords
          });
        });
    }
    else {
      // console.log("Data Alreday Fetched");
      const data = this.fetchMetaService.getData();
      this.titleService.setTitle(data['sd-wan']?.attributes.title);
      this.metaService.updateTag({
        name: 'description',
        content:
          data['sd-wan']?.attributes.description,
      });
      this.metaService.updateTag({
        name: 'keywords',
        content: data['sd-wan']?.attributes.keywords
      });

    }
  }
  spacePad(str: string, cnt: number): string {
    let i = 0;
    let ret = str;
    for (let i = str.length; i < cnt; i++) {
      ret += '&nbsp;';
    }
    return ret;
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

}


