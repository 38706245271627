import gql from 'graphql-tag';

const AWARDS_QUERY = gql`
    # STRAPI V3 - QUERY
    # query {
    #     awards(sort: "published_at:DESC") {
    #         title
    #         id
    #         description
    #         contents
    #         title_image {
    #             url
    #         }
    #     }
    # }

        # V4 QUERY
    query {
        awards(sort: "publishedAt:DESC") {
            data {
                id
                attributes 
                {
                    Title
                    Description
                    Contents
                    Title_image 
                    {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                }
            }    
        }
    }
`;

export default AWARDS_QUERY;
