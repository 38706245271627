import { isPlatformBrowser } from '@angular/common';

import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RouteConstants } from '../../../config/route.config';
import IRouteLinkItem from '../../../core/models/route.model';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import META_QUERY from '../../../queries/Meta/meta.service';
import { CanonicalService } from '../../../queries/canonical/canonical.service';


@Component({
    selector: 'app-sitemap',
    templateUrl: './sitemap.component.html',
    styleUrls: ['./sitemap.component.scss'],
})
export class SitemapComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    resourcesBySections: any = [
        {
            title: 'Solutions',
            height: 330,
            child: [
                {
                    title: 'ZTNA',
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                },
                {
                    title: 'Cloud Security',
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                },
                {
                    title: 'VPN Alternative/ Cloud VPN',
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                },
                {
                    title: 'Secure & Reliable Connectivity',
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                },
                {
                    title: 'Web filtering & IPS',
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`
                },
                {
                    title: 'On Prem Security',
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`
                },

            ],
        },
        {
            title: 'Industries',
            child: [
                {
                    title: 'Retail',
                    link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.RETAIL}`,
                },
                {
                    title: 'Manufacturing',
                    link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.MANUFACTURING}`,
                },
                {
                    title: 'Healthcare',
                    link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.HEALTHCARE}`,
                },
                {
                    title: 'Financial Services',
                    link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.FINANCIAL_SERVICES}`,
                },
                {
                    link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.ITITES}`,
                    title: 'IT/ ITES '
                },
                {
                    link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.LOGISTICS}`,
                    title: 'Logistics'
                },
            ],
        },

        {
            title: 'Resources',
            height: 330,
            child: [
                {
                    title: 'MicroZAccess Documentation',
                    link: 'https://docs.cosgrid.com/',
                },
                {
                    title: 'SD-Wan concepts',
                    link: `/${RouteConstants.RESOURCES}/${RouteConstants.SD_WAN}`,
                },
                {
                    title: 'ZTNA Concepts',
                    link: `/${RouteConstants.RESOURCES}/${RouteConstants.ZTNA}`,
                },
                {
                    title: 'Whitepapers',
                    link: `/${RouteConstants.RESOURCES}/${RouteConstants.WHITE_PAPERS}`,
                },
                {
                    title: 'Blogs',
                    link: `/${RouteConstants.RESOURCES}/${RouteConstants.BLOGS}`,
                },
                {
                    title: 'FAQs',
                    link: `/${RouteConstants.RESOURCES}/${RouteConstants.FAQS}`,
                },
                {
                    title: 'Gallery',
                    link: `/${RouteConstants.RESOURCES}/${RouteConstants.MOMENTS}`,
                },
                {
                    title: 'Case Study & Reports',
                    link: `/${RouteConstants.RESOURCES}/case-studies`,
                },
            ],
        },
        {
            title: 'Company',
            height: 330,

            child: [
                {
                    title: 'About Us',
                    link: `/${RouteConstants.COMPANY}/${RouteConstants.ABOUTUS}`,
                },
                {
                    title: 'Contact Us',
                    link: `/${RouteConstants.COMPANY}/${RouteConstants.CONTACTS}`,
                },
                {
                    title: 'Careers',
                    link: `/${RouteConstants.COMPANY}/${RouteConstants.CAREERS}`,
                },
                {
                    title: 'Awards',
                    link: `/${RouteConstants.COMPANY}/${RouteConstants.AWARDS}`,
                },
                {
                    title: 'Events',
                    link: `/${RouteConstants.COMPANY}/${RouteConstants.EVENTS}`,
                },
                {
                    title: 'Privacy Policy',
                    link: `/${RouteConstants.PRIVACY_POLICY}`,
                },
                {
                    title: 'Terms of service',
                    link: `/${RouteConstants.TERMS_OF_USE}`,
                },
            ],
        },
    ];

    productLinks: any = [
        {
            title: 'SSE',
            height: 210,
            child: [
                {
                    title: 'ZTNA',
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
                },
                {
                    title: 'SIG',
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                    fragment: 'sig',
                },
                // {
                //     title: 'VPN',
                //     link: `/${RouteConstants.PRODUCTS}/${RouteConstants.VPN}`,
                // },
            ],
        },
        {
            title: 'SD-WAN',
            child: [
                {
                    title: 'Reflex SD-Wan 4G/5G',
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
                },
            ],
        },
        {
            title: 'SASE',

            child: [
                {
                    title: 'COSGrid SASE',
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
                },
            ],
        },
        {
            title: 'Components',
            height: 210,

            child: [
                {
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.C_EDGE}`,
                    title: 'ReFlex Edge'
                },
                {
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.GUIDER}`,
                    title: 'COSGrid Guider'
                },
                {
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.CLOUD_NF_HUB}`,
                    title: 'Cloud NF Hub'
                },
                {
                    link: `/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURITY_ANALYSER_RESPONDER}`,
                    title: 'SAR SDS'
                },
            ],
        },
    ];

    additionalLinks = [`/${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`, `/${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`];
    SASLinks: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;SASE</a>',
        },
    ];
    SSELinks: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;ZTNA</a>',
        },
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;SIG</a>',
            frag: 'sig',
        },
        {
            link: `/${RouteConstants.PRODUCTS}/vpn/${RouteConstants.SECUREACCESS}/.`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;VPN</a>',
        },
    ];
    SDLinks: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.REFLEX_SDWAN}/.`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp; ReFleX-WAN</a>',
        },
        {
            link: `/${RouteConstants.PRODUCTS}/sdwan/${RouteConstants.FIVEG_SDWAN}/.`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp; ReFleX Z3 5G SD WAN</a>',
        },
    ];
    NDRLinks: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.PRODUCTS}/ndr/${RouteConstants.NETSHIELD}/.`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;NDR</a>',
        },
    ];

    componentsLinks: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.C_EDGE}/.`,
            name: 'COSGrid RefleX-Edge',
        },
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.GUIDER}/.`,
            name: 'COSGrid Guider',
        },
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.CLOUD_NF_HUB}/.`,
            name: 'COSGrid CloudNF Hub',
        },
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURITY_ANALYSER_RESPONDER}/.`,
            name: 'COSGrid SAR',
        },
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.COMPONENT}/${RouteConstants.SECURECONNECT}/.`,
            name: 'COSGrid Secure Connect',
        },
    ];

    sdWanNav: IRouteLinkItem = {
        link: RouteConstants.SD_WAN,
        name: 'What is SD-WAN?',
    };

    // SOLUTIONS DROPDOWN Links
    usecasesLinks: IRouteLinkItem[] = [
        {
            link: RouteConstants.SD_WAN,
            name: 'SD-WAN Concepts',
        },
        {
            link: RouteConstants.USECASES,
            name: 'SD-WAN Usecases',
        },
        {
            link: RouteConstants.BLOGS,
            name: 'Blogs',
        },
        {
            link: '/reports/list',
            name: 'Library',
        },
        {
            link: '/gallery/moments',
            name: 'Moments',
        },
        {
            link: '/faqs',
            name: 'FAQ',
        },
    ];

    industriesLinks: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.FINANCIAL_SERVICES}/.`,
            name: 'Financial Services',
        },
        {
            link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.HEALTHCARE}/.`,
            name: 'Healthcare',
        },
        {
            link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.MANUFACTURING}/.`,
            name: 'Manufacturing',
        },
        {
            link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.RESTAURANTS_HOSPITALITY}/.`,
            name: 'Restaurants / Hospitality',
        },
        {
            link: `/${RouteConstants.INDUSTRIES}/${RouteConstants.RETAIL}/.`,
            name: 'Retail',
        },
    ];

    // PARTNERS
    partnersLinks: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.PARTNERS}#partnersProgram`,
            name: "Partner's Portal",
        },
        {
            link: `/${RouteConstants.PARTNERS}/${RouteConstants.BECOME_PARTNER}`,
            name: 'Become a partner',
        },
    ];

    careersLinks: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.CAREERS}#partnersProgram`,
            name: 'Careers',
        },
    ];

    // COMPANY URL
    companyLinks: IRouteLinkItem[] = [
        {
            link: RouteConstants.ABOUTUS,
            name: 'About us',
        },
        {
            link: RouteConstants.CONTACTS,
            name: 'Contact us',
        },
        {
            link: RouteConstants.AWARDS,
            name: 'Awards',
        },
    ];

    DOCLinks: IRouteLinkItem[] = [
        {
            link: `https://docs.cosgrid.com/.`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;MicroZAccess</a>',
        },
    ];
    FAQLink: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.FAQS}/.`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;FAQ</a>',
        },
    ];
    UseLink: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.USECASES}`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;ReFleX SD-WAN</a>',
        },
        {
            link: `/${RouteConstants.PRODUCTS}/ztna/${RouteConstants.MICROZACCESS}`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;MicroZAccess</a>',
            frag: 'usecases',
        },
    ];
    MediLink: IRouteLinkItem[] = [
        {
            link: `${RouteConstants.COMPANY}/${RouteConstants.EVENTS}`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;Event Photographs</a>',
        },
        {
            link: `/${RouteConstants.RESOURCES}/${RouteConstants.GALLERY}/.`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;Moments</a>',
        },
    ];
    WhiteLink: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.BLOGS}/.`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;Blogs</a>',
        },
    ];
    LibraryLinks: IRouteLinkItem[] = [
        {
            link: `/${RouteConstants.RESOURCES}/${RouteConstants.SD_WAN}`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;SD-WAN Concepts</a>',
        },
        {
            link: `/${RouteConstants.RESOURCES}/whitepaper-datasheets/.`,
            name: '<i class="pl-2 fas fa-chevron-right"></i>&nbsp;&nbsp;Whitepapers</a>',
        },
    ];
    constructor(private canonicalService: CanonicalService,  private titleService: Title, private metaService: Meta, private fetchMetaService: FetchMetaService, private apollo: Apollo, @Inject(PLATFORM_ID) private platformId: object) { }

    ngOnInit(): void {
        this.canonicalService.setCanonicalTag()
        if (this.fetchMetaService.getData() == false) {
            let metatags = [];
            this.apollo
                .watchQuery({
                    query: META_QUERY,
                })
                .valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                    let meta = [];
                    meta = (result.data as any).metaTags.data.map((metaTag: any) => (
                        {
                            ...metaTag,
                        }
                    ));
                    for (let i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    // console.log("Meta Tags ", metaTagsData);
                    this.titleService.setTitle(metaTagsData['sitemap'].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content:
                            metaTagsData['sitemap'].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData['sitemap'].attributes.keywords
                    });
                });
        }
        else {
            // console.log("Data Alreday Fetched");
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data['sitemap'].attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content:
                    data['sitemap'].attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data['sitemap'].attributes.keywords
            });

        }
    }
    navigationClicked(link: any ) {
        // alert('link ' + link);
        if (link.includes('http') && isPlatformBrowser(this.platformId)) {
            window.open(link);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
