<div class="support-term rounded">
    <div class="title my-4">
        <p class="h3 fw-bold">Support Terms</p>
    </div>
    <p class="fs-6" style="color: #58595b;">Support Services can be accessed through the COSGrid Support Portal and Admin Console. Standard Support offers COSGrid Support team assistance during regular business hours, tailored to your location. On the other hand, Premium+ Support grants access to the COSGrid Support team around the clock, every day of the year.</p>
    <p class="fs-6" style="color: #58595b;">When a problem is reported (via phone/ WhatsApp/ Email, web form, or the customer's administrative UI), a unique Support Ticket ID will be assigned. This ID must be referenced in all future communications until the issue is resolved. Standard Support is included in the COSGrid license itself, while Premium Plus Support can be obtained for an additional cost. If immediate assistance is not possible, the service request will be logged, and COSGrid will respond based on the severity and support levels outlined below.</p>
    <table>
        <thead>
            <tr class="border-none">
                <th></th>
                <th class="text-uppercase fw-bold">Standard</th>
                <th class="text-uppercase fw-bold">premium</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="border-top-none border-left-none">
                    Business Hours 8 x 5 Support (IST/PST/CST)
                </td>
                <td class="border-top-none"><svg _ngcontent-serverApp-c105="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="21px" height="16px"><path _ngcontent-serverApp-c105="" fill="#006d8f" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg></td>
                <td class="border-top-none border-right-none"><svg _ngcontent-serverApp-c105="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="21px" height="16px"><path _ngcontent-serverApp-c105="" fill="#006d8f" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg></td>
            </tr>
            <tr>
                <td class="border-left-none">24 × 7 x 365 Support</td>
                <td style="font-size: 25px;">-</td>
                <td class="border-right-none"><svg _ngcontent-serverApp-c105="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="21px" height="16px"><path _ngcontent-serverApp-c105="" fill="#006d8f" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg></td>
            </tr>
            <tr>
                <td class="border-left-none">Phone / Web Portal / Admin Console /
                    Whatsapp / Mail</td>
                <td><svg _ngcontent-serverApp-c105="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="21px" height="16px"><path _ngcontent-serverApp-c105="" fill="#006d8f" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg></td>
                <td class="border-right-none"><svg _ngcontent-serverApp-c105="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="21px" height="16px"><path _ngcontent-serverApp-c105="" fill="#006d8f" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg></td>
            </tr>
            <tr>
                <td class="border-left-none">Online Training, User Guides, Knowledge
                    Base Support</td>
                <td><svg _ngcontent-serverApp-c105="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="21px" height="16px"><path _ngcontent-serverApp-c105="" fill="#006d8f" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg></td>
                <td class="border-right-none"><svg _ngcontent-serverApp-c105="" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="21px" height="16px"><path _ngcontent-serverApp-c105="" fill="#006d8f" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path></svg></td>
            </tr>
            <tr>
                <td class="border-left-none border-bottom-none">Experience Level</td>
                <td class="border-bottom-none text-body-tertiary">Technical Support Engineer</td>
                <td class="border-right-none border-bottom-none text-body-tertiary">Sr. Technical Account Manager</td>
            </tr>
        </tbody>
    </table>

    <div class="mt-5">
        <p class="p-2 h3 w-100 rounded fw-bold" style="background-color: #0078be;color: #fff;">SLA LEVELS</p>
        <table class="w-100">
                <tr>
                    <td class="border-top-none border-left-none">P1 Response</td>
                    <td class="border-top-none clr-blue">2 Hours</td>
                    <td class="border-top-none border-right-none clr-blue">15 mins</td>
                </tr>
                <tr>
                    <td class="border-left-none">P2 Response</td>
                    <td class="clr-blue">4 Hours</td>
                    <td class="border-right-none clr-blue">25 mins</td>
                </tr>
                <tr>
                    <td class="border-left-none">P3 Response</td>
                    <td class="clr-blue">12 Hours</td>
                    <td class="border-right-none clr-blue">2 Hours</td>
                </tr>
                <tr>
                    <td class="border-left-none border-bottom-none">P4 Response</td>
                    <td class="border-bottom-none clr-blue">48 Hours</td>
                    <td class="border-right-none border-bottom-none clr-blue">4 Hours</td>
                </tr>
        </table>
    </div>
</div>