import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteConstants } from '../../config/route.config';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactsComponent } from './contacts/contacts.component';
import { AwardsComponent } from './awards/awards.component';
import { CareersComponent } from './careers/careers.component';
import { OpeningsComponent } from './careers/openings/openings.component';
import { PositionComponent } from './careers/openings/position/position.component';
import { EventsComponent } from './events/events.component';
import { DebugRouteGuard } from '../../debug-route.guard';

const routes: Routes = [
  { path: '', redirectTo: RouteConstants.ABOUTUS, pathMatch: 'full' },
  { path: RouteConstants.ABOUTUS, component: AboutusComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.CONTACTS, component: ContactsComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.AWARDS, component: AwardsComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.CAREERS, component: CareersComponent, canActivate: [DebugRouteGuard] },
  { path: `${RouteConstants.CAREERS}/${RouteConstants.OPENINGS}`, component: OpeningsComponent, canActivate: [DebugRouteGuard] },
  { path: RouteConstants.EVENTS, component: EventsComponent, canActivate: [DebugRouteGuard] },
  { path: `${RouteConstants.CAREERS}/${RouteConstants.OPENINGS}/:id`, component: PositionComponent, canActivate: [DebugRouteGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class CompanyRoutingModule { }
