import { Component, HostListener } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';
import { products, solutions, industries, partners, resources, company } from '../navbar.utils';


@Component({
  selector: 'app-desktop-navbar',
  templateUrl: './desktop-navbar.component.html',
  styleUrl: './desktop-navbar.component.scss'
})
export class DesktopNavbarComponent {
  sigFragment: any = '#sig';
  carouselSliderList = [
      '14 days Free Trail - <a href="https://cosgrid.net/auth/social-signup" target="_blank" class="nav-top-a" >Avail Now</a> - No Card Required',
      'Assisting your cybersecurity efforts- <a href="/company/contacts">Talk to our team</a> to understand your Cyber resiliency Standpoint',
      'Why Basic SOC2 /ISO Compliance and MFA not enough for cybersecurity - <a href="https://vcdn.cosgrid.com/website/strapi/soc2%20mfa%20ZTNA%20report%20-COSGrid__b8929bdd-a7e3-4e8c-a946-8c1b7c0ee4e3.pdf" target="_blank" class="nav-top-a">Read the report</a>'
  ]
  carouselTile: NguCarouselConfig = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 1100,
      point: {
          visible: true,
      },
      load: 3,
      velocity: 0,
      interval: {
          timing: 4000,
      },
      loop: true,
      touch: false,
      easing: 'cubic-bezier(0, 0, 0.2, 1)',
  };
  isHovered1: any = 0;
  isHovered2: any = 0;
  isHovered3: any = 0;
  isHovered4: any = 0;
  isHovered5: any = 0;
  titleProduct: string = '';

  products = products
  solutions = solutions
  industries = industries
  partners = partners
  resources = resources
  company = company
  @HostListener('window:scroll', ['$event'])
  

  togglefn(link: string) {
    if (typeof link === 'string' && link.includes('https://')) {
        window.open(link, '_blank');
    } else {
        // if (this.menutoggle == 'menu') this.menutoggle = 'close';
    }
}
}
