import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RouteConstants } from '../../../config/route.config';
import { FetchMetaService } from '../../../queries/Meta/fetchmeta.service';
import META_QUERY from '../../../queries/Meta/meta.service';
import { CanonicalService } from '../../../queries/canonical/canonical.service';

@Component({
  selector: 'app-ztna',
  templateUrl: './ztna.component.html',
  styleUrls: ['./ztna.component.scss']
})
export class ZtnaComponent implements OnInit, OnDestroy {
  product!: string;
  data: any;
  private destroy$ = new Subject<void>()
  constructor(
    private activatedRoute: ActivatedRoute,
    private fetchMetaService: FetchMetaService,
    private titleService: Title,
    private apollo: Apollo,
    private metaService: Meta,
    private canonicalService: CanonicalService
    
  ) { }
  ngOnInit(): void {
    this.canonicalService.setCanonicalTag()
    this.data = this.ztna
    if (this.fetchMetaService.getData() == false) {
      let metatags = [];
      this.apollo
        .watchQuery({
          query: META_QUERY,
        })
        .valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          let meta = [];
          meta = (result.data as any).metaTags.data.map((metaTag: any) => ({
            ...metaTag,
          }));
          for (let i = 0; i < meta.length; i++) {
            metatags[meta[i].attributes.URL_ID] = meta[i];
          }
          // console.log("Meta Tags : ", this.metatags);
          this.fetchMetaService.storeData(metatags);
          let metaTagsData = this.fetchMetaService.getData();
          // console.log("Meta Tags ", metaTagsData);
          this.titleService.setTitle(metaTagsData['microzaccess'].attributes.title);
          this.metaService.updateTag({
            name: 'description',
            content: metaTagsData['microzaccess'].attributes.description,
          });
          this.metaService.updateTag({
            name: 'keywords',
            content: metaTagsData['microzaccess'].attributes.keywords,
          });
        });
    } else {
      // console.log("Data Alreday Fetched");
      const data = this.fetchMetaService.getData();
      this.titleService.setTitle(data['microzaccess'].attributes.title);
      this.metaService.updateTag({
        name: 'description',
        content: data['microzaccess'].attributes.description,
      });
      this.metaService.updateTag({
        name: 'keywords',
        content: data['microzaccess'].attributes.keywords,
      });
    }
  }

  ztna = {
    start: {
      logo: 'https://vcdn.cosgrid.com/website/assets4/home/MicroZAccess_1.png',
      logoAlt: 'MicroZAccess',
      desc: 'A cloud-based zero-trust network access solution perfect for both Enterprises and small and medium businesses (SMB). Safe and Secure Access connecting all users, networks, clouds, and on-prem resources.',
      image: 'https://vcdn.cosgrid.com/website/assets4/ztna/mza cover.webp',
      imageAlt: 'MicroZAccess Device',
      features: 'Easy to setup in 15 Minutes | Complete onboarding assistance | No Card Required',
      buttons: [
        {
          text: 'Signup a Free Account',
          href: 'https://cosgrid.net/auth/sign-up',
          target: '_blank',
          external: true
        },
        {
          text: 'Watch Demo',
          href: 'products/ztna/microzaccess#video-reference',
          target: '_self',
          external: false
        },
        {
          text: 'Support',
          href: '/support',
          target: '_self',
          external: false
        }
      ]
    },
    overview: {
      carouselUrl: 'https://vcdn.cosgrid.com/website/assets/MZA/',
      desc: 'MicroZAccess is a <span class="fw-bold">Smart Zero Trust Network Access (ZTNA 2.0),</span> an alternative to VPN that securely authenticates the user and seamlessly connects the device to the Cloud through reliable, high performance and encrypted tunnels that offers <span class="fw-bold">unparalleled 3X more Security</span>, faster access, <span class="fw-bold">enhanced user experience</span>, and finally super <span class="fw-bold">easy to set up in 2 mins</span>.',
      carousel: [
        {
          imgURL: 'peer-to-peer',
          // image: 'assets/images/ztna/peer-to-peer.png',
          desc: 'Peer to Peer Overlay model for improved privacy and performance',
        },
        {
          imgURL: 'flexibility',
          // image: 'assets/images/ztna/deployment.png',
          desc: 'Flexible Deployment - Host/Workload Agent & Gateway approach',
        },
        {
          imgURL: 'integration',
          // image: 'assets/images/ztna/integration.png',
          desc: 'Integrated Device Trust and Superior Identity MFA based Access',
        },
        {
          imgURL: 'simple deploy',
          // image: 'assets/images/ztna/easy-installation.png',
          desc: 'Super Simple to Deploy and Manage<br/><br/>',
        },
        {
          imgURL: 'internet-security',
          // image: 'assets/images/ztna/cooperation.png',
          desc: 'Platform approach for Comprehensive Security - Support in SD-WAN and SASE',
        },
        {
          imgURL: 'compliant',
          // image: 'assets/images/ztna/compliant (1).png',
          desc: 'Stateful device compliance checks before, and during, a connection Granular policy enforcement',
        },
      ]
    },
    keyDifferentiators: [
      {
        percent: '80%',
        width: '30%',
        height: '60%',
        title: 'Zero Trust Access',
        arrowStyle: 'fa-arrow-down',
        subtitle: 'Reduce attack surface',
        imgURL: 'https://vcdn.cosgrid.com/website/assets3/Products/MicroZAccess/Key%20Differentiators/Attack%20Surface%20Reduction.webp',
      },
      {
        percent: '15m',
        width: '30%',
        height: '75%',
        arrowStyle: 'fa-arrow-down',
        title: 'Easy to deploy',
        subtitle: 'Set-up time 15 minutes',
        imgURL: 'https://vcdn.cosgrid.com/website/assets3/Products/MicroZAccess/Key%20Differentiators/Set-up%20time.webp',
      },
      {
        percent: '70%',
        width: '30%',
        height: '60%',
        arrowStyle: 'fa-arrow-down',
        title: 'Cost reduction',
        subtitle: 'Lower Opex cost',
        imgURL: 'https://vcdn.cosgrid.com/website/assets3/Products/MicroZAccess/Key%20Differentiators/Lower%20OPEX.webp',
      },
      {
        percent: '4x',
        width: '30%',
        height: '60%',
        arrowStyle: 'fa-arrow-up',
        title: 'Enhanced protection ',
        subtitle: 'Invisible - not vulnerable to DDos attacks',
        imgURL: 'https://vcdn.cosgrid.com/website/assets4/ztna/4x-protection.png',
      },
    ],
    description: [
      "Secure access alternative to VPN",
      "Employs advanced security measures, including continuous authentication, micro-segmentation, & encryption to protect data.",
      "Designed for rapid deployment. Setup Time is up to <span class='clr-blue-dark fw-bold'> 15 Minutes </span>only",
      "Reduces risk of cyber attacks through reduction of attack surface (External / Internal)"
    ],
    features: {
      points: [
        {
          id: 1,
          title: 'Zero-Trust Security capabilities',
          imgUrl: 'https://vcdn.cosgrid.com/website/assets4/ztna/protection.png',
          points: [
            'Dedicated Certificate Authority (CA) and Enhanced Identity',
            'End-to-End Least Privileges Connectivity with Device Trust',
            'Simple Cloud Workload Protection',
          ],
          width: '75%',
        },
        {
          id: 2,
          title: 'Secure Access Orchestration',
          imgUrl: 'https://vcdn.cosgrid.com/website/assets4/ztna/cyber-security.png',
          points: [
            'Software-defined Micro-segmentation',
            'ZTA (Zero Trust Access)',
            'Flexible and Layered Protection Approach',
          ],
          width: '75%',
        },
        {
          id: 3,
          title: 'Adaptive Authentication with IAM/PAM',
          imgUrl: 'https://vcdn.cosgrid.com/website/assets4/ztna/authentication.png',
          points: [
            'SSO Single Sign-On',
            'Multi-Factor Authentication',
            'Data Privacy and improved User Experience',
          ],
          width: '75%',
        },
        {
          id: 4,
          title: 'Monitoring, Management & API Integration',
          imgUrl: 'https://vcdn.cosgrid.com/website/assets4/ztna/dashboard.png',
          points: [
            'Support for All Platforms',
            'Deployment Options',
            'Centralized Management and Visibility',
            'API Integrations and all round Support',
          ],
          width: '75%',
        },
      ],
      desc: 'Secure access to applications and resources by consistently verifying and authenticating users and devices, regardless of their location or network settings.'
    },
    howDoesItWorks: {
      title: 'COSGrid MicroZAccess Zero Trust Access - How does it works ?',
      desc: 'COSGrid MicroZAccess provides granular access control, ensuring that only authorized users and devices can access specific applications and data.',
      img: 'https://vcdn.cosgrid.com/website/assets4/ztna/mza-works.png',
      points: [
        '<span class="clr-blue-dark fw-bold">Authentication and Authorization:</span> The system verifies user identity with MFA, assesses device security, and enforces access policies.',
        "<span class='clr-blue-dark fw-bold'>Secure Connection:</span> MicroZAccess establishes an encrypted tunnel for secure data transfer. ",
        "<span class='clr-blue-dark fw-bold'>Traffic Filtering:</span> It filters network and web traffic for security.",
        "<span class='clr-blue-dark fw-bold'>Monitoring:</span> Dynamic policies and real-time monitoring detect threats promptly."
      ],
      btnText: 'Architecture work flow - Watch Now',
      btnLink: 'https://www.youtube.com/watch?v=Bn_e5KRaeiM'
    },
    webFiltering: {
      title: 'COSGrid MicroZAccess with web Filtering',
      desc: 'COSGrid MicroZAccess enhances the online safety by implementing on local web filtering to regulate Internet browsing. Web filter rules can be given based on <span class="clr-blue-dark fw-bold">URLs, Category, Domains, Whitelist & Blacklist</span> which Establish customized rules for users or groups to restrict, or permit access to specific websites. By granting administrators full control over Internet usage, employees can navigate the web securely and can avoid of potential online dang',
      img: 'https://vcdn.cosgrid.com/website/assets4/ztna/mza-web-filtering.png'
    },
    highlights: [
      {
        icon: 'https://vcdn.cosgrid.com/website/assets4/ztna/security.png',
        desc: 'Secure Remote Access / Work from home'
      },
      {
        icon: 'https://vcdn.cosgrid.com/website/assets4/ztna/shield.png',
        desc: 'On Prem Vs Cloud Workload Protection'
      },
      {
        icon: 'https://vcdn.cosgrid.com/website/assets4/ztna/segmentation.png',
        desc: 'No Lateral Movements - Microsegmentation'
      },

      {
        icon: 'https://vcdn.cosgrid.com/website/assets4/ztna/screen.png',
        desc: 'Device Transparency & Visibility'
      },
      {
        icon: 'https://vcdn.cosgrid.com/website/assets4/ztna/ransomware.png',
        desc: 'Ransomware Reduction'
      },
      {
        icon: 'https://vcdn.cosgrid.com/website/assets4/ztna/authorization.png',
        desc: 'Least privilege Access'
      },
      {
        icon: 'https://vcdn.cosgrid.com/website/assets4/ztna/virtual.png',
        desc: 'Alternative to VPN'
      },
      {
        icon: 'https://vcdn.cosgrid.com/website/assets4/ztna/customer-satisfaction.png',
        desc: 'Enhanced User Experience'
      },
    ],
    benefits:
    {
      image: 'https://vcdn.cosgrid.com/website/assets4/ztna/mza-benefits.png',
      // desc: [
      //   {
      //     desc: '<span class="clr-blue-dark fw-bold">Device trust</span> and <span class="clr-blue-dark fw-bold">geofencing provide simple</span> and <span class="clr-blue-dark fw-bold">uncomplicated zero trust access</span> and <span class="clr-blue-dark fw-bold">micro-segmentation</span>',
      //   },
      //   {
      //     desc: 'An<span class="clr-blue-dark fw-bold"> integrated platform</span> approach to comprehensive security - Support for<span class="clr-blue-dark fw-bold"> SD-WANs and SASEs</span>',
      //   },
      //   {
      //     desc: '<span class="clr-blue-dark fw-bold">Deployment</span> and orchestration are<span class="clr-blue-dark fw-bold"> simplified</span> along with seamless security',
      //   },
      //   {
      //     desc: '<span class="clr-blue-dark fw-bold">North-South</span> & <span class="clr-blue-dark fw-bold">East-West </span>(In/Out and Within) Perimeter Traffic',
      //   },
      //   {
      //     desc: '<span class="clr-blue-dark fw-bold">Invisibility of assets</span> to external internet sources improves security posture',
      //   },
      //   {
      //     desc: 'Perfect for small and medium-sized businesses - <span class="clr-blue-dark fw-bold">Save money on network security</span> and operations'
      //   }
      // ]
      left: [
        {
          title: 'Enhanced Security',
          points: [
            "<span class='clr-blue-dark fw-bold'>Granular Access Control:</span> Fine-grained control over user access to applications & data, reducing the attack surface.",
            "<span class='clr-blue-dark fw-bold'>Continuous Verification:</span> Constantly verifies user and device identity, preventing unauthorized access.",
            "<span class='clr-blue-dark fw-bold'>Web Filtering:</span> Blocks malicious websites, phishing attempts, and inappropriate content, protecting users and data."
          ]
        },
        {
          title: "Improved Productivity",
          points: [
            "<span class='clr-blue-dark fw-bold'>Secure Remote Access:</span> Enables employees to work securely from anywhere, improving productivity and flexibility.",
            "<span class='clr-blue-dark fw-bold'>Reduced Distractions:</span> Web filtering can help to stay focused on tasks by blocking distracting or inappropriate websites."
          ]
        }
      ],
      right: [
        {
          title: "Cost Savings",
          points: [
            "<span class='clr-blue-dark fw-bold'>Optimized Network Performance:</span> ZTNA & web filtering can improve network performance & reduce bandwidth consumption, leading to cost savings.",
            "<span class='clr-blue-dark fw-bold'>Reduced Security Risks:</span> By preventing security breaches and data loss, ZTNA and web filtering can save organizations significant costs."
          ]
        },
        {
          title: "Compliance Adherence",
          points: [
            "<span class='clr-blue-dark fw-bold'>Data Privacy:</span> Helps organizations comply with data privacy regulations by protecting sensitive information.",
            "<span class='clr-blue-dark fw-bold'>Industry Standards:</span> Ensures adherence to industry-specific compliance requirements."
          ]
        }
      ]

    },
    integration: [
      {
        imgURL: 'https://vcdn.cosgrid.com/website/assets/gsuite-logo.jpg',
        name: 'GSuite Logo',
        width: '60%',
        link: 'https://docs.cosgrid.com/doc/66?productId=4&topic=Authentication%20%26%20Identity%20Management%20(IAM)&subTopic=66&logo=',
      },
      {
        imgURL: 'https://vcdn.cosgrid.com/website/assets/okta-logo.jpg',
        name: 'Okta Logo',
        width: '50%',
        link: 'https://docs.cosgrid.com/doc/63?productId=4&topic=Authentication%20%26%20Identity%20Management%20(IAM)&subTopic=63&logo=',
      },
      {
        imgURL: 'https://vcdn.cosgrid.com/website/assets/azure-logo.png',
        name: 'Azure Logo',
        width: '80%',
        link: 'https://docs.cosgrid.com/doc/62?productId=4&topic=Authentication%20%26%20Identity%20Management%20(IAM)&subTopic=62&logo=',
      },
      {
        imgURL: 'https://vcdn.cosgrid.com/website/assets/onelogin.png',
        name: 'One Login ',
        width: '60%',
        link: 'https://docs.cosgrid.com/doc/67?productId=4&topic=Authentication%20%26%20Identity%20Management%20(IAM)&subTopic=67&logo=',
      },
    ],
    usecases: {
      imgUrl: 'https://vcdn.cosgrid.com/website/assets/MZA/',
      data: [
        {
          sliderTitle: 'Secure Remote Access',
          imgURL: 'secure remote Access',
          title: 'Secure and granular Remote Access of Apps',
          width: '35%',
          points: [
            'Enable secure remote access to applications for employees, partners, and contractors.',
            'Implement granular access controls based on user roles, device posture, and contextual factors.',
            'Prevent unauthorized access and protect against data breaches.',
          ],
        },
        {
          sliderTitle: 'Zero Trust Data Access',

          imgURL: 'zero trust access to data',
          title: 'Zero Trust Access to Data in SaaS such as like OneDrive, AWS S3 etc and Data Protection',
          width: '35%',
          points: [
            '<b>Data Protection</b>: Sensitive data is protected from unauthorized access regardless of their network or location.',
            '<b>Data Sharing and Collaboration</b>: Securely and grual access sharing of data with external parties and collaborators.',
            '<b>Compliance & Forensics</b>: Access logs and audit trails, aiding in the investigation of security incidents and identifying potential vulnerabilities.',
          ],
        },
        {
          sliderTitle: 'Secure Infra Access/ Multi Cloud',
          imgURL: 'multicloud',
          title: 'Secure Infrastructure Access, DevOps and Multi-Cloud through micro-segmentation',
          width: '35%',
          points: [
            'Safeguard critical infrastructure and manage secure access to devops environments and multi-cloud deployments',
            'Enforce strong access controls and verify user identities',
            'Protect infrastructure from unauthorized access attempts',
          ],
        },

        {
          sliderTitle: 'Cloud Workload protection',
          imgURL: 'cloud workload',
          title: 'Simple Cloud Workload Protection',
          width: '35%',
          points: [
            'Ensure the security of cloud workloads and applications',
            'Establish secure connections between users and cloud resources',
            'Protect sensitive data and maintain compliance with industry regulations',
          ],
        },
        {
          sliderTitle: 'Compliance Enablement',
          imgURL: 'compliant',
          title: 'Compliance Enablement - Auditable Access Logs and micro-segmentation for PCI DSS, HIPPA',
          width: '35%',
          points: [
            'Implement micro-segmentation to achieve compliance with industry regulations',
            'Define and enforce granular access policies',
            'Isolate sensitive data and critical systems, reducing the attack surface.',
          ],
        },
        {
          sliderTitle: 'Third party Access Control',
          imgURL: 'thrid party control',
          title: 'Third party / Contractor Access control',
          width: '35%',
          points: [
            'Manage and control access for third-party vendors and contractors',
            'Define access policies based on user identities and roles',
            "Limit and control external entities' access to minimize risks.",
          ],
        },
      ]
    },
    videoReference: {
      FAQ_LINK: `/${RouteConstants.FAQS}/.`
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

}