<section>
    <div class="vw-100" [ngStyle]="{'background': 'url(' + main.imgUrl + ') no-repeat 0 0 / cover'}"
      style="height: calc(100vh - 101px)">
      <div class="row h-100">
        <div class="h-100 col-12 d-flex justify-content-center align-items-center h-100 bg-transparent">
          <div class="custom-width text-center p-1 p-md-5 rounded-lg shadow-lg glass-morphism">
            <p class="fw-bold h1 text-uppercase" [innerHTML]="main.title"></p>
            <p class="h5" [innerHTML]="main.subtitle"></p>
            <button class="my-2 bg-clr-blue-dark bg-gradient px-4 py-2 border-0 text-white h5 rounded"
              routerLink="/company/contacts">Know More</button>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
  <!-- <section>
      <div class="row vw-100" style="margin-top: 10rem;">
        
        <div class="col-12 col-md-6 d-none d-md-block h-md-80 my-auto">
          <div class="d-flex justify-content-center align-items-center h-100">
            <div class="w-70 h-100 d-flex flex-column justify-content-around align-items-center">
              <div class="w-100">
                <p class="fw-bolder display-5" [innerHTML]="main.title"></p>
              </div>
              <p class="fw-light h3" [innerHTML]="main.subtitle"></p>
              <div class="d-flex w-100 align-items-center justify-content-center"><button class="text-white my-4 bg-clr-blue-dark bg-gradient px-4 py-3 border-0 text-white h5">Know More</button></div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 d-none d-md-block" >
          <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <div class="w-70 h-100" [ngStyle]="{'background': 'url(' + main.imgUrl + ') no-repeat 0 0 / cover'}"></div>
          </div>
        </div>
        <div class="col-12 d-md-none" [ngStyle]="{'background': 'url(' + main.imgUrl + ') no-repeat 0 0 / cover'}">
          <div class="d-flex justify-content-center align-items-center h-100 bg-transparent">
            <div class="w-80 text-center bg-white p-2">
              <p class="fw-bold h3" [innerHTML]="main.title"></p>
              <p class="fw-light h6" [innerHTML]="main.subtitle"></p>
              <button class="my-2 bg-clr-blue-dark bg-gradient px-4 py-2 border-0 text-white h6">Know More</button>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  
  
  <!-- statistics -->
  <section>
    <div class="px-md-5 my-5 px-2">
      <div class="row">
        <div class="col-12 col-md-4 d-flex justify-content-center" *ngFor="let stat of main.statistics; let i = index">
          <div class="w-80 d-flex flex-column align-items-center">
            <p class="clr-blue-dark display-1 fw-bold"><span class="value fw-bold"
                [attr.percent]="stat.percentage"></span>%</p>
            <p class="fs-6" [innerHTML]="stat.description"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <!-- Challenges -->
  <section>
    <div class="px-md-5 my-5 px-2">
      <h1 class="clr-blue-dark fw-bold h2 text-center my-4">Challenges in the {{main.title}} industry</h1>
      <p class="fs-5 my-4 text-center" [innerHTML]="main.challengeDescription"></p>
      <div class="row my-4">
        <div class="col-12 col-md-4 d-flex justify-content-center align-items-center my-4"
          *ngFor="let challenge of main.challenges; let i = index">
          <div class="w-90 h-100 d-flex flex-column">
            <div class="w-100 d-flex justify-content-center align-items-center"><img style="width: 8rem;"
                class="image-thumbnail" [src]="challenge.imgUrl" [alt]="challenge.title"></div>
            <p class="fs-5 fw-bold clr-blue-dark text-center my-2" [innerHTML]="challenge.title"></p>
            <p class="fs-6" [innerHTML]="challenge.description"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <!-- Trends -->
  <section>
    <div class="px-md-5 my-5 px-2">
      <h1 class="clr-blue-dark fw-bold h2 text-center my-4">Trends in the {{main.title}} industry</h1>
      <p class="fs-5 my-4 text-center" [innerHTML]="main.trendsDescription"></p>
      <div class="row my-4">
        <div class="col-12 col-sm-6 d-flex justify-content-center align-items-center"
          *ngFor="let trend of main.trends; let i=index;">
          <div class="w-80 my-4">
            <p class="fs-6">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="23px" height="18px">
                <!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
                <path fill="#006d8f"
                  d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
              </svg>
              <span class="ml-2" [innerHTML]="trend.description"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  
  <!-- Features -->
  <section>
    <div class="px-md-5 my-5 px-2">
      <h1 class="h2 clr-blue-dark fw-bold text-center">Features</h1>
      <div class="row my-4">
        <div class="col-12 col-sm-6 col-md-3 my-2 d-flex align-items-center justify-content-center"
          *ngFor="let feature of main.features; let i = index;">
          <div class="w-90 h-100 d-flex flex-column my-2">
            <div class="d-flex justify-content-center align-items-center my-2" style="height: 5rem;">
              <img style="width: 6rem" [src]="feature.imgUrl" [alt]="feature.title">
            </div>
            <p class="fs-5 clr-blue-dark fw-bold text-center my-2" [innerHTML]="feature.title"></p>
            <p class="fs-6 my-2" [innerHTML]="feature.description"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <!-- Benefits -->
  <section>
    <div class="px-md-5 my-5 px-2">
      <h1 class="text-center fw-bold h2 clr-blue-dark">{{main.benefitHeading}}</h1>
      <div class="row my-4">
        <div class="col-12 col-md-6 d-flex justify-content-center align-items-center"
          *ngFor="let benefit of main.benefits; let i = index">
          <div class="w-80 h-100 mt-5 row">
            <div class="col-12 col-sm-4 d-flex justify-content-center align-items-start"><img style="width: 8rem;"
                [src]="benefit.imgUrl" [alt]="benefit.title"></div>
            <div class="col-12 col-sm-8">
              <p class="fs-5 fw-bold text-center clr-blue-dark text-md-left" [innerHTML]="benefit.title"></p>
              <p class="fs-6 my-2 text-center text-md-left" [innerHTML]="benefit.description"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  
  <!-- product reference -->
  <section class="px-md-5 my-5">
    <div class="p-3" style="background: linear-gradient(90deg, #6801AF 0%, #00B3F0 100%); border-top-right-radius: 20px">
      <h1 class="fw-bold clr-white h2">Related Products</h1>
      <!-- <p class="clr-white fs-5">Cloud Integration Support & SSO for the following platforms</p> -->
    </div>
    <div class="row d-flex justify-content-center align-items-center h-100 py-lg-5 py-1 integration-row">
      <div class="col-md-3 col-12 mb-1 image-box d-flex justify-content-center align-items-center hover-popup"
        *ngFor="let item of main.relatedProducts">
        <a [routerLink]="item.product.link"
          class="d-flex justify-content-center w-90 h-90 p-3 image-anchor bg-clr-white"><img [src]="item?.product?.imgUrl"
            [alt]="item?.product?.name" loading="lazy" class="img-fluid5" style="cursor: pointer"
            style="max-height: 80px; object-fit: contain" [attr.width]="item.product.width" />
        </a>
        <!-- </div> -->
      </div>
    </div>
  </section>
  
  <!-- Contact us -->
  <section>
    <div class="px-md-5 my-5 px-2">
      <div class="w-70 mx-auto">
        <h1 class="clr-blue-dark fw-bold h2 text-center">Have any Questions?</h1>
        <p class="text-center fs-6">Our team will reach out to you</p>
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary" routerLink="/company/contacts">Know more</button>
          <!-- <form action="" class="d-flex flex-column justify-content-center">
            <div class="row my-4">
              <div class="col-12 col-md-6 my-2">
                <input class="form-control form-control-lg w-100 bg-light bg-gradient" type="text"
                  placeholder="Full Name">
              </div>
              <div class="col-12 col-md-6 my-2">
                <input class="form-control form-control-lg w-100 bg-light bg-gradient" type="text"
                  placeholder="Email address">
              </div>
            </div>
            <Textarea class="form-control form-control-lg w-100 bg-light bg-gradient" placeholder="message"
              rows="5"></Textarea>
            <button
              class="bg-clr-blue-dark bg-gradient rounded py-3 px-5 my-4 border-0 text-white fs-6 fw-bold align-self-center">Submit</button>
          </form> -->
        </div>
      </div>
    </div>
  </section>