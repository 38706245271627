import { Injectable } from '@angular/core';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CanonicalService {
  constructor(private meta: Meta, private title: Title, private router: Router, private sanitizer: DomSanitizer) {
    // Listen to route changes to update the canonical tag
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.setCanonicalTag();
      });
  }

  setCanonicalTag() {
    const canonicalURL = this.getCanonicalUrl()
    let link: HTMLLinkElement | null = document.querySelector("link[rel='canonical']");

    if (link) {
      // Update existing canonical URL
      link.setAttribute('href', canonicalURL);
    } else {
      // Create and append a new canonical URL tag
      link = document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', this.sanitizer.sanitize(1, canonicalURL) || canonicalURL);
      document.head.appendChild(link);
    }

    console.log(canonicalURL)
  }

  getCanonicalUrl(): string {
    // This is where you can determine the canonical URL dynamically.
    // For example, if you want to use the full URL for the current route:
    const baseUrl = window.location.origin;
    const currentUrl = this.router.url;
    return `https://cosgrid.com${currentUrl}`;
  }

  // Optionally, you can also set the title and other meta tags
  setTitleAndMeta(title: string, description: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'description', content: description });
  }
}
